import React, { useEffect, useState, useMemo, useRef } from 'react';

import { Link } from 'react-router-dom';

import ForumIcon from '@mui/icons-material/Forum';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import HistoryIcon from '@mui/icons-material/History';

import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportTopbar from '../Components/Reporting/ReportTopbar.js';
import CurrentActivity from '../InstanceTabs/CurrentActivity.js';
import DetailsTab from '../InstanceTabs/DetailsTab.js';
import TertiaryReportToggles from '../Components/Tertiary/TertiaryReportToggles.js';
import SingleTertiary from '../Components/SingleView.js';
import FetchHandler from '../InstanceTabs/FetchHandler.js';
import LifeExpectancies from '../InstanceTabs/LifeExpectancies.js';

function SingleView(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const casePath = session?.[reporting?.data?.dataPointer]?.data?.[props?.branch]?.[props?.stem];
    const [currentCase, setCurrentCase] = useState(retrieveCase());

    const initialCaseLoading = useRef(false);

    const [dropdowns, setDropdowns] = useState({
        premiums : {
            showMenu : false,
            onClick : (value, data) => {
                session?.set("case", `${props?.branch}.${props?.stem}[${currentCase?.index}].premiums.selectedPremiumKey`, data?.premiumKey);
            }
        },
        premiumsDownload : {
            showMenu : false,
        },
        timeline : {
            showMenu : false,
        }
    });

    const updateDropdown = (key, attributes) => {
        setDropdowns((prev) => {
            const updatedDropdowns = {
                ...prev,
                [key]: {
                    ...prev[key],
                    ...attributes, // Spread the incoming attributes to update multiple fields
                },
            };
            return updatedDropdowns;
        });
    };

    const resetDropdowns = () => {
        setDropdowns((prev) => {
            const updated = Object.keys(prev).reduce((acc, key) => {
                acc[key] = { ...prev[key], showMenu: false };
                return acc;
            }, {});
            return updated;
        });
    };

    const [loadingHandler, setLoadingHandler] = useState(() => ({
        timeline: !currentCase?.timeline,
        shareLink: !currentCase?.generatedShareLink,
        les: !currentCase?.lifeExpectancies,
        premiums: !currentCase?.premiums,
    }));

    const tabComponents = {
        "timeline": {
            key: "timeline",
            label: "Timeline",
            icon: <TimelineIcon />,
            activity: "timeline",
            notification: undefined,
            notificationIcon: <FiberNewIcon />
        },
        "documents": {
            key: "documentsBtn",
            label: "Documents",
            icon: <HistoryEduIcon />,
            activity: "documents"
        },
        "les": {
            key: "les",
            label: "Life Expectancies",
            icon: <LineAxisIcon />,
            activity: "les"
        },
        "premiums": {
            key: "premiums",
            label: "Premium Schedule",
            icon: <HistoryIcon />,
            activity: "premiums"
        },
        "messages": {
            key: "messages",
            label: "Messages",
            icon: <HistoryIcon />,
            activity: "messages"
        },
        "all-timeline": {
            key: "timeline",
            label: "Timeline",
            icon: <TimelineIcon />,
            activity: "timeline",
            notification: undefined,
            notificationIcon: <FiberNewIcon />
        },
        "all-documents": {
            key: "documentsBtn",
            label: "Documents",
            icon: <HistoryEduIcon />,
            activity: "documents"
        },
    };

    const tertiaryInstance = session?.user?.data?.pages?.bids || [];
    const instancePermissions = tertiaryInstance?.viewPermissions || [];
    const approvedTabs = {
        "tertiary" : ["timeline", "documents", "les", "premiums", "all-timeline", "all-documents"],
        "bids" : ["timeline", "documents", "les", "premiums", "messages"],
    };

    const verifiedTabs = approvedTabs?.[props?.stem]?.filter(activity =>
        instancePermissions.includes(`${activity}`) && tabComponents?.[`${activity}`]
    );

    const [currentActivityView, setCurrentActivityView] = useState(() => {
        const { activity: tab, key: permission } = tabComponents[`${verifiedTabs?.[0]}`] || {};
        return { tab, permission };
    });

    // const currentCase = undefined;
    const active = currentCase?.data !== undefined;

    const updateLoadingHandler = (keys, value) => {
        setLoadingHandler((prevState) => {
          // Check if keys is an array or a single string
          if (Array.isArray(keys)) {
            // Create a new object with updated values for all keys
            const updatedState = keys.reduce((acc, key) => {
              acc[key] = value;
              return acc;
            }, {});
      
            return {
              ...prevState,
              ...updatedState,
            };
          } else if (typeof keys === "string") {
            // Update a single key
            return {
              ...prevState,
              [keys]: value,
            };
          }
      
          return prevState; // Return previous state if keys is not valid
        });
    };

    const fetchHandler = useMemo(() => FetchHandler({
        session,
        loadingHandler,
        updateLoadingHandler,
        branch: props?.branch,
        stem: props?.stem,
        currentCase: currentCase,
    }), [session, currentCase]);

    function retrieveCase() {
        // return {data : session?.case?.data?.policies?.servicing?.[0], index : 0};

        const opportunities = session?.case?.data?.[props?.branch]?.[props?.stem];
        const index = opportunities?.findIndex(
            data => Number(data?.recordID) === Number(props?.recordID)
        );
    
        if (index !== -1 && index !== undefined) {
            return { data: opportunities[index], index };
        } else {
            return { data: undefined, index: undefined };
        }
    }
    
    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const containerWidth = document.querySelector('.tertiaryHub').offsetWidth;
        const newWidth = containerWidth - e.clientX + 12; // Adjust this calculation as needed
        const minWidth = 300; // Minimum width in pixels
        const maxWidth = containerWidth - minWidth; // Maximum width in pixels
    
        if (newWidth < minWidth) {
            // setThirdColumnWidth(`${minWidth}px`);
            session?.set('bids', 'tertiary.layout.dimensions', `1fr 8px ${minWidth}px`);
        } else if (newWidth > maxWidth) {
            // setThirdColumnWidth(`${maxWidth}px`);
            session?.set('bids', 'tertiary.layout.dimensions', `1fr 8px ${maxWidth}px`);
        } else {
            console.log(newWidth, containerWidth, maxWidth);
            // setThirdColumnWidth(`${newWidth}px`);
            session?.set('bids', 'tertiary.layout.dimensions', `1fr 8px ${newWidth}px`);
        }
    };
    
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    function loadCase(){
        return;
    }

    function currentActivity(tab){
        if(currentActivityView?.tab === tab){
            return " selected";
        }
        return '';
    }

    function switchViewBtnContainer(tabCount){
        let className = "switchViewBtnContainer g f tabCount" + tabCount;
        return className;
    }

    function printTabs() {
        let tabBar = [];
        let tabCount = 0;

        approvedTabs?.[props?.stem]?.forEach(activity => {
            const permissionKey = `${activity}`;
            const tab = tabComponents?.[permissionKey];

            if (tab && instancePermissions.includes(permissionKey)) {
                tabBar.push(
                    <div
                        key={tab?.key}
                        className={`switchViewBtn f g cC s e${currentActivity(tab?.activity)}${!active ? " inactive" : ''}`}
                        onClick={() => { if (active) { setCurrentActivityView({ "tab": tab?.activity, "permission": permissionKey }) }}}
                        title={tab?.label}
                    >
                        {tab.notification &&
                            <div className="notificationBubble">
                                <FiberNewIcon />
                            </div>
                        }
                        <div className="switchViewBtnOption">
                            {tab.label}
                        </div>
                        <div className="inlineBtnIcon cC">{tab?.icon}</div>
                    </div>
                );
                tabCount++;
            }
        });

        return (
            <div className={switchViewBtnContainer(tabCount)}>
                {tabBar}
            </div>
        );
    }

    useEffect(() => {
        if (!currentCase?.data || !casePath) return;
    
        const loadData = async () => {
            if (initialCaseLoading.current) {
                return;
            }
    
            initialCaseLoading.current = true;
            const tempCurrentCase = { ...currentCase?.data };
            const affectedLoaders = [];
    
            try {
                const promises = [];
                // Load premiums if needed
                if (!tempCurrentCase?.premiums && props?.branch === "bids" && (props?.stem === "bids" || props?.stem === "tertiary")) {
                    promises.push(fetchHandler?.loadServicingPremiums().then(data => {
                        tempCurrentCase.premiums = data;
                        affectedLoaders.push("premiums");
                    }));
                } else {
                    updateLoadingHandler("premiums", false);
                }
    
                // Load life expectancies if needed
                if (!tempCurrentCase?.lifeExpectancies && loadingHandler?.les && (props?.stem === "bids" || props?.stem === "tertiary")) {
                    promises.push(fetchHandler?.loadLifeExpectancies().then(data => {
                        tempCurrentCase.lifeExpectancies = data;
                        affectedLoaders.push("les");
                    }));
                } else {
                    updateLoadingHandler("les", false);
                }
    
                // Load timeline if needed
                if (!tempCurrentCase?.timeline && loadingHandler?.timeline) {
                    promises.push(fetchHandler?.loadTimeline().then(data => {
                        tempCurrentCase.timeline = data;
                        affectedLoaders.push("timeline");
                    }));
                } else {
                    updateLoadingHandler("timeline", false);
                }
    
                // Load share link if needed
                if (!tempCurrentCase?.generatedShareLink && loadingHandler?.shareLink) {
                    promises.push(fetchHandler?.loadShareLink().then(data => {
                        tempCurrentCase.generatedShareLink = data;
                        affectedLoaders.push("shareLink");
                    }));
                } else {
                    updateLoadingHandler("shareLink", false);
                }
    
                // Wait for all the promises to resolve
                await Promise.all(promises);
    
                // Only update if the data has changed
                if (JSON.stringify(tempCurrentCase) !== JSON.stringify(currentCase?.data)) {
                    session?.set("case", `${props?.branch}.${props?.stem}[${currentCase?.index}]`, tempCurrentCase);
                    setCurrentCase({data : tempCurrentCase, index : currentCase?.index});
                    updateLoadingHandler(affectedLoaders, false);
                }
    
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                initialCaseLoading.current = false;
            }
        };
    
        loadData();

    }, [currentCase, session?.[reporting?.data?.dataPointer]?.data?.[props?.branch]?.[props?.stem]]);

    useEffect(() => {
        setCurrentCase(retrieveCase());

        if(Number(currentCase?.data?.recordID) !== Number(props?.recordID)){
            if(!currentCase?.data?.timeline){
                updateLoadingHandler("timeline", true);
            }
            if(!currentCase?.data?.lifeExpectancies){
                updateLoadingHandler("les", true);
            }
            if(!currentCase?.data?.generatedShareLink){
                updateLoadingHandler("shareLink", true);
            }
        }
        // Set timeline loading handler based on whether timelineData exists
    }, [
        session?.[reporting?.data?.dataPointer]?.data?.[props?.branch]?.[props?.stem],
        props?.recordID,
        session?.[reporting?.data?.dataPointer]?.data?.[props?.branch]?.[props?.stem]?.[currentCase?.index]
    ]);

    return (
        <div className="singleView g" onClick={()=>{resetDropdowns()}}>
            <div className="staticTab g oH">
                <Link
                    className="inlineNavBar g cL fR"
                    key="home"
                    to={`/${session?.navigation?.functions?.inlineNavigation("back")?.view}`}
                    onClick={() => {
                        session?.navigation?.functions?.updateInstance("back");
                    }}
                >
                    <div className="f cC">
                        <ArrowBackIosNewTwoToneIcon/>
                    </div>
                    <div className="f cL e">
                        {session?.navigation?.functions?.inlineNavigation("back")?.prompt}
                    </div>
                </Link>
                <DetailsTab
                    session={session}
                    branch={props?.branch}
                    stem={props?.stem}
                    currentCase={currentCase}
                />
            </div>
            <div className="alignmentContainer pR g fR fC">
                <div
                    // className={`columnDrag g cC${dragging?.current ? " dragging" : ''}`}
                    className={`columnDrag g cC fR fC`}
                    onMouseDown={handleMouseDown}
                    // onMouseDown={(e) => setupDragHandlers(column, index - allColumns("count")).handleMouseDown(e)}
                    // onDoubleClick={columnDoubleClickHandler}
                >
                    <div className="bar">
                    </div>
                </div>
            </div>
            <div className="activity f g fC b e dG">
                <div className="topBar g fR f">
                    <div className="tabs g f">
                        {printTabs()}
                    </div>
                    <div className="moreBtn cC f p">
                        <MoreHorizIcon/>
                    </div>
                </div>
                <CurrentActivity
                    session={session}
                    activityView={{data: currentActivityView, set : setCurrentActivityView}}
                    currentCase={currentCase}
                    loadingHandler={loadingHandler}
                    branch={props?.branch}
                    stem={props?.stem}
                    verifiedTabs={verifiedTabs}
                    // resetDropdowns={resetDropdowns}
                    dropdowns={{data : dropdowns, set : updateDropdown}}
                />
            </div>
        </div>
    )
};

export default SingleView;