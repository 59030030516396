import React, { useEffect, useState } from 'react';

function Initialization(props){
    const [data, setData] = useState({
        reporting : false,
        cases : false,
    });

    function updateAttributeData(session, obj, stem, consoleLog) {
        const newData = { ...session?.case?.data?.attributeData };
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const targetStem = obj?.stem ?? stem;
            if (newData?.[key]?.formType === "generatedList") {
              if (!newData[key].list[targetStem]) {
                newData[key].list[targetStem] = {};
              }
      
              if (!newData?.[key]?.list?.[targetStem]?.[value]) {
                newData[key].list[targetStem][value] = {
                  count: 1,
                  [stem] : true,
                };
              } else {
                newData[key].list[targetStem][value].count++;
              }
            }
          }
        }

        return newData;
    }

    function loadReportingData(session) {
        setData(prevState => ({
            ...prevState,
            reporting: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("user/reports")
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                const processedReports = resData.reports.map(report => {
                    let newReport = {};
                    for (const key in report) {
                        newReport[key] = report[key] === '' ? undefined : report[key];
                    }
                    return newReport;
                });

                session?.set("reporting", "allReports", processedReports);
                setData(prevState => ({
                    ...prevState,
                    reporting: true
                }));
            } else {
                setData(prevState => ({
                    ...prevState,
                    reporting: false
                }));
            }
        });
    }

    function loadPoliciesData(session, setAllPolicies){
        setData(prevState => ({
            ...prevState,
            cases: "pending"
        }));
    
        const params = {
          "accountID" : session?.user?.data.accountID
        };

        session?.env?.functions?.buildFetchRequest("pullAccountPolicies", params)
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                let attributeData = {};
                const fundArray = [];
                const originationArray = [];

                Object.values(resData?.policyRecords).forEach(caseItem => {
                    if (caseItem.type === "servicing") {
                        fundArray.push(caseItem);
                    } else {
                        originationArray.push(caseItem);
                    }

                    attributeData = updateAttributeData(session, caseItem, caseItem.type);
                });

                const caseModule = {...session?.case?.data} || {};
                caseModule.allCases = Object.values(resData?.policyRecords);
                caseModule.policies.servicing = fundArray;
                caseModule.policies.origination = originationArray;
                caseModule.attributeData = attributeData;

                setAllPolicies(Object.values(resData?.policyRecords));

                session?.set("case", null, caseModule);
                setData(prevState => ({
                    ...prevState,
                    cases: true
                }));
            } else {
                setData(prevState => ({
                    ...prevState,
                    cases: false
                }));
            }
        });
    }

    function loadBidData(session){
        setData(prevState => ({
            ...prevState,
            bids: "pending"
        }));
    
        const params = {
          "accountID" : session?.user?.data.accountID
        };

        session?.env?.functions?.buildFetchRequest("marketplace/getShippedPolicies", params)
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                let attributeData = {};
                const tasks = resData.tasks;
                const bids = Object.keys(tasks)
                    .map(key => tasks[key]);

                bids.forEach(bid => {
                    attributeData = updateAttributeData(session, bid, "bids");
                });

                const caseModule = {...session?.case?.data} || {};
                caseModule.bids.bids = bids;
                caseModule.attributeData = attributeData;

                session?.set("case", null, caseModule);
                setData(prevState => ({
                    ...prevState,
                    bids: true
                }));
            } else {
                setData(prevState => ({
                    ...prevState,
                    bids: false
                }));
            }
        });
    }

    function loadLeadsData (session){
        setData(prevState => ({
            ...prevState,
            leads: "pending"
        }));

        const params = {
            "accountID" : session?.user?.data.accountID
          };

        session?.env?.functions?.buildFetchRequest("pullLeadsV2", params)
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                let attributeData = {};
                const leads = resData?.leads;
                const tertiary = Object.keys(leads).map(key => {
                    const policy = leads?.[key];
                    attributeData = updateAttributeData(session, policy, "leads");
                    return policy;
                });

                const caseModule = {...session?.case?.data} || {};
                // caseModule.allCases = Object.values(resData?.policyRecords);
                caseModule.leads.leads = tertiary;
                session?.set("case", null, caseModule);
                // session?.set("bids", "selectedReport", tertiaryReport);
                setData(prevState => ({
                    ...prevState,
                    leads: true
                }));
            } else {
                setData(prevState => ({
                    ...prevState,
                    leads: false
                }));
            }
        });
    }

    function loadTertiaryData(session){
        setData(prevState => ({
            ...prevState,
            tertiary: "pending"
        }));

        session?.env?.functions?.buildFetchRequest("tertiary/getShippedPolicies")
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                let attributeData = {};
                const bids = resData?.policies;
                const tertiary = Object.keys(bids).map(key => {
                    const policy = bids?.[key];
                    attributeData = updateAttributeData(session, policy, "tertiary");
                    return policy;
                });

                const caseModule = {...session?.case?.data} || {};
                // caseModule.allCases = Object.values(resData?.policyRecords);
                caseModule.bids.tertiary = tertiary;
                session?.set("case", null, caseModule);
                loadPortfoliosData(session);
                // session?.set("bids", "selectedReport", tertiaryReport);
                setData(prevState => ({
                    ...prevState,
                    tertiary: true
                }));
            } else {
                setData(prevState => ({
                    ...prevState,
                    tertiary: false
                }));
            }
        });
    }

    function loadPortfoliosData(session){
        session?.env?.functions?.buildFetchRequest("tertiary/getPortfolios")
        .then(response => response.json())
        .then(resData => {
            if (resData.status === 200) {
                session?.set("bids", "tertiary.portfolios", resData?.portfolios);
            }
        }, (error) => {
            if (error) {
                session?.set("bids", "tertiary.portfolios", false);
            }
        });
    }

    function resetLoaders(){
        setData({
            reporting : false,
            cases : false,
            bids : false,
            tertiary : false,
            leads : false,
        });
    }

    const functions = {
        loadReportingData,
        loadPoliciesData,
        loadBidData,
        loadTertiaryData,
        resetLoaders,
        loadLeadsData,
    }

    const initialization = {
        data,
        setData,
        functions,
    }

    return initialization;
};

export default Initialization;