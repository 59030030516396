import { useState, useEffect } from 'react';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import StatusIcon from '../StatusIcons';
import Default from './Default.js';
import OriginationRequest from './Request - Origination.js';
import BiddingRequest from './Request - Bidding.js';
import Response from './Response.js';
import BiddingOffer from './BiddingOffer.js';
import Note from './Note.js';

function TimelinePostHandler(props){
    const session = props?.session;
    const branch = props?.branch;
    const stem = props?.stem;
    const currentCase = props?.currentCase;
    const timelineItem = props?.timelineItem;
    const timelineData = props?.timelineData;

    const [timeAgo, setTimeAgo] = useState('');
    const [checkStatusClass, setCheckStatusClass] = useState("open");

    useEffect(() => {
        const updateTime = () => {
            setTimeAgo(session?.env?.functions?.timeAgo(timelineItem?.timeStamp));
        };

        // Initial update
        updateTime();

        // Update every second
        const intervalId = setInterval(updateTime, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [timelineItem?.timeStamp]);

    const commonProps = {
        session : session,
        branch : branch,
        stem : stem,
        currentCase : currentCase,
        activityView : props?.activityView,
        timelineData : timelineData,
        timelineItem : timelineItem,
        timeAgo : timeAgo,
        onClick : () => {
            session?.env?.setOverlay({
                name : "statusDetail",
                props : {
                    currentCase : currentCase?.data,
                    branch : branch,
                    stem : stem,
                    timelineItem : timelineItem,
                }
            })
        }
    };

    if (timelineItem?.type === "Note") {
        return (
            <Note
                {...commonProps}
            />
        );
    }

    if (timelineItem?.type === "Request") {
        if(stem === "origination"){
            return (
                <OriginationRequest
                    {...commonProps}
                />
            );
        }

        if(stem === "bids" || stem === "tertiary"){
            return (
                <BiddingRequest
                    {...commonProps}
                />
            );
        }
    }

    if (timelineItem?.type === "Response - Bid" || timelineItem?.type === "Response - Request") {
        return (
            <Response
                {...commonProps}
            />
        );
    }

    if((stem === "bids" || stem === "tertiary") && timelineItem?.type === "Bid"){
        return (
            <BiddingOffer
                {...commonProps}
            />
        )
    }

    return (
        <Default
            {...commonProps}
        />
    );
}

export default TimelinePostHandler;
