import React from 'react';
import { BrowserRouter as Router, Routes, Route, redirect } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import _ from 'lodash';  // Import lodash for deep comparison

import NavBar from './Template/NavBar';
import Login from './Login/Login';
import { useParams, useLocation } from 'react-router-dom';



import Rerouter from './Public/Rerouter.js';
import Analytics from './Analytics/Analytics.js';
import Reporting from './MarketplacePortal/Reporting.js';
import Tertiary from './MarketplacePortal/Tertiary.js';
import AllPolicies from './MarketplacePortal/Policies.js';
import Dashboard from './MarketplacePortal/Dashboard.js';
import Bids from './MarketplacePortal/Bids.js';

// import Bids from './BidsDashboard/Bids.js';
import HomePage from './HomePageDashboard/HomePage.js';
import Policies from './PoliciesDashboard/Policies.js';
import Profile from './ProfileDashboard/Profile.js';
import Upload from './Upload/Upload.js';
import Leads from './LeadsDashboard/Leads.js';
import NotificationBoard from './Notifications/NotificationBoard.js';
import HomePageTimelinePost from './Components/Timeline/HomePageTimelinePost';
import LeadBlockKanban from './LeadsDashboard/LeadBlockKanban';
import LeadBlock from './LeadsDashboard/LeadBlock';
import KanbanBlock from './Components/Kanban/KanbanBlock';
import KanbanRow from './Components/Kanban/KanbanRow';
import PolicyRows from './PoliciesDashboard/PolicyRows.js';
import fetchBase from './envHandler.js';
import EnvVars from './envVars.js';
import OverlayHandler from "./Components/Overlays/OverlayHandler.js";
import SessionVars from './SessionVars/SessionVars.js';
// import Initialization from './SessionVars/Initialization.js';

import { authorizeRequest } from './envHandler.js';
import { parseJWT } from './envFunctions.js';

import { keyMapSearchVal, comparisonStatus, containsNoWhiteSpace } from './PoliciesDashboard/PoliciesOverview.js';
import { detectHomePage, ForceLogout } from './envFunctions.js';
import { returnStatusData } from './Components/StatusIcons.js';
import { ResetTvRounded } from '@mui/icons-material';

const handleFetch = (fetch, updatePage) => {
  return async function(url, options) {
    const response = await fetch(url, options);
    const clone = response.clone();
    const data = await clone.json();
    //console.log(data);

    if(data.status === 500){
     console.log(data);
    }

    if (data.status === 509 || data.error == "Unauthorized") {
      console.log("Kicking user.");
      updatePage("login");
    }
    //console.log(response);
    return response;
  };
};

function App(){
  const session = SessionVars();
  const params = useParams();
  const [sessionUser, setSessionUser] = useState(maintainSessionUser());
  const [policies, setPolicies] = useState();
  const [policyList, setPolicyList] = useState([]);
  const [profile, setProfile] = useState({});
  const [collectiveAccount, setCollectiveAccount] = useState({});
  const [chartData, setChartData] = useState();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMobileMore, setShowMobileMore] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(JSON.parse(localStorage.getItem('sessionInfo')));
  const [mobileDevice, setMobileDevice] = useState(false);
  const [activityView, setActivityView] = useState('');
  const [currentPolicy, setCurrentPolicy] = useState('');
  const [currentLead, setCurrentLead] = useState('');
  const [currentBid, setCurrentBid] = useState('');
  const [currentActivity, setCurrentActivity] = useState('');
  const [allPolicies, setAllPolicies] = useState();

  // const initialize = Initialization();

  const envVars = EnvVars(sessionUser);
  const updatePage = (pageName) => {
    session?.env?.setOverlay();
    navigation.setCurrentLead();
    navigation.setCurrentPolicy();
    navigation.setCurrentBid();

    setShowNotifications(false);
    //setSessionUser(tempSessionUser => ({...tempSessionUser, currentPage: pageName }));

    if(pageName === "login"){
      window.localStorage.removeItem("sessionUser");
      window.localStorage.setItem('sessionUser', JSON.stringify({currentPage : pageName }));
      return;
    }

    // var tempStoredSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));
    // tempStoredSessionUser.currentPage = pageName;
    // window.localStorage.setItem( 'sessionUser', JSON.stringify(tempStoredSessionUser));

    window.scrollTo(0, 0);
  }

  const fetch = handleFetch(window.fetch, updatePage);

  function buildRequestOptions(bodyParams, directContentType){
    let defaultContentType = directContentType || "application/json";

    return {
      method: "POST",
      headers: { "Content-Type": defaultContentType },
      body: JSON.stringify({
        ...(bodyParams || {}),
        sessionToken: authorizeRequest(sessionUser, updatePage)
      })
    };
  }

  function buildFetchRequest(url, params) {
    const requestOptions = buildRequestOptions(params);
    return fetch(fetchBase + url, requestOptions);
  }

  const sessionVars = {
    fetch,
    fetchBase,
    updatePage,
    authorizeRequest: () => authorizeRequest(sessionUser, updatePage),
    parseJWT,
    ForceLogout,
    returnStatusData,
    buildRequestOptions,
    buildFetchRequest,
    envVars
  }

  const navigation = {
    currentPolicy,
    setCurrentPolicy,
    currentLead,
    setCurrentLead,
    currentBid,
    setCurrentBid,
    currentActivity,
    setCurrentActivity
  };

  let [filters, setFilters] = useState({});

  function maintainSessionUser(){
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));

    if (storedSessionUser && storedSessionUser?.application === "AMP" && !session?.user?.data) {
      session?.user?.functions?.loadUser(storedSessionUser);
    }

    if(!storedSessionUser || storedSessionUser?.application !== "AMP"){
      return {currentPage : "login"};
    }else{
      return storedSessionUser;
    }
  }

  function updateSessionProfile(profileData, key){
    if(key){
      setProfile(prev => ({...prev, [key] : profileData }));
    }else{
      setProfile(profileData);
    }
  }

  function loadSessionPolicies(policyData, relatedPolicyID){
    if(relatedPolicyID){

      var tempPolicyData = {};
      var x = 1;

      policyData.x = 0;
      tempPolicyData[relatedPolicyID] = policyData;

      Object.keys(policies).sort(function(a, b){
        return policies[a].x - policies[b].x;
      }).forEach(updatePolicyPosition);

      function updatePolicyPosition(key){
        policies[key].x = x;
        tempPolicyData[policies[key].relatedPolicyID] = policies[key];
        x++;
      }

      setPolicies(tempPolicyData);
    }else{
      setPolicies(policyData);
    }
  }

  function markSessionPolicyAsRead(relatedPolicyID){
    setPolicies( policies =>  { return { ...policies, [relatedPolicyID] : { ...policies[relatedPolicyID], readStatus : false }}});
  }

  function updateChartData(chartDataVal){
    setChartData(chartDataVal);
  }

  function updateFiltersNew(filtersData, data, key){
    if(data === true){
      setFilters( filtersData );
      return; 
    }
  }

  function loadCollectiveAccount(){
    const paramVals = {
      "accountID" : 3
    };

    session?.env?.functions?.buildFetchRequest("pullAccountData", paramVals)
      .then(response => response.json())
      .then(resData => {
        if(resData.status == 200){
          //console.log(resData);
          const accountData = {
            totalActiveAUM : resData.accountData[3].totalActiveAUM,
            totalClosedAUM : resData.accountData[3].totalClosedAUM,
          };
          setCollectiveAccount(accountData);
        }

        if(resData.status == 500){
          console.log("No account found.");
        }
    });
  }

  useEffect(() => {
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));

    loadCollectiveAccount();

    if (!(storedSessionUser && storedSessionUser.application === "AMP" && storedSessionUser.sessionToken)) {
      session?.user?.functions?.logout();
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if(windowWidth < 769 && windowWidth !== 0 || windowHeight < 600 && windowHeight !== 0){
      if(!mobileDevice){
        setMobileDevice(true);
      }
    }else{
      if(mobileDevice){
        setMobileDevice(false);
      }
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if(!session?.user?.data?.sessionToken && !localStorage.getItem("sessionUser")){
      session?.env?.setOverlay();
    }

    if(session?.user?.data?.sessionToken && session?.user?.data){
      const opportunityTypes = session?.user?.data?.opportunityTypes;

      if(!session?.initialize?.data?.reporting){
        session?.initialize?.functions?.loadReportingData(session);
      }

      if(!session?.initialize?.data?.leads && opportunityTypes.some(val => val === "leads")){
        session?.initialize?.functions?.loadLeadsData(session);
      }

      if(!session?.initialize?.data?.cases && opportunityTypes.some(val => val === "origination" || val === "servicing")){
        session?.initialize?.functions?.loadPoliciesData(session, setAllPolicies);
      }

      if(!session?.initialize?.data?.bids && opportunityTypes.some(val => val === "bids")){
        session?.initialize?.functions?.loadBidData(session);
      }

      if(!session?.initialize?.data?.tertiary && opportunityTypes.some(val => val === "tertiary")){
        session?.initialize?.functions?.loadTertiaryData(session);
      }
    }
  }, [session?.user?.data]);

    // function useWhyDidYouUpdate(name, props) {
    //     const previousProps = useRef();
    
    //     useEffect(() => {
    //         if (previousProps.current) {
    //             const allKeys = Object.keys({ ...previousProps.current, ...props });
    //             const changes = {};
    
    //             allKeys.forEach(key => {
    //                 if (!_.isEqual(previousProps.current[key], props[key])) {
    //                     changes[key] = {
    //                         from: previousProps.current[key],
    //                         to: props[key]
    //                     };
    //                 }
    //             });
    
    //             if (Object.keys(changes).length) {
    //                 console.log(`[why-did-you-update] ${name}`, changes);
    //             }
    //         }
    
    //         previousProps.current = props;
    //     });
    // }

    // useWhyDidYouUpdate('MyComponent', session);
    

  return(
    <Router>
      <div className="App">
        {session?.user?.data?.sessionToken || JSON.parse(localStorage.getItem("sessionUser") || "{}").sessionToken !== undefined ?
          <MarketPlace
            sessionUser={sessionUser}
            setSessionUser={setSessionUser}
            setSessionInfo={setSessionInfo}
            policies={policies}
            policyList={policyList}
            setPolicyList={setPolicyList}
            profile={profile}
            updateFiltersNew={updateFiltersNew}
            filters={filters}
            chartData={chartData}
            updateChartData={updateChartData}
            showNotifications={showNotifications}
            setShowNotifications={setShowNotifications}
            updatePage={updatePage}
            loadSessionPolicies={loadSessionPolicies}
            markSessionPolicyAsRead={markSessionPolicyAsRead}
            updateSessionProfile={updateSessionProfile}
            sessionInfo={sessionInfo}
            mobileDevice={mobileDevice}
            showMobileMore={showMobileMore}
            setShowMobileMore={setShowMobileMore}
            activityView={activityView}
            setActivityView={setActivityView}
            navigation={navigation}
            sessionVars={sessionVars}
            session={session}
            allPolicies={allPolicies}
            setAllPolicies={setAllPolicies}
          />
          :
          <PublicPath
            sessionUser={sessionUser}
            setSessionUser={setSessionUser}
            updatePage={updatePage}
            account={collectiveAccount}
            mobileDevice={mobileDevice}
            sessionVars={sessionVars}
            session={session}
          />
        }
      </div>
    </Router>
  );
}

function MarketPlace(props){
  const [notifications, setNotifications] = useState({});
  const [notificationsCount, setNotificationsCount] = useState();
  const [policyNumber, setPolicyNumber] = useState('');
  const [leadID, setLeadID] = useState();
  const [singlePolicyHash, setSinglePolicyHash] = useState('');
  const allPolicies = props?.allPolicies;
  const setAllPolicies = props?.setAllPolicies;
  const [initializationHandler, setInitializationHandler] = useState({
    homepage : false,
    policies : false,
    leads : false,
    bids : false,
    notifications : false,
    insuranceCompanyList : false,
  });
  const location = useLocation();
  const navigate = useNavigate();

  function currentPath(){
    // let currentPage = location.pathname.split('/');
    // console.log(detectHomePage(props.sessionUser?.views));
    // if(currentPage[1] === ""){
    //   navigate("/" + detectHomePage(props.sessionUser?.views));
    // }

    // return currentPage[1];
  }
  
  function changeActivityView(view, hash){
    props.setActivityView(view);

    if(hash){
      setSinglePolicyHash(hash);
    }else{
      setSinglePolicyHash('');
    }
  }

  function loadAccountData() {
    const paramVals = {
      accountID: props.sessionUser?.accountID,
      userID: props.sessionUser?.userID,
    };

    props.session?.env?.functions?.buildFetchRequest("pullAccountData", paramVals)
      .then((response) => response.json())
      .then((resData) => {
        if(resData.status == 200){
          props.setSessionUser(prevState => ({
            ...prevState,
              accountData: resData.accountData
          }));

          let accountData = [];

          for (var currentAccount in resData.accountData){
            resData.accountData[currentAccount].activeHandler = {
              policies : true,
              leads : true,
              bids : true
            }
            accountData[resData.accountData[currentAccount].accountID] = resData.accountData[currentAccount];
          }

          props.sessionVars.envVars.setAdminHandler(accountData);
          props?.session?.user?.functions?.updateUserData("opportunityTypes", [resData?.accountData?.opportunityTypes]);
          props?.session?.user?.functions?.updateUserData("accountData", resData?.accountData);
        }else{
          console.log(resData);
        }
      });
  }

  useEffect(() => {

    if(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type){
      // if(JSON.parse(localStorage.getItem('redirectLink'))){
      //   var redirectObj = JSON.parse(localStorage.getItem('redirectLink'));
      //   var pagePath = redirectObj["URL"];
      //   const page = pagePath.substring(pagePath.indexOf('/') + 1, pagePath.indexOf('/', pagePath.indexOf('/') + 1));

      //   // if(["policies", "leads", "upload", "profile", "bids", "home"].includes(page)){
      //   //   //props.updatePage(page);
      //   //   navigate(redirectObj.URL);
      //   // }

      //   localStorage.removeItem("redirectLink");
      //}

      loadAccountData();
    }
  }, []);

  function loadNotifications(){

    if(initializationHandler.notifications === true){
      return;
    }else{
      setInitializationHandler(prevState => ({
        ...prevState,
        notifications: true
      }));      
    }

    const paramVals = {
      "accountID" : props.sessionUser.accountID,
    };

    // props.sessionVars.buildFetchRequest("pullNotifications", paramVals)
    props.session?.env?.functions?.buildFetchRequest("pullNotifications", paramVals)
    .then(response => response.json())
    .then(resData => {
        let notificationData = {};
        let notificationCounter = 0;

        if(resData.status == 200){
          for (var currentPolicy in resData.notificationData){
            notificationData[currentPolicy] = {
              policyNumber : resData.notificationData[currentPolicy].policyNumber,
              relatedPolicyID : resData.notificationData[currentPolicy].relatedPolicyID,
              firstName : resData.notificationData[currentPolicy].firstName,
              lastName : resData.notificationData[currentPolicy].lastName,
              status : resData.notificationData[currentPolicy].status,
              message : resData.notificationData[currentPolicy].message,
              request : resData.notificationData[currentPolicy].request,
              note : resData.notificationData[currentPolicy].note
            };
            if(notificationData[currentPolicy]?.status?.length > 0){
              notificationCounter++;
            }
            if(notificationData[currentPolicy]?.message?.length > 0){
              notificationCounter++;
            }
            if(notificationData[currentPolicy]?.request?.length > 0){
              notificationCounter++;
            }
            if(notificationData[currentPolicy]?.note?.length > 0){
              notificationCounter++;
            }
          }
        }

        notificationData.count = notificationCounter;

        setNotifications(notificationData);

        if(notificationsCount !== notificationCounter){
          if(notificationsCount){
            //loadPolicyOverview();
            loadHomePageTimelineData();
          }

          setNotificationsCount(notificationCounter);
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  useEffect(() =>{
    if(props.sessionVars?.envVars?.adminHandler){
      if(initializationHandler.policies === false &&
        ["origination", "servicing"].some(type => props?.session?.user?.data?.opportunityTypes?.includes(type))){
        loadPolicyOverview();
      }
      if(initializationHandler.homepage === false){
        if(props?.session?.navigation?.functions?.verifyPageAccess("dashboard")){
          loadHomePageTimelineData();
          loadHomePageGraph();
        }

        if(props.sessionUser?.views?.some(value => ["Marketing"].includes(value))){
          // loadMarketingData();
        }
      }
      if(initializationHandler.bids === false && props?.session?.user?.data?.opportunityTypes?.includes("bids")){
        pullShippedPolicies();
      }
      if(initializationHandler.leads === false && props?.session?.user?.data?.opportunityTypes?.includes("leads")){
        pullLeads();
      }

      if(initializationHandler.notifications === false){
        loadNotifications();
      }

      // if(props.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.views?.some(value => ["Marketplace", "Marketing"].includes(value))){
      //   loadHomePageGraph();
      // }

      if(initializationHandler.insuranceCompanyList === false){
        loadInsuranceCompanyList();
      }

    }
  }, [props.sessionVars]);

  /* Home Page */

  const [homepageTimeline, setHomepageTimeline] = useState();
  const [statusHandler, setStatusHandler] = useState({});
  const [visibleMaxStatusCount, setVisibleMaxStatusCount] = useState(0);
  const [visibleTimeline, setVisibleTimeline] = useState([]);
  const [statusMaxCount, setStatusMaxCount] = useState(0);
  const [graphData, setGraphData] = useState();

  /* Policies */
  // const [allPolicies, setAllPolicies] = useState();
  const [defaultOrderedAllPolicies, setDefaultOrderedAllPolicies] = useState();
  const [filterHandler, setFilterHandler] = useState({});
  const [policyIndex, setPolicyIndex] = useState([]);
  const [filterStatus, setFilterStatus] = useState(false)
  const [visiblePolicies, setVisiblePolicies] = useState();
  const [maxVisiblePolicies, setMaxVisiblePolicies] = useState();
  const [deathBenefitsRange, setDeathBenefitsRange] = useState({minVal : 0, maxVal : 0, fixedMinVal : 0, fixedMaxVal : 0});
  const [searchVal, setSearchVal] = useState('');
  const [searchValHandler, setSearchValHandler] = useState([]);
  const [emptyDeathBenefit, setEmptyDeathBenefit] = useState(true);
  const [deathBenefitsRangeReset, setDeathBenefitsRangeReset] = useState(false);
  const [appView, setAppView] = useState("default");
  const [policyOrderTracker, setPolicyOrderTracker] = useState({
    policyStatus : 0,
    policyNumber : 0,
    primaryLastName : 0,
    deathBenefit : 0,
    insuranceCompany : 0,
    count : 0});
  const [policyOrderDirection, setPolicyOrderDirection] = useState({
    policyStatus : '',
    policyNumber : '',
    primaryLastName : '',
    deathBenefit : '',
    insuranceCompany : '',
    count : ''
  });

  var filterTracker = {
    status : {
      activeCount : 0,
      maxCount : 0,
    },
    leadSource : {
      activeCount : 0,
      maxCount : 0,
    },
    affiliates : {
      activeCount : 0,
      maxCount : 0,
    },
    priceRangeGroup : {
      deathBenefits : [],
      deathBenefitsMap : [],
      emptyCount : 0 },
  };

  const policiesProps = {
    sessionVars : props.sessionVars,
    policies : allPolicies,
    setPolicies : setAllPolicies
  }

  const closedPolicyOptions = [
    "Closed Won",
    "Invoicing",
  ];

  const inactivePolicyOptions = [
    "Closed Lost",
    "Closed Lost to Competitor",
    "Closed Lost with Bid",
    "Closed Lost no bid",
    "Sent to 3rd Party",
    "Client Not Interested",
    "Unqualified Closed",
    "Client Deceased"
  ];

  function updateDeathBenefitsRange(currentMinPrice, currentMaxPrice, setFixed){
    if(setFixed){
      setDeathBenefitsRange({minVal : currentMinPrice, maxVal : currentMaxPrice, fixedMinVal : currentMinPrice, fixedMaxVal : currentMaxPrice });
    }else{
      setDeathBenefitsRange( prevState => ({ ...prevState, minVal : currentMinPrice, maxVal : currentMaxPrice }));
    }
  }

  function updateDeathBenefitsRangeReset(){
    setDeathBenefitsRangeReset(false);
  }

  function updateSearchVal(evt, reset){
    if(reset){
      setSearchVal('');
    }else{
      setSearchVal(evt.target.value);
    }
  }

  function compareStrings(haystack, searchData){
    let splitHaystackArray = haystack?.split(" ");
    let splitMatchCounter = 0;

    if(searchValHandler.length > 1 && !(splitHaystackArray.length > 1)){
      for (var i = 0; i < searchValHandler.length; i++){
        if(comparisonStatus(haystack, searchValHandler[i]) && containsNoWhiteSpace(searchValHandler[i])){
          searchData.completeMatch++;
          delete searchData.keyCounter[i];
          searchData.returningInt++;
          splitMatchCounter++;
        }
      }
    }else if(searchValHandler.length > 1 && splitHaystackArray.length > 1){
        for (var i = 0; i < splitHaystackArray.length; i++){
          for (var x = 0; x < searchValHandler.length; x++){
            if(comparisonStatus(splitHaystackArray[i], searchValHandler[x]) && containsNoWhiteSpace(searchValHandler[x])){
              searchData.completeMatch++;
              delete searchData.keyCounter[x];

              searchData.returningInt++;
              splitMatchCounter++;
            }
          }
        }
    }else if(!(searchValHandler.length > 1) && splitHaystackArray.length > 1){
      for (var i = 0; i < splitHaystackArray.length; i++){
        if(comparisonStatus(splitHaystackArray[i], searchValHandler[0]) && containsNoWhiteSpace(searchValHandler[0])){
          searchData.completeMatch++;
          searchData.returningInt++;
          splitMatchCounter++;
          delete searchData.keyCounter[0];
        }
      }
     }else{
      if(comparisonStatus(haystack, searchValHandler[0])){
        delete searchData.keyCounter[0];
        searchData.returningInt++;
      }
    }

    if(splitMatchCounter >= (searchValHandler.length - 1)){
        searchData.splitMatch++; 
    }

    return searchData;
  }

  function checkPolicyActiveStatus(i, policyKey){
    // console.log(456, i, allPolicies, policyKey);
    // if(!filterHandler?.status || !allPolicies?.[i]?.[policyKey]){
    if(!filterHandler?.status || !allPolicies?.[i]){
        return true;
    }

    // if(filterHandler.status[allPolicies[i][policyKey]?.policyStatus]?.active === false){
    if(filterHandler.status[allPolicies[i]?.policyStatus]?.active === false){
        return false;
    }

    if(filterHandler.affiliates[allPolicies[i]?.relatedAccountID]?.active === false){
      // if(filterHandler.affiliates[allPolicies[i][policyKey]?.relatedAccountID]?.active === false){
        return false;
    }

    if(filterHandler.affiliates[allPolicies[i]?.relatedAccountID]?.funds?.[allPolicies[i]?.fundID]?.active === false){
      // if(filterHandler.affiliates[allPolicies[i][policyKey]?.relatedAccountID]?.funds?.[allPolicies[i][policyKey]?.fundID]?.active === false){
        return false;
    }

    if(filterHandler.leadSource[allPolicies[i]?.leadSource]?.active === false){
      // if(filterHandler.leadSource[allPolicies[i][policyKey]?.leadSource]?.active === false){
        return false;
    }

    if((allPolicies?.[i]?.deathBenefit >= deathBenefitsRange.minVal && allPolicies?.[i]?.deathBenefit <= deathBenefitsRange.maxVal) === false){
      // if((allPolicies?.[i]?.[policyKey]?.deathBenefit >= deathBenefitsRange.minVal && allPolicies?.[i]?.[policyKey]?.deathBenefit <= deathBenefitsRange.maxVal) === false){
        return false;
    }

    if(emptyDeathBenefit === false){
        return false;
    }

    if(searchVal !== ''){
      var searchData = {
        returningInt : 0,
        completeMatch : 0,
        splitMatch : 0,
        keyCounter : keyMapSearchVal(searchValHandler)
      };

      if(searchValHandler.length > 0){
        let eligableMark = searchValHandler.length - 1;
        // searchData = compareStrings(allPolicies[i][policyKey].primaryFirstName, searchData);
        // searchData = compareStrings(allPolicies[i][policyKey].primaryLastName, searchData);
        // searchData = compareStrings(allPolicies[i][policyKey].policyNumber, searchData);
        searchData = compareStrings(allPolicies[i].primaryFirstName, searchData);
        searchData = compareStrings(allPolicies[i].primaryLastName, searchData);
        searchData = compareStrings(allPolicies[i].policyNumber, searchData);
  
        if(searchValHandler.length > 1){
          if(!containsNoWhiteSpace(searchValHandler[searchValHandler.length - 1])){
              eligableMark--;
          }
        }

        searchData.keyCounter = searchData.keyCounter.filter(function (el) {
          return el != null;
        });

        if(searchValHandler.length > 1 && searchData.keyCounter.length > 0){
          return false;
        }

        if(searchData.returningInt <= eligableMark){
          return false;
        }
      }
    }

    return true;
  }

  function loadPolicyOverview(){

    if(initializationHandler.policies === true){
      return;
    }else{
      setInitializationHandler(prevState => ({
        ...prevState,
        policies: true
      }));      
    }

    const paramVals = {
      "accountID" : props.sessionUser.accountID
    };

    var x = 0;
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const filters = {
      status : "binary",
      leadSource : "binary",
      deathBenefit : "range",
      age : "range",
      affiliate : "binary",
      carrier : "binary"
    }

    const rangeKeys = {
      deathBenefit: "deathBenefit",
    };

    const filterFunctions = props.sessionVars.envVars.filterHandler.functions;
    const filterTracker2 = filterFunctions.buildFilterTracker(filters);

    props.session?.env?.functions?.buildFetchRequest("pullAccountPolicies", paramVals)
        .then(response => response.json())
        .then(resData => {
            let tempAllPolicies = [];
            let activeCount = 0;
            let closedCount = 0;
            let inactiveCount = 0;
            let countedPolicy = [];
            let tempChartData = {
              friendlyTotal : 0,
              total : 0,
              totalFaceValue : 0,
              totalActiveDeathBenefit : 0,
              totalActiveFaceValue : 0,
              Matured : {
                faceValue : 0,
                count : 0
              },
              Lapsed : {
                faceValue : 0,
                count : 0
              },
              Sold : {
                faceValue : 0,
                count : 0
              },
              servicing : {
                total : 0,
                totalFaceValue : 0,
                totalActiveDeathBenefit : 0,
                totalActiveFaceValue : 0,
                Matured : {
                  faceValue : 0,
                  count : 0
                },
                Lapsed : {
                  faceValue : 0,
                  count : 0
                },
                Sold : {
                  faceValue : 0,
                  count : 0
                },
              }
            };

            let tempPolicyData = {};
            let tempPolicyIndex = [];

            if(resData.status === 200){
              filterTracker.status = {
                activeCount : 0,
                maxCount : 0,
              }
              filterTracker.leadSource = {
                activeCount : 0,
                maxCount : 0,
              }
              filterTracker.affiliates = {
                activeCount : 0,
                maxCount : 0,
              }

              for (var currentRecord in resData.policyRecords){
                var policyRecord = {};
                let policy = resData.policyRecords[currentRecord];
                let accountType;

                policyRecord = policy;
                policyRecord.primaryFirstName = policy?.primaryFirstName?.trim();
                policyRecord.primaryLastName = policy?.primaryLastName?.trim();
                policyRecord.deathBenefit = policy?.faceValue ?? policy?.deathBenefit;

                if(policy.type === "servicing"){
                //  policyRecord.x = x;
                //console.log(policyRecord);
                  tempPolicyData[policyRecord.recordID] = policyRecord;
                  tempPolicyIndex.push(policyRecord.recordID);
                  accountType = "servicing";
                  // props?.session?.case?.setData("policies.servicing", policyRecord);
                  if(policyRecord.policyStatus === "Lapsed" || policyRecord.policyStatus === "Matured"){
                    tempChartData.servicing.totalDeathBenefit += policyRecord.deathBenefit;
                    tempChartData.servicing[policyRecord.policyStatus].count++;
                    tempChartData.servicing[policyRecord.policyStatus].faceValue += policyRecord?.deathBenefit;
                  }

                  if(policyRecord.policyStatus === "Owned"){
                    tempChartData.servicing.total++;
                    tempChartData.servicing.totalActiveFaceValue += policyRecord?.deathBenefit;
                  }
                }else if (policy.type === "origination"){
                  //props?.session?.case?.setData("policies.origination", policyRecord);
                }

                if(policy.relatedPolicyID){
                //  policyRecord.x = x;
                  tempPolicyData[policyRecord.relatedPolicyID] = policyRecord;
                  tempPolicyIndex.push(policyRecord.relatedPolicyID);
                  accountType = "origination";
                };

                if(!accountType){
                //  policyRecord.x = x;
                  tempPolicyData[policyRecord.relatedPolicyID] = policyRecord;
                  tempPolicyIndex.push(policyRecord.relatedPolicyID);
                  accountType = "origination";
                };

                filterTracker.status.activeCount++;
                filterTracker.leadSource.activeCount++;
                filterTracker.affiliates.activeCount++;

                //Add Policy Source Name to Filters


                // if(policyRecord.sourceName){
                  // console.log(policyRecord);

                  if(filterTracker.affiliates.hasOwnProperty(policyRecord.relatedAccountID)){
                    filterTracker.affiliates[policyRecord.relatedAccountID].count++;

                    if(policyRecord?.fundID){
                      if(!filterTracker.affiliates[policyRecord.relatedAccountID]["funds"][policyRecord?.fundID]){
                        filterTracker.affiliates[policyRecord.relatedAccountID]["funds"][policyRecord?.fundID] = {
                          count : 1,
                          active : true,
                          type : "affiliates",
                          accountType : accountType ?? false,
                          fundName : policyRecord?.ownerName ?? false,
                          fundID : policyRecord?.fundID ?? false
                        };
                      }else{
                        filterTracker.affiliates[policyRecord.relatedAccountID]["funds"][policyRecord?.fundID].count++;
                      }
                    }
                  }else{
                    filterTracker.affiliates[policyRecord.relatedAccountID] = {
                      count : 1,
                      active : true,
                      type : "affiliates",
                      accountType : accountType ?? false,
                      accountName : props.sessionVars?.envVars?.adminHandler[policyRecord?.relatedAccountID]?.name ?? false,
                      fundID : policyRecord?.fundID ?? false
                    };

                    if(policyRecord?.fundID){
                      filterTracker.affiliates[policyRecord.relatedAccountID]["funds"] = [];
                      filterTracker.affiliates[policyRecord.relatedAccountID]["funds"][policyRecord?.fundID] = {
                        count : 1,
                        active : true,
                        type : "affiliates",
                        accountType : accountType ?? false,
                        fundName : policyRecord?.ownerName ?? false,
                        fundID : policyRecord?.fundID ?? false
                      };
                    }
                  }  
                // }

                //Add Policy Status to Filters
                if(policyRecord.policyStatus){
                  if(filterTracker.status.hasOwnProperty(policyRecord.policyStatus)){
                    filterTracker.status[policyRecord.policyStatus].count++;
                  }else{
                    filterTracker.status[policyRecord.policyStatus] = {
                      count : 1,
                      active : true,
                      type : "status",
                      accountType : accountType ?? false
                    };
                  }
                }

                //Add Policy Lead Source to Filters
                if(policyRecord.leadSource){
                  if(policyRecord.leadSource === "Marketplace"){
                    policyRecord.leadSource = "Direct";
                  }

                  if(filterTracker.leadSource.hasOwnProperty(policyRecord.leadSource)){
                    filterTracker.leadSource[policyRecord.leadSource].count++;
                  }else{
                    filterTracker.leadSource[policyRecord.leadSource] = {
                        count : 1,
                        active : true,
                        type : "leadSource",
                        accountType : accountType ?? false
                      };
                  }
                }else{
                  policyRecord.leadSource = "Serviced Policy";
                  if(policyRecord?.fundID){
                    if(filterTracker.leadSource.hasOwnProperty("Serviced Policy")){
                      filterTracker.leadSource["Serviced Policy"].count++;
                    }else{
                      filterTracker.leadSource["Serviced Policy"] = {
                          count : 1,
                          active : true,
                          type : "leadSource",
                          accountType : accountType ?? false
                        };
                    }
                  }else{
                    policyRecord.leadSource = "Direct";
                    if(filterTracker.leadSource.hasOwnProperty("Direct")){
                      filterTracker.leadSource["Direct"].count++;
                    }else{
                      filterTracker.leadSource["Direct"] = {
                        count : 1,
                        active : true,
                        type : "leadSource",
                        accountType : accountType ?? false
                      };
                    }  
                  }
                }

                if(resData.policyRecords[x].policyNumber){
                  if(!countedPolicy.includes(resData.policyRecords[x].policyNumber)){
                    if(closedPolicyOptions.includes(resData.policyRecords[x].policyStatus)){
                      closedCount++;
                    }else if(inactivePolicyOptions.includes(resData.policyRecords[x].policyStatus)){
                      inactiveCount++;
                    }else{
                      activeCount++;
                    }
                  }
                }

                if(policyRecord.deathBenefit === null){
                    filterTracker.priceRangeGroup.emptyCount++;
                    filterTracker.priceRangeGroup.deathBenefitsMap.push(policyRecord.deathBenefit);
                }else{
                    filterTracker.priceRangeGroup.deathBenefits.push(policyRecord.deathBenefit);
                    filterTracker.priceRangeGroup.deathBenefitsMap.push(policyRecord.deathBenefit);
                }

                Object.entries(rangeKeys).forEach(([rangeKey, childProp]) => {
                  let attr = childProp;
                  if(childProp === "deathBenefit"){
                    attr = "deathBenefit";
                  }
                  const currentData = filterTracker2[rangeKey];
                  filterFunctions.updateRangeData(currentData, policyRecord[attr]);
                });

                if(accountType === "origination"){
                  if(tempChartData.hasOwnProperty(policyRecord.policyStatus)){
                    tempChartData[policyRecord.policyStatus].count++;
                    tempChartData[policyRecord.policyStatus].deathBenefit += policyRecord.deathBenefit ? policyRecord.deathBenefit : 0;
                    tempChartData[policyRecord.policyStatus].faceValue += policyRecord?.faceValue ?? 0;
                  }else{
                    let currentStatus = returnStatusData(policyRecord.policyStatus, "Status", true, true);
                    tempChartData[policyRecord.policyStatus] = {
                      count : 1,
                      status : policyRecord.policyStatus,
                      deathBenefit : policyRecord.deathBenefit ? policyRecord.deathBenefit : 0,
                      faceValue : policyRecord?.faceValue ?? 0,
                      position : currentStatus.position ? capitalizeFirstLetter(currentStatus.position) : null,
                      icon : currentStatus.icon,
                      color : currentStatus.color
                    };
                  }

                  // if(!tempChartData.hasOwnProperty(policyRecord?.position?.toLowerCase())){
                  //   if(policyRecord?.position){
                  //     tempChartData[policyRecord.position.toLowerCase()] = {
                  //       statement : policyRecord.position,
                  //       count : 1
                  //     }
                  //   }
                  // }else{
                  //   if(policyRecord?.position){
                  //     tempChartData[policyRecord.position.toLowerCase()].count++;
                  //   }
                  // }

                  if (policyRecord?.position?.toLowerCase() === "inactive" && policyRecord?.lastUpdate) {
                    const lastUpdateDate = new Date(policyRecord.lastUpdate);
                    const currentDate = new Date();
                    const ninetyDaysAgo = new Date();
                    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
                  
                    if (lastUpdateDate >= ninetyDaysAgo && lastUpdateDate <= currentDate) {
                      const positionKey = policyRecord.position.toLowerCase();
                  
                      tempChartData[positionKey] = tempChartData[positionKey] || {
                        statement: policyRecord.position,
                        count: 0,
                      };
                  
                      tempChartData[positionKey].count++;
                      tempChartData.friendlyTotal++;
                    }
                  } else if (policyRecord?.position) {
                    let positionKey = policyRecord.position.toLowerCase();
                    if(policyRecord?.policyStatus === "Invoicing"){
                      positionKey = "closed";
                    }

                    tempChartData[positionKey] = tempChartData[positionKey] || {
                      statement: policyRecord.position,
                      count: 0,
                    };
                  
                    tempChartData[positionKey].count++;
                    tempChartData.friendlyTotal++;
                  }

                  tempChartData.total++;
                  tempChartData.totalFaceValue += policyRecord?.faceValue ?? 0;
  
                  if(policyRecord.policyStatus !== "Matured" && policyRecord.policyStatus !== "Sold" && policyRecord.fundID && policyRecord.policyStatus !== "Lapsed"){
                    tempChartData.totalActiveFaceValue += policyRecord?.faceValue ?? 0;
                  }
                }

                countedPolicy.push(resData.policyRecords[x].policyNumber);
                tempAllPolicies[accountType + (policyRecord.fundID ? policyRecord.recordID : policyRecord.relatedPolicyID)] = policyRecord;
                props?.session?.case?.functions?.updateAttributeData(policyRecord, accountType);

                x++;
              }

              const arrayOfObjects = Object.entries(tempAllPolicies).map(([key, value]) => {
                return { [key]: value };
              });

              // setAllPolicies(arrayOfObjects);
              // setDefaultOrderedAllPolicies(arrayOfObjects);

              props.loadSessionPolicies(tempPolicyData);

              const filtersArray = Object.entries(filterTracker.status);
              const sortedFiltersArray = filtersArray.sort((a, b) => b[1].count - a[1].count);
              const sortedFiltersObject = {};
              sortedFiltersArray.forEach(([key, filter]) => {
                sortedFiltersObject[key] = filter;
              });

              filterTracker.status = sortedFiltersObject;

              var sortedDeathBenefits = filterTracker.priceRangeGroup.deathBenefits.slice().sort(function(a, b) {
                  return a - b;
              });

              filterTracker.priceRangeGroup.deathBenefits = sortedDeathBenefits;
              if(JSON.stringify(policyIndex) !== JSON.stringify(tempPolicyIndex)){
                setPolicyIndex(tempPolicyIndex);
              }

              if(JSON.stringify(props.chartData) !== JSON.stringify(tempChartData)){
                props.updateChartData(tempChartData);
              }

              if(JSON.stringify(filterHandler) !== JSON.stringify(filterTracker)){
                setFilterHandler(filterTracker);
              }

              updateDeathBenefitsRange(sortedDeathBenefits[0], sortedDeathBenefits[sortedDeathBenefits.length - 1], true);

              filterTracker.status.maxCount = filterTracker.status.activeCount;
          }

          if(resData.status === 500){
            props.updateChartData({});
            console.log("No policies found.");
          }

          setMaxVisiblePolicies(32);
          props.sessionVars.envVars.filterHandler.functions.initializeFilterBranch("policies", filterTracker);

        return filterTracker;
    });
}

function loadAccountPolicies(){
  var visiblePolicyCount = 0;
  const tempPolicyList = [];
  if(!allPolicies){
    return;
  }

  allPolicies.forEach((policy, index) => {
    // const policyKey = Object.keys(policy)[0];
    // const policyData = policy[policyKey];
    let activeBool = checkPolicyActiveStatus(index, policy?.recordID);
    if(maxVisiblePolicies > visiblePolicyCount + 1 && activeBool){
      tempPolicyList.push(
        <PolicyRows
          key={policy?.recordID}
          active={activeBool}
          policyData={policy}
          changeActivityView={changeActivityView}
          searchValHandler={searchValHandler}
          mobileDevice={props.mobileDevice}
          sessionUser={props.sessionUser}
          session={props?.session}
          viewType={props?.session?.env?.viewport?.viewType}
        />
      );

      visiblePolicyCount++;
    }
  });

  props.setPolicyList(tempPolicyList);
  setVisiblePolicies(visiblePolicyCount);

  return;

}

  useEffect(() => {
    loadAccountPolicies();
    // console.log("?");
    // props?.session?.set("case", "selectedCases", props?.session?.case?.functions?.printPolicies());
    // props?.session?.set("case", "selectedCases", props?.session?.case?.functions?.printPolicies());
  }, [
    deathBenefitsRange,
    filterHandler,
    emptyDeathBenefit,
    searchValHandler,
    props.policies,
    policyOrderTracker,
    policyOrderDirection,
    maxVisiblePolicies,
    allPolicies,
    props?.session?.env?.viewport?.viewType
  ]);

  // props?.session?.useListen(() => {
  //   //loadAccountPolicies();
  //   console.log("Loading Policies 1");

  //   // props?.session?.set("case", "selectedCases", props?.session?.case?.functions?.printPolicies());
    
  //   props?.session?.set("case", "selectedCases", props?.session?.case?.functions?.printPolicies());
  // }, [
  //   props?.session?.case?.data?.policies
  // ], true);

  function updateTimeline(timelineData){
    return;
    setHomepageTimeline(timelineData);
  }

  /* Home Page */
  function loadHomePageGraph(){

    if(!graphData){
      let requestOptions;

      if(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type && /Fund/.test(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type)){
        requestOptions = {
            "accountID" : props.sessionUser.accountID,
        };
      }else{
        return;
      }

      var x = 0;
      if(requestOptions){
        props.session?.env?.functions?.buildFetchRequest("servicing/getAccountPremiumSchedule", requestOptions)
        .then(response => response.json())
        .then(resData => {

          let timelineData = {};
          if(resData.status == 200){
          //  console.log(resData);
            Object.keys(resData.projectedPremiums).forEach(function(accountID) {
              let projectedPremiums = resData.projectedPremiums[accountID];
              let currentMonthTotal = 0;
              let nextMonthTotal = 0;
              let twelveMonthTotal = 0;
              resData.projectedPremiums.currentMonthTotal = 0;
              resData.projectedPremiums.nextMonthTotal = 0;
              resData.projectedPremiums.twelveMonthTotal = 0;
          
              // Calculate the first available full month
              let currentDate = new Date();
              let currentMonth = currentDate.getMonth();
              let nextYear = currentDate.getFullYear() + 1;
              let firstFullMonth = (currentMonth < 10) ? '0' + (currentMonth + 1) : (currentMonth + 1);
          
              // Calculate the 12-month date range
              let twelveMonthDate = new Date();
              twelveMonthDate.setFullYear(currentDate.getFullYear() + 1);
          
              // Loop through each projected premium for the account
              projectedPremiums.premiums.forEach(function(projectedPremium) {
                // Get the projected premium's date
                let premiumDate = new Date(projectedPremium.date);
          
                // Check if the projected premium is due in the current month
                if (premiumDate.getMonth() == currentDate.getMonth() &&
                  premiumDate.getFullYear() == currentDate.getFullYear()) {
                  currentMonthTotal += projectedPremium.projectedPremium;
                }
          
                // Check if the projected premium is due in the next month
                if (premiumDate.getMonth() == currentDate.getMonth() + 1 &&
                  premiumDate.getFullYear() == currentDate.getFullYear()) {
                  // Check if the projected premium is due on or after the first day of the next month
                  if (premiumDate >= new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)) {
                    nextMonthTotal += projectedPremium.projectedPremium;
                  }
                }
          
                // Check if the projected premium falls within the 12-month period
                if (premiumDate >= currentDate && premiumDate <= twelveMonthDate) {
                  twelveMonthTotal += projectedPremium.projectedPremium;
                }
              });
          
              // Add the new attributes to the resData object for the account
              resData.projectedPremiums[accountID].nextMonthTotal = nextMonthTotal;
              resData.projectedPremiums[accountID].twelveMonthTotal = twelveMonthTotal;
              resData.projectedPremiums[accountID].currentMonthTotal = currentMonthTotal;
              resData.projectedPremiums.nextMonthTotal += nextMonthTotal;
              resData.projectedPremiums.twelveMonthTotal += twelveMonthTotal;
              resData.projectedPremiums.currentMonthTotal += currentMonthTotal;
            });

            if(JSON.stringify(graphData) !== JSON.stringify(resData.projectedPremiums)){
              setGraphData(resData.projectedPremiums);
            }
          }

          if(resData.status == 500){
              console.log("No premium data found.");
              setGraphData({});
          }
        });
      }
    }
  }

  function loadHomePageTimelineData(resync){

    if(initializationHandler.homepage === true){
      return;
    }else{
      setInitializationHandler(prevState => ({
        ...prevState,
        homepage: true
      }));
      
    } 

    if(homepageTimeline){
      return;
    }

    function calculateInclusion(accountID){
      return true;
      if(Object.keys(props.sessionUser?.accountData)?.length === 1){
        return true;
      }

      let accountName = props.sessionUser?.accountData?.[accountID]?.accountName;
      var counter = 0;

      if(!filterHandler?.status?.[accountName]?.active){
          counter++;
      }

      if(!profileProps.kanbanToggles.includes(accountID)){
          counter++;
      }

      if(!profileProps.listToggles.includes(accountID)){
          counter++;
      }

      if(counter === 3){
        console.log("Return FALSE!");
        return false;
      }

      return true;
    }

    if(!homepageTimeline || resync){
      let requestOptions = null;
      requestOptions = {
          "accountID" : props.sessionUser.accountID,
      };

      if(requestOptions){
        props.session?.env?.functions?.buildFetchRequest("pullHomePageTimeline", requestOptions)
        .then(response => response.json())
        .then(resData => {
          let timelineData = {};

          if(resData.status == 200){
            for (var key in resData.timeline){
              if(calculateInclusion(resData.timeline[key].accountID)){
                timelineData[key] = resData.timeline[key];
                if (!timelineData[key].hasOwnProperty("insuredFullName")) {
                  timelineData[key].insuredFullName = timelineData[key].lastName + ", " + timelineData[key].firstName;
                }
              }
            }

            setStatusHandler({timelineData : timelineData});
            setHomepageTimeline({timelineData : timelineData})
            setVisibleMaxStatusCount(32);
          }else{
            setHomepageTimeline({});
          }
        });   
      }

    }else{
      setVisibleMaxStatusCount(20);
    }
  }

  function loadHomepageTimeline(){
    let timelineBlock = [];
    let statusCount = 0;

    if (!homepageTimeline?.timelineData) {
      return;
    }

    let timelineType;
    if(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type){
      if(/Broker|Agent|Origination/.test(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type)){
        timelineType = "origination";
      }

      if(/Fund/.test(props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type)){
        timelineType = "fund";
      }
    }

    for (var key of Object.keys(homepageTimeline?.timelineData)){

      if(statusCount < visibleMaxStatusCount){
          if(homepageTimeline?.timelineData[key].objectType === "servicing"){
            statusCount++;
            timelineBlock.push(
              <HomePageTimelinePost
                key={key}
                status={homepageTimeline?.timelineData[key].status}
                timeStamp={homepageTimeline?.timelineData[key].timeStamp}
                type={homepageTimeline?.timelineData[key].type}
                timelineType={timelineType}
                data={homepageTimeline?.timelineData[key]}
                redirect={props.updatePage}
                changeActivityView={changeActivityView}
                mobileDevice={props.mobileDevice}
                sessionUser={props.sessionUser}
                session={props?.session}
              />
            );
          }

          if(homepageTimeline?.timelineData[key].objectType === "origination" || homepageTimeline?.timelineData[key].objectType === "marketplace"){
            statusCount++;
            timelineBlock.push(
              <HomePageTimelinePost
                key={key}
                status={homepageTimeline?.timelineData[key].objectType === "marketplace" ? homepageTimeline?.timelineData[key].content : homepageTimeline?.timelineData[key].status}
                timeStamp={homepageTimeline?.timelineData[key].timeStamp}
                type={homepageTimeline?.timelineData[key].type}
                timelineType={timelineType}
                data={homepageTimeline?.timelineData[key]}
                redirect={props.updatePage}
                changeActivityView={changeActivityView}
                mobileDevice={props.mobileDevice}
                sessionUser={props.sessionUser}
                session={props?.session}
              />
            );
          }
      }
    }

    setStatusMaxCount(statusCount);
    setVisibleTimeline(timelineBlock);
  }

  useEffect(() => {
    loadHomepageTimeline();
  }, [homepageTimeline?.timelineData, props?.session?.env?.viewport?.viewType]);

  function orderPolicies(sortBy) {
    const tempOrderHandler = { ...policyOrderDirection };
    const newOrder = (tempOrderHandler[sortBy] === '') ? 'desc' : (tempOrderHandler[sortBy] === 'desc') ? 'asc' : '';
    setMaxVisiblePolicies(32);

    tempOrderHandler[sortBy] = newOrder;
    setPolicyOrderDirection(prevState => ({
      ...prevState,
      [sortBy]: newOrder
    }));
  
    function compareValues(valueA, valueB) {
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }
  
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    }
  
    const allEmpty = Object.values(tempOrderHandler).every(order => order === '');
  
    if (allEmpty) {
      setAllPolicies(defaultOrderedAllPolicies); // Reset to the initial order
      return;
    }
  
    let sortingCategories = Object.keys(tempOrderHandler).filter(category => tempOrderHandler[category] !== '');
  
    if (!sortingCategories.includes(sortBy)) {
      sortingCategories = [sortBy, ...sortingCategories.filter(category => category !== sortBy)];
    }
  
    const policyArray = Object.values(allPolicies);
  
    policyArray.sort((a, b) => {
      let comparison = 0;
      let priority = 1;
  
      for (const category of sortingCategories) {
        switch (category) {
          case 'policyStatus':
            comparison = compareValues(a[Object.keys(a)[0]].policyStatus, b[Object.keys(b)[0]].policyStatus);
            break;
          case 'policyNumber':
            comparison = compareValues(a[Object.keys(a)[0]].policyNumber, b[Object.keys(b)[0]].policyNumber);
            break;
          case 'primaryLastName':
            comparison = compareValues(a[Object.keys(a)[0]].primaryLastName, b[Object.keys(b)[0]].primaryLastName);
            break;
          case 'deathBenefit':
            comparison = compareValues(a[Object.keys(a)[0]].deathBenefit, b[Object.keys(b)[0]].deathBenefit);
            break;
          case 'insuranceCompany':
            comparison = compareValues(a[Object.keys(a)[0]].insuranceCompany, b[Object.keys(b)[0]].insuranceCompany);
            break;
          default:
            comparison = 0;
            break;
        }
  
        if (comparison !== 0) {
          const sortOrder = tempOrderHandler[category];
          comparison *= (sortOrder === 'desc') ? -1 : 1;
          break;
        }
  
        priority++;
      }
  
      return comparison !== 0 ? comparison : sortingCategories.indexOf(sortBy) - sortingCategories.indexOf(sortingCategories[priority - 1]);
    });

    setAllPolicies(policyArray);
  }

/* Upload */

  const [insuranceCompanyList, setInsuranceCompanyList] = useState({});

  function updateInsuranceCompanyList(insuranceCompanyData){
    setInsuranceCompanyList(insuranceCompanyData);
  }

  function loadInsuranceCompanyList(){
    if(initializationHandler.insuranceCompanyList === true){
      return;
    }else{
      const paramVals = {
        "accountID" : props.sessionUser.accountID,
      };

      var x = 0;

      props.session?.env?.functions?.buildFetchRequest("pullInsuranceCompanyList", paramVals)
        .then(response => response.json())
        .then(resData => {
            let insuranceCompanyListData = {};
            if(resData.status === 200){
                for (var currentInuranceCompany in resData.insuranceCompaniesData){
                    const insuranceCompany = {
                      recordID : resData.insuranceCompaniesData[x].recordID,
                      insuranceCompanyName : resData.insuranceCompaniesData[x].insuranceCompanyName
                    };
                    insuranceCompanyListData[x] = insuranceCompany;
                    x++;
                }
                updateInsuranceCompanyList(insuranceCompanyListData);
            }

            if(resData.status === 500){
                console.log("No insurance companies found.");
            }

            setInitializationHandler(prevState => ({
              ...prevState,
              insuranceCompanyList: true
            }));
      });
    }
  }

  /* Leads */

  const kanbanLeads = useRef();
  const kanbanContactAttempted = useRef();
  const kanbanInProgress = useRef();
  const [leadsList, setLeadsList] = useState([]);
  const [leadsFilters, setLeadsFilters] = useState([]);
  const [leadsSearchVal, setLeadsSearchVal] = useState('');
  const [leadsListPriceRange, setLeadsListPriceRange] = useState({});
  const [categoryLeadList, setCategoryLeadList] = useState([]);
  const [categoryContactAttemptList, setCategoryContactAttemptList] = useState([]);
  const [categoryInProgress, setCategoryInProgress] = useState([]);
  const [leadBlockKanbanData, setLeadBlockKanbanData] = useState();
  const [leadTeamFilter, setLeadTeamFilter] = useState("assignedOnly");
  const [ignoredTeamList, setIgnoredTeamList] = useState([]);
  const [kanbanToggles, setKanbanToggles] = useState([
    "Lead", "Contact Attempted 1", "Contact Attempted 2", "Application Sent", "Application Received", "Follow Up"
  ]);
  const [kanbanLeadVisibleThreshold, setKanbanLeadVisibleThreshold] = useState(24);
  const [kanbanContactAttemptedVisibleThreshold, setKanbanContactAttemptedVisibleThreshold] = useState(24);
  const [kanbanInProgressVisibleThreshold, setKanbanInProgressVisibleThreshold] = useState(24);
  const [kanbanNotificationToggles, setKanbanNotificationToggles] = useState([]);
  const [showLeadOptions, setShowLeadOptions] = useState(false);
  const [currentLeadInfo, setCurrentLeadInfo] = useState({});
  const [leadActivityView, setLeadActivityView] = useState('kanban');
  const [listAllVisibleThreshold, setListAllVisibleThreshold] = useState(48);
  const [showLeadFilters, setShowLeadFilters] = useState(false);
  const [leadBlockListData, setLeadBlocListkData] = useState([]);
  const [viewAllList, setViewAllList] = useState(["Lead", "Contact Attempted 1", "Contact Attempted 2", "Application Sent", "Application Received", "Follow Up"]);
  const [leadFilterPanelType, setLeadFilterPanelType] = useState("toggle");
  const [listToggles, setListToggles] = useState([]);
  const [currentColumn, setCurrentColumn] = useState("lead");
  const [yScrollList, setYScrollList] = useState(0);
  const [yScrollKanban, setYScrollKanban] = useState({
    lead : 0,
    contactAttempt : 0,
    inProgress : 0
  });

  const leadProps = {
    kanbanLeads : kanbanLeads,
    kanbanContactAttempted : kanbanContactAttempted,
    kanbanInProgress : kanbanInProgress,
    yScrollKanban : yScrollKanban,
    setYScrollKanban : setYScrollKanban,
    yScrollList : yScrollList,
    setYScrollList : setYScrollList,
    showLeadFilters : showLeadFilters,
    setShowLeadFilters : setShowLeadFilters,
    leadBlockListData : leadBlockListData,
    setLeadBlocListkData : setLeadBlocListkData,
    viewAllList : viewAllList,
    setViewAllList : setViewAllList,
    leadFilterPanelType : leadFilterPanelType,
    setLeadFilterPanelType : setLeadFilterPanelType,
    listToggles : listToggles,
    setListToggles : setListToggles,
    currentColumn : currentColumn,
    setCurrentColumn : setCurrentColumn,
    activityView : leadActivityView,
    setActivityView : setLeadActivityView,
    leadsList : leadsList,
    setLeadsList : setLeadsList,
    leadsFilters : leadsFilters,
    setLeadsFilters : setLeadsFilters,
    leadsSearchVal : leadsSearchVal,
    leadsListPriceRange : leadsListPriceRange,
    setLeadsListPriceRange : setLeadsListPriceRange,
    categoryLeadList : categoryLeadList,
    setCategoryLeadList : setCategoryLeadList,
    categoryContactAttemptList : categoryContactAttemptList,
    setCategoryContactAttemptList : setCategoryContactAttemptList,
    categoryInProgress : categoryInProgress,
    setCategoryInProgress : setCategoryInProgress,
    leadBlockKanbanData : leadBlockKanbanData,
    setLeadBlockKanbanData : setLeadBlockKanbanData,
    leadTeamFilter : leadTeamFilter,
    setLeadTeamFilter : setLeadTeamFilter,
    ignoredTeamList : ignoredTeamList,
    setIgnoredTeamList : setIgnoredTeamList,
    kanbanToggles : kanbanToggles,
    setKanbanToggles : setKanbanToggles,
    kanbanLeadVisibleThreshold : kanbanLeadVisibleThreshold,
    setKanbanLeadVisibleThreshold : setKanbanLeadVisibleThreshold,
    kanbanContactAttemptedVisibleThreshold : kanbanContactAttemptedVisibleThreshold,
    setKanbanContactAttemptedVisibleThreshold : setKanbanContactAttemptedVisibleThreshold,
    kanbanInProgressVisibleThreshold : kanbanInProgressVisibleThreshold,
    setKanbanInProgressVisibleThreshold : setKanbanInProgressVisibleThreshold,
    kanbanNotificationToggles : kanbanNotificationToggles,
    setKanbanNotificationToggles : setKanbanNotificationToggles,
    showLeadOptions : showLeadOptions,
    setShowLeadOptions : setShowLeadOptions,
    currentLeadInfo : currentLeadInfo,
    setCurrentLeadInfo : setCurrentLeadInfo,
    listAllVisibleThreshold : listAllVisibleThreshold,
    setListAllVisibleThreshold : setListAllVisibleThreshold,
    handleClose : handleLeadsClose,
    printListView : printListView,
    leadsCategoryToggle : leadsCategoryToggle,
    toggleLeadFilters : toggleLeadFilters,
    currentColumnCheck : currentColumnCheck,
    updateLeadsListPriceRange : updateLeadsListPriceRange,
    resetYScrolls : resetYScrolls,
    updateLeadsSearchVal : updateLeadsSearchVal,
    toggleLeadQuickView : toggleLeadQuickView,
    printLeadsKanbanBoard : printLeadsKanbanBoard,
    changeActivityView : changeLeadActivityView,
    setLeadID : setLeadID
  }

  function handleLeadsClose(evt){
    if(evt.target.className === "leadOptionsBoard"){
        setShowLeadOptions(false);
        setShowLeadFilters(false);
    }
  }

  function resetYScrolls(direct){

    if(direct === "kanbanLead"){
      setKanbanLeadVisibleThreshold(24);
      setYScrollKanban(prevState => ({
        ...prevState,
        lead: 0
      }));

      return;
    }

    if(direct === "kanbanContactAttempt"){
      setKanbanContactAttemptedVisibleThreshold(24);
      setYScrollKanban(prevState => ({
        ...prevState,
        contactAttempt: 0
      }));

      return;
    }

    if(direct === "kanbanInProgress"){
      setKanbanInProgressVisibleThreshold(24);
      setYScrollKanban(prevState => ({
        ...prevState,
        inProgress: 0
      }));

      return;
    }

    if(direct === "kanban"){
      setYScrollKanban({
        lead : 0,
        contactAttempt : 0,
        inProgress : 0
      });

      return;
    }

    if(direct === "list"){
      setYScrollList(0);
      printListView();
      return;
    }

    setYScrollKanban({
      lead : 0,
      contactAttempt : 0,
      inProgress : 0
    });

    setKanbanLeadVisibleThreshold(24);
    setKanbanContactAttemptedVisibleThreshold(24);
    setKanbanInProgressVisibleThreshold(24);

    setYScrollList(0);
    printListView();
    printLeadsKanbanBoard();
  }

  function clearCategory(array1, array2) {
    return array1.filter(item => !array2.includes(item));
  }

  function leadsCategoryToggle(category, all, filterType){

    var tempListToggles = null;
    if(filterType === "list"){
      tempListToggles = listToggles;
    }

    if(filterType === "kanban"){
      tempListToggles = kanbanToggles;
    }

    if(all === "only"){
      let allListToggles = [
        "Lead",
        "Contact Attempted 1",
        "Contact Attempted 2",
        "Application Sent",
        "Application Received",
        "Follow Up",
        "Unqualified",
        "Failed to Contact",
        "Client not Interested",
        "Sent to Abacus",
        "New Insurance"
      ];

      const missingElements = allListToggles.filter((elem) => {
        return !tempListToggles.includes(elem);
      });

      tempListToggles = tempListToggles.concat(missingElements);
    }

    if(all){
      if(category === "lead"){
        if(tempListToggles.some(toggleIndex => ["Lead"].includes(toggleIndex))){
          tempListToggles = clearCategory(tempListToggles, ["Lead"]);
        }else{
          tempListToggles.push("Lead");
        }
      }

      if(category === "contactAttempted"){
        if(tempListToggles.some(toggleIndex => ["Contact Attempted 1", "Contact Attempted 2"].includes(toggleIndex))){
          tempListToggles = clearCategory(tempListToggles, ["Contact Attempted 1", "Contact Attempted 2"]);
        }else{
          tempListToggles.push("Contact Attempted 1", "Contact Attempted 2");
        }
      }

      if(category === "inProgress"){
        if(tempListToggles.some(toggleIndex => ["Application Sent", "Application Received", "Follow Up"].includes(toggleIndex))){
          tempListToggles = clearCategory(tempListToggles, ["Application Sent", "Application Received", "Follow Up"]);
        }else{
          tempListToggles.push("Application Sent", "Application Received", "Follow Up");
        }
      }

      if(category === "lost"){
        if(tempListToggles.some(toggleIndex => ["Unqualified", "Failed to Contact", "Client not Interested"].includes(toggleIndex))){
          tempListToggles = clearCategory(tempListToggles, ["Unqualified", "Failed to Contact", "Client not Interested"]);
        }else{
          tempListToggles.push("Unqualified", "Failed to Contact", "Client not Interested");
        }
      }

      if(category === "abacus"){
        if(tempListToggles.some(toggleIndex => ["Sent to Abacus"].includes(toggleIndex))){
          tempListToggles = clearCategory(tempListToggles, ["Sent to Abacus"]);
        }else{
          tempListToggles.push("Sent to Abacus");
        }
      }

      if(category === "insurance"){
        if(tempListToggles.some(toggleIndex => ["New Insurance"].includes(toggleIndex))){
          tempListToggles = clearCategory(tempListToggles, ["New Insurance"]);
        }else{
          tempListToggles.push("New Insurance");
        }
      }

      if(category === "success"){
        if(tempListToggles.some(toggleIndex => ["Sent to Abacus", "New Insurance"].includes(toggleIndex))){
          tempListToggles = clearCategory(tempListToggles, ["Sent to Abacus", "New Insurance"]);
        }else{
          tempListToggles.push("Sent to Abacus", "New Insurance");
        }
      }

      if(category === "clear"){
        if(tempListToggles.some(toggleIndex => [
            "Lead",
            "Contact Attempted 1",
            "Contact Attempted 2",
            "Application Sent",
            "Application Received",
            "Follow Up",
            "Unqualified",
            "Failed to Contact",
            "Client not Interested",
            "Sent to Abacus",
            "New Insurance"].includes(toggleIndex))){
              tempListToggles = clearCategory(tempListToggles, [
                "Lead",
                "Contact Attempted 1",
                "Contact Attempted 2",
                "Application Sent",
                "Application Received",
                "Follow Up",
                "Unqualified",
                "Failed to Contact",
                "Client not Interested",
                "Sent to Abacus",
                "New Insurance"
              ]
          );
        }else{
          tempListToggles.push(
            "Lead",
            "Contact Attempted 1",
            "Contact Attempted 2",
            "Application Sent",
            "Application Received",
            "Follow Up",
            "Unqualified",
            "Failed to Contact",
            "Client not Interested",
            "Sent to Abacus",
            "New Insurance"
          );
        }
      }
    }else{
      if(tempListToggles.includes(category)){
        const toggleIndex = tempListToggles.indexOf(category);
        if (toggleIndex > -1) {
            tempListToggles.splice(toggleIndex, 1);
        }
      }else{
        tempListToggles.push(category);
      }
    }

    if(filterType === "list"){
      setListToggles(tempListToggles);
      printListView();
    }

    if(filterType === "kanban"){
      setKanbanToggles(tempListToggles);
      printLeadsKanbanBoard();
    }
  }

  function currentColumnCheck(column){
    if(!props.mobileDevice){
      return '';
    }

    if(column === currentColumn){
      return " selected";
    }

    return " inactive";
  }

  function updateLeadsListPriceRange(minVal, maxVal){
    setLeadsListPriceRange(prevLeadsRangeList => {
      const newLeadsRangeList = prevLeadsRangeList;
      newLeadsRangeList.minVal = minVal;
      newLeadsRangeList.maxVal = maxVal;
      return newLeadsRangeList;
    });

    printListView();
  }

  function toggleLeadFilters(filterType){
    setLeadFilterPanelType(filterType);
    setShowLeadFilters(true);
  }

  function printListView(){
    const searchVal = leadsSearchVal?.toLowerCase();
    var visibleCount = 0;
    var tempViewAllList = [];
    var tempLeadData = {
      lead: {
        count : 0,
        total : 0,
        visibleCount : 0,
        lead: {
          count : 0,
          total : 0,
          visibleCount :0
        }
      },
      contactAttempt:{
        count : 0,
        total : 0,
        visibleCount : 0,
        contactAttempted1 : {
          count : 0,
          total : 0,
          visibleCount : 0
        },
        contactAttempted2 : {
          count : 0,
          total : 0,
          visibleCount : 0
        },
        contactAttempted3 : {
          count : 0,
          total : 0,
          visibleCount : 0
        }
      },
      inProgress: {
        count : 0,
        total : 0,
        visibleCount : 0,
        applicationSent : {
          count : 0,
          total : 0,
          visibleCount : 0
        },
        applicationReceived : {
          count : 0,
          total : 0,
          visibleCount : 0
        },
        followUp : {
          count : 0,
          total : 0,
          visibleCount : 0
        }
      },
      abacus: {
        count : 0,
        total : 0,
        visibleCount : 0
      },
      insurance: {
        count : 0,
        total : 0,
        visibleCount : 0
      },
      closed: {
        count : 0,
        total : 0,
        visibleCount : 0,
        unqualified: {
          count : 0,
          total : 0,
          visibleCount : 0 
        },
        failedToContact: {
          count : 0,
          total : 0,
          visibleCount : 0 
        },
        clientNotInterested: {
          count : 0,
          total : 0,
          visibleCount : 0 
        }
      },
      total: {
        count : 0,
        total : 0,
        visibleCount : 0,
        missedLeads : {
          leads : 0,
          contactAttempt : 0,
          inProgress : 0
        },
        followUps : {
          leads : 0,
          contactAttempt : 0,
          inProgress : 0
        },
      }
    };

    if(leadsList?.length === 0){
      return;
    }

    const sortLeadBlockList = leadsList => {
      return leadsList.sort((a, b) => {
        return new Date(b.lastUpdateStatusTimeRaw) - new Date(a.lastUpdateStatusTimeRaw);
      });
    };

    const sortedLeadBlockList = sortLeadBlockList(leadsList);

    const filteredLeadBlockList = sortedLeadBlockList.filter(leadBlock => {
      const fullName = (leadBlock.leadFirstName + " " + leadBlock.leadLastName).toLowerCase();
      return fullName.includes(searchVal);
    });

    for (let leadBlock of filteredLeadBlockList){
      if(leadBlock === undefined || leadTeamFilter === "assignedOnly" && !leadBlock.assignedUsersIDs.includes(props.sessionUser.userID.toString()) && leadBlock.assignedUsersIDs.length !== 1){
        continue;
      }

      if(leadTeamFilter === "all" && ignoredTeamList.includes("0") && leadBlock.assignedUsersIDs.length === 1 ){
        continue;
      }

      const ignoredListCheck = ignoredTeamList.some(item => leadBlock.assignedUsersIDs.slice(1).includes(item));
      const isEqual = ignoredListCheck && leadBlock.assignedUsersIDs.slice(1).every(item => ignoredTeamList.includes(item));
      if(leadTeamFilter === "all" && isEqual){
        continue;
      }

      if(!listToggles.includes(leadBlock.relatedAccount)){
        continue;
      }

      if(leadBlock.currentPosition === "lead"){
        tempLeadData.lead.count++;
        tempLeadData.lead.total+= leadBlock.leadDeathBenefit;
        tempLeadData.lead.lead.count++;
      }

      if(leadBlock.currentPosition === "contactAttempted"){
        tempLeadData.contactAttempt.count++;
        tempLeadData.contactAttempt.total+= leadBlock.leadDeathBenefit;

        if(leadBlock.leadStatus === "Contact Attempted 1"){
          tempLeadData.contactAttempt.contactAttempted1.count++;
        }

        if(leadBlock.leadStatus === "Contact Attempted 2"){
          tempLeadData.contactAttempt.contactAttempted2.count++;
        }

        if(leadBlock.leadStatus === "Contact Attempted 3"){
          tempLeadData.contactAttempt.contactAttempted3.count++;
        }
      }

      if(leadBlock.currentPosition === "inProgress"){
        tempLeadData.inProgress.count++;
        tempLeadData.inProgress.total+= leadBlock.leadDeathBenefit;
        if(leadBlock.leadStatus === "Application Sent"){
          tempLeadData.inProgress.applicationSent.count++;
        }

        if(leadBlock.leadStatus === "Application Received"){
          tempLeadData.inProgress.applicationReceived.count++;
        }

        if(leadBlock.leadStatus === "Follow Up"){
          tempLeadData.inProgress.followUp.count++;
        }
      }

      if(leadBlock.currentPosition === "closed"){
        if(leadBlock.leadStatus === "Sent to Abacus"){
          tempLeadData.abacus.count++;
          tempLeadData.abacus.total+= leadBlock.leadDeathBenefit;
        }else if(leadBlock.leadStatus === "New Insurance"){
          tempLeadData.insurance.count++;
          tempLeadData.insurance.total+= leadBlock.leadDeathBenefit;
        }else{
          tempLeadData.closed.count++;
          tempLeadData.closed.total+= leadBlock.leadDeathBenefit;
        }

        if(leadBlock.leadStatus === "Unqualified"){
          tempLeadData.closed.unqualified.count++;
        }

        if(leadBlock.leadStatus === "Failed to Contact"){
          tempLeadData.closed.failedToContact.count++;
        }

        if(leadBlock.leadStatus === "Client not Interested"){
          tempLeadData.closed.clientNotInterested.count++;
        }
      }

      if(leadBlock.leadDeathBenefit >= leadsListPriceRange.minVal && leadBlock.leadDeathBenefit <= leadsListPriceRange.maxVal){
        if(!listToggles.includes(leadBlock.leadStatus)){
          if(visibleCount < listAllVisibleThreshold){
            tempViewAllList.push(
              <LeadBlock
                key={leadBlock.leadID}
                leadBlockInfo={leadBlock}
                leadBlockType="result"
                changeActivityView={changeActivityView}
                toggleLeadQuickView={toggleLeadQuickView}
                navigation={props.navigation}
                sessionUser={props.sessionUser}
              />
            );
            visibleCount++;
          }

          if(leadBlock.leadStatus === "Lead"){
            tempLeadData.lead.lead.visibleCount++;
            tempLeadData.lead.lead.total+= leadBlock.leadDeathBenefit;
            tempLeadData.lead.visibleCount++;
          }

          if(leadBlock.leadStatus === "Contact Attempted 1"){
            tempLeadData.contactAttempt.contactAttempted1.visibleCount++;
            tempLeadData.contactAttempt.contactAttempted1.total+= leadBlock.leadDeathBenefit;
            tempLeadData.contactAttempt.visibleCount++;
          }

          if(leadBlock.leadStatus === "Contact Attempted 2"){
            tempLeadData.contactAttempt.contactAttempted2.visibleCount++;
            tempLeadData.contactAttempt.contactAttempted2.total+= leadBlock.leadDeathBenefit;
            tempLeadData.contactAttempt.visibleCount++;
          }

          if(leadBlock.leadStatus === "Contact Attempted 3"){
            tempLeadData.contactAttempt.contactAttempted3.visibleCount++;
            tempLeadData.contactAttempt.contactAttempted3.total+= leadBlock.leadDeathBenefit;
            tempLeadData.contactAttempt.visibleCount++;
          }

          if(leadBlock.leadStatus === "Application Sent"){
            tempLeadData.inProgress.applicationSent.visibleCount++;
            tempLeadData.inProgress.applicationSent.total+= leadBlock.leadDeathBenefit;
            tempLeadData.inProgress.visibleCount++;
          }

          if(leadBlock.leadStatus === "Application Received"){
            tempLeadData.inProgress.applicationReceived.visibleCount++;
            tempLeadData.inProgress.applicationReceived.total+= leadBlock.leadDeathBenefit;
            tempLeadData.inProgress.visibleCount++;
          }

          if(leadBlock.leadStatus === "Follow Up"){
            tempLeadData.inProgress.followUp.visibleCount++;
            tempLeadData.inProgress.followUp.total+= leadBlock.leadDeathBenefit;
            tempLeadData.inProgress.visibleCount++;
          }

          if(leadBlock.leadStatus === "Unqualified"){
            tempLeadData.closed.unqualified.visibleCount++;
            tempLeadData.closed.unqualified.total+= leadBlock.leadDeathBenefit;
            tempLeadData.closed.visibleCount++;
          }

          if(leadBlock.leadStatus === "Failed to Contact"){
            tempLeadData.closed.failedToContact.visibleCount++;
            tempLeadData.closed.failedToContact.total+= leadBlock.leadDeathBenefit;
            tempLeadData.closed.visibleCount++;
          }

          if(leadBlock.leadStatus === "Client not Interested"){
            tempLeadData.closed.clientNotInterested.visibleCount++;
            tempLeadData.closed.clientNotInterested.total+= leadBlock.leadDeathBenefit;
            tempLeadData.closed.visibleCount++;
          }

          if(leadBlock.leadStatus === "Sent to Abacus"){
            tempLeadData.abacus.visibleCount++;
            tempLeadData.abacus.total+= leadBlock.leadDeathBenefit;
          }

          if(leadBlock.leadStatus === "New Insurance"){
            tempLeadData.insurance.visibleCount++;
            tempLeadData.insurance.total+= leadBlock.leadDeathBenefit;
          }

          tempLeadData.total.visibleCount++;
        }
      }

      tempLeadData.total.count++;
      tempLeadData.total.total+= leadBlock.leadDeathBenefit;

    }
  
    setViewAllList(tempViewAllList);
    setLeadBlocListkData({...tempLeadData});
  }

  function convertTimeStamp(timeStamp) {

    timeStamp = Date.parse(timeStamp);
    timeStamp = timeStamp/1000;

    var seconds = Math.floor(((new Date().getTime()/1000) - timeStamp)),
    interval = Math.floor(seconds / 31536000);

    if (interval > 1) return interval + " yrs";

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return interval + " mo";

    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return interval + " days";

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return interval + "h";

    interval = Math.floor(seconds / 60);
    if (interval > 1) return interval + "m ";

    return Math.floor(seconds) + "s";
  }

  function pullLeads(){
    if(!props.session?.user?.data?.opportunityTypes?.includes("leads")){
      setInitializationHandler(prevState => ({
        ...prevState,
        leads: true
      }));
      return false;
    }

    if(initializationHandler.leads === true){
      return;
    }else{
      setInitializationHandler(prevState => ({
        ...prevState,
        leads: true
      }));      
    }

    if(Object.keys(leadsList).length > 0){
      return;
    }

    props.session?.env?.functions?.buildFetchRequest("pullLeads", {"accountID" : props.session?.user?.data?.accountID})
      .then(response => response.json())
      .then(resData => {
        var tempLeadsList = [];
        var x = 0;
        let lowestPrice = 0;
        let highestPrice = 0;
        let leadCategories = [];

        if(resData.status === 200){

          Object.keys(resData?.leads).forEach(function(key){
            if(key === 0){
              lowestPrice = resData.leads[key].leadDeathBenefit;
              highestPrice = resData.leads[key].leadDeathBenefit;
            }

            if(resData.leads[key].leadDeathBenefit < lowestPrice){
              lowestPrice = resData.leads[key].leadDeathBenefit;
            }

            if(resData.leads[key].leadDeathBenefit > highestPrice){
              highestPrice = resData.leads[key].leadDeathBenefit;
            }

            if(!leadCategories.includes(resData.leads[key].relatedAccount)){
              leadCategories.push(resData.leads[key].relatedAccount);
            }

            resData.leads[key].lastUpdateStatusTimeRaw = resData.leads[key].lastStatusUpdate;
            resData.leads[key].lastStatusUpdate = convertTimeStamp(resData.leads[key].lastStatusUpdate);
            resData.leads[key].accountID = props.sessionUser.accountID;
            resData.leads[key].key = x;
            tempLeadsList.push(resData.leads[key]);
            x++;
          });

          if(resData.status === 500){
              console.log("No leads found.");
          }
        }

        var leadsListPriceRangeObj = {
          minVal : lowestPrice,
          maxVal : highestPrice,
          fixedMinVal : lowestPrice,
          fixedMaxVal : highestPrice
        }

        setKanbanToggles(prevToggles => [...prevToggles, ...leadCategories]);
        setListToggles(prevToggles => [...prevToggles, ...leadCategories]);

        setLeadsListPriceRange(leadsListPriceRangeObj);
        setLeadsList(tempLeadsList);
      });
  }

  function updateLeadsSearchVal(evt, reset){
    if(reset){
      setLeadsSearchVal('');
    }else{
      setLeadsSearchVal(evt.target.value);
    }
  }

  function changeLeadActivityView(view){
    if(leadsList?.length === 0){
      return;
    }
    setLeadActivityView(view);
  }

  function printLeadsKanbanBoard(){
    const searchVal = leadsSearchVal.toLowerCase();
    if(leadsList.length === 0){
        return;
    }

    var leadVisibleCount = 0;
    var contactAttemptedVisibleCount = 0;
    var inProgressVisibleCount = 0;
    var tempLeadsList = [];
    var tempContactAttemptList = [];
    var tempInProgressList = [];
    var tempLeadData = {
      leadCount : 0,
      leadTotal : 0,
      contactAttemptCount : 0,
      contactAttemptTotal : 0,
      inProgressCount : 0,
      inProgressTotal : 0,
      abacusCount : 0,
      insuranceCount : 0,
      closedCount : 0,
      abacusTotal : 0,
      insuranceTotal : 0,
      closedTotal : 0,
      totalCount : 0,
      totalTotal : 0,
      totalMissedLeads : 0,
      totalMissedContactAttempt : 0,
      totalMissedInProgress : 0,
      totalLeadsFollowUps : 0,
      totalContactAttemptFollowUps : 0,
      totalInProgressFollowUps : 0
    };

    const sortLeadBlockList = leadsList => {
      return leadsList.sort((a, b) => {
        return new Date(b.lastUpdateStatusTimeRaw) - new Date(a.lastUpdateStatusTimeRaw);
      });
    };

    const sortedLeadBlockList = sortLeadBlockList(leadsList);
    const filteredLeadBlockList = sortedLeadBlockList.filter(leadBlock => {
      const fullName = (leadBlock.leadFirstName + " " + leadBlock.leadLastName).toLowerCase();
      return fullName.includes(searchVal);
    });

    for (let leadBlock of filteredLeadBlockList){

      if(leadBlock === undefined){
        continue;
      }

      if(leadTeamFilter === "assignedOnly" &&
        !leadBlock.assignedUsersIDs.includes(props.sessionUser.userID.toString()) &&
        leadBlock.assignedUsersIDs.length !== 0){

        continue;
      }

      // if(leadTeamFilter === "all" && ignoredTeamList.includes("0") && leadBlock.assignedUsersIDs.length === 1 ){
      //   continue;
      // }

      if(ignoredTeamList.includes("0") && leadBlock.assignedUsersIDs.length === 0 ){
        continue;
      }

      const ignoredListCheck = ignoredTeamList.some(item => leadBlock.assignedUsersIDs.slice(1).includes(item));
      const isEqual = ignoredListCheck && leadBlock.assignedUsersIDs.slice(1).every(item => ignoredTeamList.includes(item));
      if(leadTeamFilter === "all" && isEqual){
          continue;
      }

      if(!kanbanToggles.includes(leadBlock.relatedAccount)){
        continue;
      }

      if(leadBlock.currentPosition === "lead"){

        tempLeadData.leadCount++;
        tempLeadData.leadTotal += leadBlock.leadDeathBenefit;
        tempLeadData.totalMissedLeads += leadBlock.numMissedFollowUps;

        if(Date.parse(leadBlock.nextFollowUp) > new Date().getTime()){
            tempLeadData.totalLeadsFollowUps++;
        }

        if(!detectLeadBlockNotification(leadBlock.currentPosition, leadBlock)){
            continue;
        }

        if(!kanbanToggles.includes(leadBlock.leadStatus)){
            continue;
        }

        if(leadVisibleCount < kanbanLeadVisibleThreshold){
          tempLeadsList.push(
            <LeadBlockKanban
              key={leadBlock.leadID}
              leadBlock={leadBlock}
              changeActivityView={changeActivityView}
              toggleLeadQuickView={toggleLeadQuickView}
              navigation={props.navigation}
            />
          );
          leadVisibleCount++;
        }
      }

      if(leadBlock.currentPosition === "contactAttempted"){
        tempLeadData.contactAttemptCount++;
        tempLeadData.contactAttemptTotal += leadBlock.leadDeathBenefit;
        tempLeadData.totalMissedContactAttempt += leadBlock.numMissedFollowUps;

        if(Date.parse(leadBlock.nextFollowUp) > new Date().getTime()){
          tempLeadData.totalContactAttemptFollowUps++;
        }

        if(!detectLeadBlockNotification(leadBlock.currentPosition, leadBlock)){
          continue;
        }

        if(!kanbanToggles.includes(leadBlock.leadStatus)){
          continue;
        }

        if(contactAttemptedVisibleCount < kanbanContactAttemptedVisibleThreshold){
          tempContactAttemptList.push(
            <LeadBlockKanban
              key={leadBlock.leadID}
              leadBlock={leadBlock}
              changeActivityView={changeActivityView}
              toggleLeadQuickView={toggleLeadQuickView}
              navigation={props.navigation}
            />
          );
          contactAttemptedVisibleCount++;
        }
      }

      if(leadBlock.currentPosition === "inProgress"){
        tempLeadData.inProgressCount++;
        tempLeadData.inProgressTotal += leadBlock.leadDeathBenefit;
        tempLeadData.totalMissedInProgress += leadBlock.numMissedFollowUps;

        if(Date.parse(leadBlock.nextFollowUp) > new Date().getTime()){
          tempLeadData.totalInProgressFollowUps++;
        }

        if(!detectLeadBlockNotification(leadBlock.currentPosition, leadBlock)){
          continue;
        }

        if(!kanbanToggles.includes(leadBlock.leadStatus)){
          continue;
        }

        if(inProgressVisibleCount < kanbanInProgressVisibleThreshold){
          tempInProgressList.push(
            <LeadBlockKanban
              key={leadBlock.leadID}
              leadBlock={leadBlock}
              changeActivityView={changeActivityView}
              toggleLeadQuickView={toggleLeadQuickView}
              navigation={props.navigation}
            />
          );
          inProgressVisibleCount++;
        }
      }

      if(leadBlock.currentPosition === "closed"){
        if(leadBlock.leadStatus === "Sent to Abacus"){
          tempLeadData.abacusCount++;
          tempLeadData.abacusTotal += leadBlock.leadDeathBenefit;
        }
        if(leadBlock.leadStatus === "New Insurance Interest"){
          tempLeadData.insuranceCount++;
          tempLeadData.insuranceTotal += leadBlock.leadDeathBenefit;
        }
        if(leadBlock.leadStatus === "Closed"){
          tempLeadData.closedCount++;
          tempLeadData.closedTotal += leadBlock.leadDeathBenefit;
        }
        if(leadBlock.leadStatus === "Unqualified"){
          tempLeadData.closedCount++;
          tempLeadData.closedTotal += leadBlock.leadDeathBenefit;
        }
        if(leadBlock.leadStatus === "Client not Interested"){
          tempLeadData.closedCount++;
          tempLeadData.closedTotal += leadBlock.leadDeathBenefit;
        }
        if(leadBlock.leadStatus === "Failed to Contact"){
          tempLeadData.closedCount++;
          tempLeadData.closedTotal += leadBlock.leadDeathBenefit;
        }
      }
      tempLeadData.totalCount++;
      tempLeadData.totalTotal += leadBlock.leadDeathBenefit;
    }

    const checkNotifications = (notification) => {
      if (kanbanNotificationToggles.includes(notification)) {
        setKanbanNotificationToggles(kanbanNotificationToggles.filter(item => item !== notification));
      }
    }    

    if(tempLeadData.totalLeadsFollowUps === 0){
      checkNotifications("leadActive");
    }

    if(tempLeadData.totalMissedLeads === 0){
      checkNotifications("leadMissed");
    }

    if(tempLeadData.totalContactAttemptFollowUps === 0){
      checkNotifications("contactAttemptedActive");
    }

    if(tempLeadData.totalMissedContactAttempt === 0){
      checkNotifications("contactAttemptedMissed");
    }
  
    if(tempLeadData.totalInProgressFollowUps === 0){
      checkNotifications("inProgressActive");
    }

    if(tempLeadData.totalMissedInProgress === 0){
      checkNotifications("inProgressMissed");
    }

    setCategoryLeadList(tempLeadsList);
    setCategoryContactAttemptList(tempContactAttemptList);
    setCategoryInProgress(tempInProgressList);
    setLeadBlockKanbanData({...tempLeadData});

    return;
  }

  useEffect(() => {
    if(kanbanLeads?.current){
      kanbanLeads.current.scrollTop = yScrollKanban.lead;
    }
  }, [categoryLeadList]);

  useEffect(() => {
    if(kanbanContactAttempted?.current){
      kanbanContactAttempted.current.scrollTop = yScrollKanban.contactAttempt;
    }
  }, [categoryContactAttemptList]);

  useEffect(() => {
    if(kanbanInProgress?.current){
      kanbanInProgress.current.scrollTop = yScrollKanban.inProgress;
    }
  }, [categoryInProgress]);

  useEffect(() => {
    resetYScrolls();
    resetBidYScrolls();
    setKanbanLeadVisibleThreshold(24);
    setKanbanContactAttemptedVisibleThreshold(24);
    setKanbanInProgressVisibleThreshold(24);
    setListAllVisibleThreshold(48);
  }, [
    props.sessionUser.currentPage]);

  useEffect(() => {
    resetYScrolls();
  }, [
    leadTeamFilter,
    leadsSearchVal,
    listToggles,
    kanbanToggles,
    ignoredTeamList
  ]);

  useEffect(() => {
    printLeadsKanbanBoard();
  }, [kanbanNotificationToggles, leadsList]);

function detectLeadBlockNotification(currentPosition, leadBlock){
  let showMissed = kanbanNotificationToggles.includes(currentPosition + "Missed");
  let showActive = kanbanNotificationToggles.includes(currentPosition + "Active");
  let includeBlock = false;

  if (showMissed && leadBlock.numMissedFollowUps > 0) {
      includeBlock = true;
  }

  if (showActive && Date.parse(leadBlock.nextFollowUp) >= new Date().getTime()) {
      includeBlock = true;
  }

  if (!showMissed && !showActive) {
      includeBlock = true;
  }

  return includeBlock;
}

function toggleLeadQuickView(view, currentLeadInfo){
  if(showLeadOptions){
      setShowLeadOptions(false);
  }else{
    setShowLeadOptions(view);
    setCurrentLeadInfo(currentLeadInfo);
  }
}

/* Bids */

const bidsKanbanColumnStage1 = useRef();
const bidsKanbanColumnStage2 = useRef();
const bidsKanbanColumnStage3 = useRef();
const bidsColumnHandlerBuild = {
  columnRefs:{
    stage1 : bidsKanbanColumnStage1,
    stage2 : bidsKanbanColumnStage2,
    stage3 : bidsKanbanColumnStage3
  },
  sort : {
    kanban : {
      stage1 : "default",
      stage2 : "default",
      stage3 : "default"
    },
    list : {
      all : "default"
    }
  }
}
const [bidsColumnHandler, setBidsColumnHandler] = useState(bidsColumnHandlerBuild);
const [bidsList, setBidsList] = useState();
const [bidsFilters, setBidsFilters] = useState([]);
const [bidsSearchVal, setBidsSearchVal] = useState('');
const [bidsListPriceRange, setBidsListPriceRange] = useState({});
const [bidsCategoryStage1List, setBidsCategoryStage1List] = useState([]);
const [bidsCategoryStage2List, setBidsCategoryStage2List] = useState([]);
const [bidsCategoryStage3List, setBidsCategoryStage3List] = useState([]);
const [bidBlockKanbanData, setBidBlockKanbanData] = useState();
const [bidTeamFilter, setBidTeamFilter] = useState("assignedOnly");
const [ignoredBidTeamList, setIgnoredBidTeamList] = useState([]);
const [kanbanBidToggles, setKanbanBidToggles] = useState([
  "Shipped", "Under Review", "Bidding", "Requested Info", "No Bid", "Lost with bid", "Won", "Initial Assessment", "Request LEs / Datatape", "Phase II", "Declined", "Closed Won"
]);
const [bidsKanbanColumnStage1VisibleThreshold, setBidsKanbanColumnStage1VisibleThreshold] = useState(24);
const [bidsKanbanColumnStage2VisibleThreshold, setBidsKanbanColumnStage2VisibleThreshold] = useState(24);
const [bidsKanbanColumnStage3VisibleThreshold, setBidsKanbanColumnStage3VisibleThreshold] = useState(24);
const [kanbanBidNotificationToggles, setKanbanBidNotificationToggles] = useState([]);
const [showBidOptions, setShowBidOptions] = useState(false);
const [currentBidInfo, setCurrentBidInfo] = useState({});
const [bidActivityView, setBidActivityView] = useState("kanban");
const [listAllBidsVisibleThreshold, setListAllBidsVisibleThreshold] = useState(48);
const [showBidFilters, setShowBidFilters] = useState(false);
const [bidBlockListData, setBidBlocListkData] = useState([]);
const [viewAllBidsList, setViewAllBidsList] = useState([]);
const [bidFilterPanelType, setBidFilterPanelType] = useState("toggle");
const [listBidToggles, setListBidToggles] = useState([]);
const [currentBidColumn, setCurrentBidColumn] = useState("bid");
const [yScrollBidList, setYScrollBidList] = useState(0);
const [bidsBuffer, setBidsBuffer] = useState({
  kanban : [],
  list : [],
  stage1Threshold : 24,
  stage2Threshold : 24,
  stage3Threshold : 24
});
const [yScrollBidKanban, setYScrollBidKanban] = useState({
  stage1 : 0,
  stage2 : 0,
  stage3 : 0
});

const bidProps = {
  bidsKanbanColumnStage1,
  bidsKanbanColumnStage2,
  bidsKanbanColumnStage3,
  bidsList,
  setBidsList,
  bidsFilters,
  setBidsFilters,
  bidsSearchVal,
  setBidsSearchVal,
  bidsListPriceRange,
  setBidsListPriceRange,
  bidsCategoryStage1List,
  setBidsCategoryStage1List,
  bidsCategoryStage2List,
  setBidsCategoryStage2List,
  bidsCategoryStage3List,
  setBidsCategoryStage3List,
  bidBlockKanbanData,
  setBidBlockKanbanData,
  bidTeamFilter,
  setBidTeamFilter,
  ignoredBidTeamList,
  setIgnoredBidTeamList,
  kanbanBidToggles,
  setKanbanBidToggles,
  bidsKanbanColumnStage1VisibleThreshold,
  setBidsKanbanColumnStage1VisibleThreshold,
  bidsKanbanColumnStage2VisibleThreshold,
  setBidsKanbanColumnStage2VisibleThreshold,
  bidsKanbanColumnStage3VisibleThreshold,
  setBidsKanbanColumnStage3VisibleThreshold,
  kanbanBidNotificationToggles,
  setKanbanBidNotificationToggles,
  showBidOptions,
  setShowBidOptions,
  currentBidInfo,
  setCurrentBidInfo,
  activityView : bidActivityView,
  setActivityView : setBidActivityView,
  listAllBidsVisibleThreshold,
  setListAllBidsVisibleThreshold,
  showBidFilters,
  setShowBidFilters,
  bidBlockListData,
  setBidBlocListkData,
  viewAllBidsList,
  setViewAllBidsList,
  bidFilterPanelType,
  setBidFilterPanelType,
  listBidToggles,
  setListBidToggles,
  currentBidColumn,
  setCurrentBidColumn,
  yScrollBidList,
  setYScrollBidList,
  yScrollBidKanban,
  setYScrollBidKanban,
  handleClose : handleBidsClose,
  printBidsListView : printBidsListView,
  bidsCategoryToggle : bidsCategoryToggle,
  toggleLeadFilters : toggleLeadFilters,
  currentColumnCheck : currentColumnCheck,
  updateLeadsListPriceRange : updateLeadsListPriceRange,
  resetYScrolls : resetBidYScrolls,
  updateLeadsSearchVal : updateLeadsSearchVal,
  toggleLeadQuickView : toggleLeadQuickView,
  printBidsKanbanBoard : printBidsKanbanBoard,
  changeActivityView : changeBidActivityView,
  setLeadID : setLeadID,
  updateBidSearchVal : updateBidSearchVal,
  columnHandler : bidsColumnHandler,
  setColumnHandler : setBidsColumnHandler
}

function updateBidSearchVal(evt, reset){
  if(reset){
    setBidsSearchVal('');
  }else{
    setBidsSearchVal(evt.target.value);
  }
}

function resetBidYScrolls(direct) {
  if (direct === "stage1") {
    setBidsKanbanColumnStage1VisibleThreshold(24);
    setYScrollBidKanban(prevState => ({ ...prevState, stage1: 0 }));
  } else if (direct === "stage2") {
    setBidsKanbanColumnStage2VisibleThreshold(24);
    setYScrollBidKanban(prevState => ({ ...prevState, stage2: 0 }));
  } else if (direct === "stage3") {
    setBidsKanbanColumnStage3VisibleThreshold(24);
    setYScrollBidKanban(prevState => ({ ...prevState, stage3: 0 }));
  } else if (direct === "kanban") {
    setYScrollBidKanban({ stage1: 0, stage2: 0, stage3: 0 });
    printBidsKanbanBoard();
  } else if (direct === "list") {
    setListAllBidsVisibleThreshold(48);
    setYScrollBidList(0);
    printBidsListView();
    return;
  } else {
    setYScrollBidKanban({ stage1: 0, stage2: 0, stage3: 0 });
    setBidsKanbanColumnStage1VisibleThreshold(24);
    setBidsKanbanColumnStage2VisibleThreshold(24);
    setBidsKanbanColumnStage3VisibleThreshold(24);
    setListAllBidsVisibleThreshold(48);
    setYScrollBidList(0);
    printBidsListView();
    printBidsKanbanBoard();
  }
}

function changeBidActivityView(view){
  if(bidsList?.length === 0){
    return;
  }
  setBidActivityView(view);
}

function bidsCategoryToggle(category, all, filterType) {
  let tempListToggles = filterType === "list" ? listBidToggles : kanbanBidToggles;

  if (all === "only") {
    const allListToggles = ["Shipped", "Under Review", "Bidding", "Requested Info", "No Bid", "Lost with bid", "In Conflict", "Won", "Closing", "Does Not Fit Parameters"];
    const missingElements = allListToggles.filter(elem => !tempListToggles.includes(elem));
    tempListToggles = tempListToggles.concat(missingElements);
  }

  if (all) {
    const categoryActions = {
      "NEW": ["Shipped", "Initial Assessment"],
      "UNDER REVIEW": ["Under Review", "Phase II"],
      "BIDDING": ["Bidding", "Requested Info"],
      "WON": ["Won", "Closed Won"],
      "CONTRACTS": "Closing",
      "CLOSED": ["No Bid", "Lost with bid", "In Conflict", "Does Not Fit Parameters", "Declined"],
      "SUCCESS": ["Won", "Closed Won", "Closing"],
      "clear": [
        "Shipped",
        "Initial Assessment",
        "Under Review",
        "Phase II",
        "Bidding",
        "Requested Info",
        "No Bid",
        "Lost with bid",
        "In Conflict",
        "Won",
        "Closed Won",
        "Closing",
        "Does Not Fit Parameters"
      ]
    };

    if (categoryActions.hasOwnProperty(category)) {
      const toggleAction = categoryActions[category];
      if (Array.isArray(toggleAction)) {
        if (toggleAction.some(toggleIndex => tempListToggles.includes(toggleIndex))) {
          tempListToggles = clearCategory(tempListToggles, toggleAction);
        } else {
          tempListToggles = tempListToggles.concat(toggleAction);
        }
      } else {
        if (tempListToggles.includes(toggleAction)) {
          tempListToggles = clearCategory(tempListToggles, [toggleAction]);
        } else {
          tempListToggles.push(toggleAction);
        }
      }
    }
  } else {
    if (tempListToggles.includes(category)) {
      tempListToggles = tempListToggles.filter(toggleIndex => toggleIndex !== category);
    } else {
      tempListToggles.push(category);
    }
  }

  if (filterType === "list") {
    setListBidToggles(tempListToggles);
    printBidsListView();
  }

  if (filterType === "kanban") {
    setKanbanBidToggles(tempListToggles);
    printBidsKanbanBoard();
  }
}

function detectBidBlockNotification(currentPosition, bidBlock){
  let showRequest = kanbanBidNotificationToggles.includes(currentPosition + "TotalUnreadRequests");
  let showBid = kanbanBidNotificationToggles.includes(currentPosition + "TotalUnreadBidding");
  let includeBlock = false;

  if (showRequest && bidBlock.numOfUnreadRequestResponses > 0) {
    includeBlock = true;
  }

  if (showBid && bidBlock.numOfUnreadBidResponses > 0) {
    includeBlock = true;
  }

  if (!showRequest && !showBid) {
    includeBlock = true;
  }

  return includeBlock;
}

function pullShippedPolicies(){

  if(!props?.session?.user?.data?.opportunityTypes?.includes("bids")){
    setInitializationHandler(prevState => ({
      ...prevState,
      bids: true
    }));

    return false;
  }

  if(initializationHandler.bids === true || initializationHandler.bids === "pending"){
    return;
  }else{
    setInitializationHandler(prevState => ({
      ...prevState,
      bids: "pending"
    }));      
  }

  props.session?.env?.functions?.buildFetchRequest("marketplace/getShippedPolicies")
    .then(response => response.json())
    .then(resData => {
      const filters = {
        // status : "binary",
        leadSource : "binary",
        deathBenefit : "range",
        lifeExpectancy : "range",
        age : "range",
        affiliate : "binary",
        carrier : "binary"
      }

      const stageHandler = {
        NEW: "stage1",
        "UNDER REVIEW": "stage2",
        BIDDING: "stage3"
      };

      const filterFunctions = props.sessionVars.envVars.filterHandler.functions;
      const filterTracker = filterFunctions.buildFilterTracker(filters, "kanban");

      setInitializationHandler(prevState => ({
        ...prevState,
        bids: true
      }));

      if (resData.status === 200) {
        let tempBidsList = [];
        const rangeKeys = {
          lifeExpectancy: "averageLEMonths",
          age: "age",
          deathBenefit: "deathBenefit",
        };

        if (resData.status === 200) {
          const localStorage = JSON.parse(window.localStorage.getItem('storage'));
          const existingBidFilters = localStorage?.filters?.bids;

          Object.keys(resData?.tasks).forEach((key) => {
            const currentCase = resData.tasks[key];
            const birthdate = new Date(currentCase?.primaryBirthDate);
            const ageInMs = Date.now() - birthdate.getTime();
            const ageInYears = ~~(ageInMs / (31557600000));
            currentCase.age = ageInYears;

            const branchTypes = ["kanban", "list"];
            branchTypes.forEach((type) => {

            let activeCaseHandler = {
              kanban : {
                affiliate : false,
                leadSource : false,
                carrier : false
              },
              list : {
                affiliate : false,
                leadSource : false,
                carrier : false
              }
            }

              Object.entries(rangeKeys).forEach(([rangeKey, childProp]) => {
                const currentData = filterTracker[type][rangeKey];
                filterFunctions.updateRangeData(currentData, currentCase[childProp]);
              });

              if (!filterTracker[type]?.affiliate?.hasOwnProperty(currentCase?.relatedAccountID)){
                activeCaseHandler[type].affiliate = existingBidFilters?.[type]?.affiliate?.[currentCase?.relatedAccountID]?.active ?? true;

                filterTracker[type].affiliate[currentCase?.relatedAccountID] = {
                  count: 0,
                  active: existingBidFilters?.[type]?.affiliate?.[currentCase?.relatedAccountID]?.active ?? true,
                  type: "affiliate",
                  accountType: "fund",
                  value: props.sessionVars?.envVars?.adminHandler[currentCase?.relatedAccountID]?.name,
                  fundID: currentCase?.fundID,
                  funds: {},
                };
              }else{
                activeCaseHandler[type].affiliate = existingBidFilters?.[type]?.affiliate?.[currentCase?.relatedAccountID]?.active ?? true;
              }

              if (!filterTracker[type].carrier?.hasOwnProperty(currentCase?.insuranceCompany)){
                activeCaseHandler[type].carrier = existingBidFilters?.[type]?.carrier?.[currentCase?.insuranceCompany]?.active ?? true;

                filterTracker[type].carrier[currentCase?.insuranceCompany] = {
                  count: 1,
                  active: existingBidFilters?.[type]?.carrier?.[currentCase?.insuranceCompany]?.active ?? true,
                  value: currentCase?.insuranceCompany
                };
              }else{
                filterTracker[type].carrier[currentCase?.insuranceCompany].count++;
                activeCaseHandler[type].carrier = existingBidFilters?.[type]?.carrier?.[currentCase?.insuranceCompany]?.active ?? true;
              }

              if (!filterTracker[type].leadSource?.hasOwnProperty(currentCase?.leadSource)){
                activeCaseHandler[type].leadSource = existingBidFilters?.[type]?.leadSource?.[currentCase?.leadSource]?.active ?? true;

                filterTracker[type].leadSource[currentCase?.leadSource] = {
                  count: 1,
                  active: existingBidFilters?.[type]?.leadSource?.[currentCase?.leadSource]?.active ?? true,
                  value: currentCase?.leadSource
                };
              }else{
                filterTracker[type].leadSource[currentCase?.leadSource].count++;
                activeCaseHandler[type].leadSource = existingBidFilters?.[type]?.leadSource?.[currentCase?.leadSource]?.active ?? true;
              }

              const affiliate = filterTracker[type].affiliate[currentCase.relatedAccountID];
              const funds = Object.values(props.sessionVars?.envVars?.adminHandler[currentCase?.relatedAccountID]?.funds || {});
              const matchedFund = funds.find(fund => fund.lsFundID === currentCase?.fundID);
              const value = matchedFund?.ownerName ?? '';

              affiliate.count++;
              affiliate.funds[currentCase?.fundID] = {
                count: (affiliate.funds[currentCase?.fundID]?.count || 0) + 1,
                active: existingBidFilters?.[type]?.affiliate[currentCase.relatedAccountID]?.funds[currentCase?.fundID]?.active ?? true,
                type: "affiliate",
                accountType: "fund",
                value: value,
                fundID: currentCase?.fundID,
              };

              if (stageHandler.hasOwnProperty(currentCase?.stage)) {
                const maxCountKey = stageHandler[currentCase?.stage] + "MaxCount";
                if (!filterTracker[type].hasOwnProperty(maxCountKey)) {
                  filterTracker[type][maxCountKey] = 1;
                } else {
                  filterTracker[type][maxCountKey]++;
                }
              }

              Object.keys(filters).forEach((key) => {
                if(filters[key] === "binary"){
                  if(activeCaseHandler[type]?.[key]){
                    filterTracker[type][key].activeCount++;
                  }
                  filterTracker[type][key].maxCount++;
                }                
              });
            });

            // props?.session?.set("bids", "bids", currentCase);
            // props?.session?.set("case", "bids", currentCase);
            // console.log(currentCase);
            tempBidsList.push(currentCase);
            // props?.session?.case?.functions?.updateAttributeData(currentCase, "bids");
          });

          if (resData.status === 500) {
            console.log("No leads found.");
            setBidsList(tempBidsList);
          }
        }

      filterTracker.kanban.carrier = filterFunctions.initializeOrder(filterTracker.kanban.carrier, "value");
      filterTracker.kanban.affiliate = filterFunctions.initializeOrder(filterTracker.kanban.affiliate, "value");
      filterTracker.kanban.leadSource = filterFunctions.initializeOrder(filterTracker.kanban.leadSource, "value");

      filterFunctions.initializeFilterBranch("bids", filterTracker);

      // props?.session?.set("case", "bids.bids", tempBidsList);
      setBidsList(tempBidsList);
    }
  });
}

function handleBidsClose(){
  alert("Close!");
}

function printBidsKanbanBoard(){

  const searchVal = bidsSearchVal?.toLowerCase();

  if(initializationHandler.bids !== true){
    return;
  }

  if(bidsList?.length === 0){
    setBidBlockKanbanData({});
    return;
  }

  let visibleStageHandler = {
    stage1 : 0,
    stage2 : 0,
    stage3 : 0
  }

  let iteratingList = {};

  let blockList = {
    stage1 : [],
    stage2 : [],
    stage3 : []
  }

  var tempBidData = {
    totalCount : 0,
    totalTotal : 0,
    completedCount : 0,
    completedTotal : 0,
    completedTotalBids : 0,
    completedTotalUnreadRequests : 0,
    completedTotalOpenRequests : 0,
    completedTotalUnreadBidding : 0
  };

  const stageHandler = {
    "NEW": "stage1",
    "UNDER REVIEW": "stage2",
    "BIDDING": "stage3",
    "CLOSING": "closing",
    "CLOSED": "closed"
  };

  const subStageHandler = {
    "No Bid": "noBid",
    "Lost with bid": "lostWithBid",
    "In Conflict": "inConflict",
    "Does Not Fit Parameters": "doesNotFitParameters",
    "Unqualified Closed": "unqualified",
    "Declined" : "noBid"
  };

  const notificationHandler = {
    notifications: ["notification1", "notification2"],
    stages: ["stage1", "stage2", "stage3"]
  };

  const columnHandler = {
    stage1 : bidsKanbanColumnStage1VisibleThreshold,
    stage2 : bidsKanbanColumnStage2VisibleThreshold,
    stage3 : bidsKanbanColumnStage3VisibleThreshold
  }

  function buildAttributes(tempBidData, currentStage, blockInfo) {
    let blockStage = currentStage;
    let subBlockStage;
    let attributes = [
      "Count",
      "Total",
      "TotalBids",
      "TotalUnreadBidding",
      "TotalUnreadRequests",
      "TotalOpenRequests",
      "Visible"
    ];

    // let closedCategories;

    if(blockStage === "closed"){
      if(blockInfo?.shippingStatus === "Won" || blockInfo?.shippingStatus === "Closed Won"){
        blockStage = "won";
      }else{
        subBlockStage = subStageHandler[blockInfo?.shippingStatus];
      }
    }

    for (const attr of attributes) {
      if (!tempBidData.hasOwnProperty(blockStage + attr)) {
        tempBidData[blockStage + attr] = 0;
      }

      if(subBlockStage && !tempBidData.hasOwnProperty(subBlockStage + attr)) {
          tempBidData[subBlockStage + attr] = 0;
      }
    }

    tempBidData[blockStage + "Count"]++;
    tempBidData[blockStage + "Total"] += blockInfo.deathBenefit;
    tempBidData[blockStage + "TotalBids"] += blockInfo.grossBid;
    tempBidData[blockStage + "TotalUnreadBidding"] += blockInfo.numOfUnreadBidResponses ?? 0;
    tempBidData[blockStage + "TotalUnreadRequests"] += blockInfo.numOfUnreadRequestResponses ?? 0;

    tempBidData[blockStage + "TotalOpenRequests"] += blockInfo.numOfOpenRequests;

    if(subBlockStage){
      tempBidData[subBlockStage + "Count"]++;
      tempBidData[subBlockStage + "Total"] += blockInfo.deathBenefit;
      tempBidData[subBlockStage + "TotalBids"] += blockInfo.grossBid;
      tempBidData[subBlockStage + "TotalUnreadBidding"] += blockInfo.numOfUnreadBidResponses ?? 0;
      tempBidData[subBlockStage + "TotalUnreadRequests"] += blockInfo.numOfUnreadRequestResponses ?? 0;
      tempBidData[subBlockStage + "TotalOpenRequests"] += blockInfo.numOfOpenRequests;
    }

    if(blockInfo?.shippingStatus === "Closing" || blockInfo?.shippingStatus === "Won"){
      tempBidData.completedCount++;
      tempBidData.completedTotal += blockInfo.deathBenefit;
      tempBidData.completedTotalBids += blockInfo.grossBid;
      tempBidData.completedTotalUnreadBidding += blockInfo.numOfUnreadBidResponses ?? 0;
      tempBidData.completedTotalUnreadRequests += blockInfo.numOfUnreadRequestResponses ?? 0;
      tempBidData.completedTotalOpenRequests += blockInfo.numOfOpenRequests;
    }

    return tempBidData;
  }

  function checkEmptyInclusion(inclusion, value){
    if(!inclusion && !(value > 0 )){
      return false;
    }

    return true;
  }

  const filteredBidBlockList = bidsList?.filter(bidBlock => {
    const isEmployee = props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee;
    const filterBranch = props?.sessionVars?.envVars?.filterHandler?.bids;
    const filterBranchHandler = filterBranch.kanban;
  
    const ignoreAffiliate = filterBranchHandler?.affiliate?.[bidBlock?.relatedAccountID]?.active;
  
    const includesFullName = bidBlock.primaryFullName?.toLowerCase().includes(searchVal);
    const includesPolicyNumber = bidBlock.policyNumber?.toLowerCase().includes(searchVal);
    const includesClarinetRecordID = bidBlock.clarinetRecordID?.toLowerCase().includes(searchVal);
  
    const searchStatus = !searchVal || (includesFullName || includesPolicyNumber || (isEmployee && includesClarinetRecordID));
  
    const viewType = filterBranch?.viewType;
    const includeUnassigned = filterBranch?.includeUnassigned;
    const includeOpenRequests = filterBranch?.includeOpenRequests;
  
    const assignedStatus = viewType === "assigned";
    const assignedToCurrentUser = bidBlock?.assignedUsersIDs.includes(props?.sessionUser?.userID.toString());
    const noAssignedUsers = bidBlock?.assignedUsersIDs.length === 0;

    const openRequestsStatus =
    (!includeOpenRequests && bidBlock?.numOfOpenRequests > 0 ? false : true)
    || bidBlock?.numOfUnreadRequestResponses > 0
    || bidBlock?.numOfUnreadBidResponses > 0;

    const shouldPassAssignedStatus = assignedStatus && assignedToCurrentUser;
    const shouldPassIncludeUnassigned = assignedStatus && includeUnassigned && noAssignedUsers;
  
    const shouldPassAssignedConditions = shouldPassAssignedStatus || shouldPassIncludeUnassigned;
  
    const assignedStatusCondition = assignedStatus ? shouldPassAssignedConditions : true;
  
    const notification1Status = filterBranch?.notification1?.[stageHandler[bidBlock?.stage]];
    const notification2Status = filterBranch?.notification2?.[stageHandler[bidBlock?.stage]];
    const unreadRequestResponses = bidBlock?.numOfUnreadRequestResponses;
    const unreadBidResponses = bidBlock?.numOfUnreadBidResponses;
  
    const includesAssignedID = bidBlock?.assignedUsersIDs.includes(props?.sessionUser?.userID.toString());
    const existingAssigned = bidBlock?.assignedUsersIDs.length > 0;
    const includeUnassignedCondition = includeUnassigned || (assignedStatus && includesAssignedID);

    const lifeExpectancyEmpty = checkEmptyInclusion(filterBranchHandler?.lifeExpectancy?.empty?.active, bidBlock?.averageLEMonths);
    const ageEmpty = checkEmptyInclusion(filterBranchHandler?.age?.empty?.active, bidBlock?.age);
    const deathBenefitEmpty = checkEmptyInclusion(filterBranchHandler?.deathBenefit?.empty?.active, bidBlock?.deathBenefit);

    const allConditions = [
      props.sessionVars?.envVars?.adminHandler?.[bidBlock?.relatedAccountID]?.activeHandler?.bids,
      searchStatus,
      assignedStatusCondition,
      ignoreAffiliate,
      filterBranchHandler?.carrier?.[bidBlock?.insuranceCompany]?.active,
      filterBranchHandler?.leadSource?.[bidBlock?.leadSource]?.active,
      lifeExpectancyEmpty,
      (bidBlock?.averageLEMonths ? bidBlock?.averageLEMonths >= filterBranchHandler?.lifeExpectancy?.minVal && bidBlock?.averageLEMonths <= filterBranchHandler?.lifeExpectancy?.maxVal : true),
      ageEmpty,
      (bidBlock?.age ? bidBlock?.age >= filterBranchHandler?.age?.minVal && bidBlock?.age <= filterBranchHandler?.age?.maxVal : true),
      deathBenefitEmpty,
      (bidBlock?.deathBenefit ? bidBlock?.deathBenefit >= filterBranchHandler?.deathBenefit?.minVal && bidBlock?.deathBenefit <= filterBranchHandler?.deathBenefit?.maxVal : true),
      includeUnassignedCondition || existingAssigned,
      (notification1Status && unreadRequestResponses > 0) || !notification1Status,
      (notification2Status && unreadBidResponses > 0) || !notification2Status,
      openRequestsStatus
    ];

    return allConditions.every(condition => condition);
  });
  
  for (let bidBlock of filteredBidBlockList){
    let currentStage = stageHandler[bidBlock.stage];

    if (!iteratingList.hasOwnProperty(currentStage)) {
      iteratingList[currentStage] = []
    }

    iteratingList[currentStage].push(bidBlock);
  }

  const sortBidBlockList = (columnList, columnKey, sortConfig) => {
    return columnList?.sort((a, b) => {
      const direction = sortConfig[columnKey] === "reverse" ? -1 : 1;
      return direction * (new Date(a.lastUpdate) - new Date(b.lastUpdate));
    });
  };

  Object.keys(iteratingList).forEach(stage => {
    let columnList = iteratingList[stage];
    columnList = sortBidBlockList(columnList, stage, bidProps?.columnHandler?.sort?.kanban);
  });

  if(filteredBidBlockList){

    setBidsBuffer(prev => ({
      ...prev,
      kanban: iteratingList,
      stage1Threshold: bidsKanbanColumnStage1VisibleThreshold,
      stage2Threshold: bidsKanbanColumnStage2VisibleThreshold,
      stage3Threshold: bidsKanbanColumnStage3VisibleThreshold
    }));

    Object.keys(iteratingList).forEach(stage => {
      for (let bidBlock of iteratingList[stage]){

        tempBidData = buildAttributes(tempBidData, stage, bidBlock);
        if(visibleStageHandler[stage] < columnHandler[stage]){

          if(!detectBidBlockNotification(stage, bidBlock)){
            continue;
          }

          if(!kanbanBidToggles.includes(bidBlock.shippingStatus)){
            continue;
          }

          blockList[stage].push(
            <KanbanBlock
              key={bidBlock.recordID}
              kanbanBlockInfo={bidBlock}
              changeActivityView={changeActivityView}
              toggleLeadQuickView={toggleLeadQuickView}
              navigation={props.navigation}
              type="bid"
              sessionUser={props.sessionUser}
              session={props?.session}
            />
          );

          visibleStageHandler[stage]++;
        }
      }
    });

    tempBidData.stage1Visible = visibleStageHandler.stage1;
    tempBidData.stage2Visible = visibleStageHandler.stage2;
    tempBidData.stage3Visible = visibleStageHandler.stage3;
  }

  const notificationPath = props?.sessionVars?.envVars?.filterHandler?.bids;
  notificationHandler.notifications.forEach(notification => {
    notificationHandler.stages.forEach(stage => {
      if(tempBidData[stage + "Visible"] === 0 && notificationPath?.[notification]?.[stage] === true){
        props?.sessionVars?.envVars?.filterHandler?.functions?.updateFilterAttr(false, "bids", notification, stage);
      }
    });
  });

  setBidsCategoryStage1List(blockList?.stage1);
  setBidsCategoryStage2List(blockList?.stage2);
  setBidsCategoryStage3List(blockList?.stage3);
  setBidBlockKanbanData({...tempBidData});
  return;
}

function printBidsListView(){
  const searchVal = bidsSearchVal?.toLowerCase();
  var visibleCount = 0;
  var tempViewAllBidsList = [];
  var categoryTemp = {
    count : 0,
    total : 0,
    visibleCount :0
  }
  var tempBidData = {
    newCase: {
      count : 0,
      total : 0,
      visibleCount : 0,
      newCase: categoryTemp
    },
    underReview:{
      count : 0,
      total : 0,
      visibleCount : 0,
      underReview : categoryTemp,
      requestedInfo : categoryTemp
    },
    bidding: {
      count : 0,
      total : 0,
      visibleCount : 0,
      bidding : categoryTemp,
      requestedInfo : categoryTemp
    },
    success: {
      count : 0,
      total : 0,
      visibleCount : 0,
      closedWon : categoryTemp,
      closing : categoryTemp,
    },
    closed: {
      count : 0,
      total : 0,
      visibleCount : 0,
      noBid: categoryTemp,
      inConflict: categoryTemp,
      doesNotFitParameters: categoryTemp,
      lostWithBid: categoryTemp
    },
    total: {
      count : 0,
      total : 0,
      visibleCount : 0,
      missedLeads : {
        leads : 0,
        contactAttempt : 0,
        inProgress : 0
      },
      followUps : {
        leads : 0,
        contactAttempt : 0,
        inProgress : 0
      },
    }
  };

  if(bidsList?.length === 0){
    return;
  }

  const sortBidsBlockList = bidsList => {
    return bidsList?.sort((a, b) => {
      return new Date(a.lastUpdate) - new Date(b.lastUpdate);
    });
  };

  //console.log(bidsList);
  const sortedBidBlockList = sortBidsBlockList(bidsList);

  const filteredBidBlockList = sortedBidBlockList?.filter(bidBlock => {
    const isEmployee = props?.sessionUser?.accountData[props?.sessionUser?.accountID]?.teamList?.find((teamMember) => teamMember.userID === props?.sessionUser?.userID)?.isAbacusEmployee;
    const includesFullName = bidBlock.primaryFullName?.toLowerCase().includes(searchVal);
    const includesPolicyNumber = bidBlock.policyNumber?.toLowerCase().includes(searchVal);
    const includesClarinetRecordID = bidBlock.clarinetRecordID?.toLowerCase().includes(searchVal);
  
    const searchStatus = !searchVal || (includesFullName || includesPolicyNumber || (isEmployee && includesClarinetRecordID));
    return searchStatus;
  });

  if (filteredBidBlockList){
    for (let bidBlock of filteredBidBlockList){

      if(bidBlock.stage === "NEW"){
        tempBidData.newCase.count++;
        tempBidData.newCase.total+= bidBlock.deathBenefit;
        tempBidData.newCase.newCase.count++;
      }

      if(bidBlock.stage === "UNDER REVIEW"){
        tempBidData.underReview.count++;
        tempBidData.underReview.total+= bidBlock.deathBenefit;

        if(bidBlock.shippingStatus === "Under Review"){
          tempBidData.underReview.underReview.count++;
        }

        if(bidBlock.shippingStatus === "Requested Info"){
          tempBidData.underReview.requestedInfo.count++;
        }
      }

      if(bidBlock.stage === "BIDDING"){
        tempBidData.bidding.count++;
        tempBidData.bidding.total+= bidBlock.deathBenefit;

        if(bidBlock.shippingStatus === "Bidding"){
          tempBidData.bidding.bidding.count++;
        }

        if(bidBlock.shippingStatus === "Requested Info"){
          tempBidData.bidding.requestedInfo.count++;
        }
      }

      if(bidBlock.stage === "CLOSED" || bidBlock.stage === "CLOSING"){
        if(bidBlock.shippingStatus === "Won"){
          tempBidData.success.count++;
          tempBidData.success.total+= bidBlock.deathBenefit;
          tempBidData.success.closedWon.count++;
        }

        if(bidBlock.shippingStatus === "Closing"){
          tempBidData.success.count++;
          tempBidData.success.total+= bidBlock.deathBenefit;
          tempBidData.success.closing.count++;
        }
    
        if(bidBlock.shippingStatus === "No Bid"){
          tempBidData.closed.noBid.count++;
          tempBidData.closed.count++;
        }

        if(bidBlock.shippingStatus === "In Conflict"){
          tempBidData.closed.inConflict.count++;
          tempBidData.closed.count++;
        }

        if(bidBlock.shippingStatus === "Does Not Fit Parameters"){
          tempBidData.closed.doesNotFitParameters.count++;
          tempBidData.closed.count++;
        }

        if(bidBlock.shippingStatus === "Lost with bid"){
          tempBidData.closed.lostWithBid.count++;
          tempBidData.closed.count++;
        }
      }

      //if(bidBlock.deathBenefit >= bidsListPriceRange.minVal && bidBlock.deathBenefit <= bidsListPriceRange.maxVal){
        if(!listBidToggles.includes(bidBlock.shippingStatus)){
          let resultClass;
          if(bidBlock.shippingStatus === "Shipped"){
            resultClass = "newCase";
            tempBidData.newCase.newCase.visibleCount++;
            tempBidData.newCase.newCase.total+= bidBlock.deathBenefit;
            tempBidData.newCase.visibleCount++;
          }

          if(bidBlock.shippingStatus === "Under Review"){
            resultClass = "underReview";
            tempBidData.underReview.underReview.visibleCount++;
            tempBidData.underReview.underReview.total+= bidBlock.deathBenefit;
            tempBidData.underReview.visibleCount++;
          }

          if(bidBlock.shippingStatus === "Bidding"){
            resultClass = "bidding";
            tempBidData.bidding.bidding.visibleCount++;
            tempBidData.bidding.bidding.total+= bidBlock.deathBenefit;
            tempBidData.bidding.visibleCount++;
          }

          if(bidBlock.shippingStatus === "Requested Info"){
            if(bidBlock.stage === "UNDER REVIEW"){
              resultClass = "underReview";
              tempBidData.underReview.requestedInfo.visibleCount++;
              tempBidData.underReview.requestedInfo.total+= bidBlock.deathBenefit;
              tempBidData.underReview.visibleCount++;
            }

            if(bidBlock.stage === "BIDDING"){
              resultClass = "bidding";
              tempBidData.bidding.requestedInfo.visibleCount++;
              tempBidData.bidding.requestedInfo.total+= bidBlock.deathBenefit;
              tempBidData.bidding.visibleCount++;
            }
          }

          if(bidBlock.shippingStatus === "Won"){
            resultClass = "closedWon";
            tempBidData.success.closedWon.visibleCount++;
            tempBidData.success.closedWon.total+= bidBlock.deathBenefit;
            tempBidData.success.visibleCount++;
          }

          if(bidBlock.shippingStatus === "Closing"){
            resultClass = "closedInContracts";
            tempBidData.success.closing.visibleCount++;
            tempBidData.success.closing.total+= bidBlock.deathBenefit;
            tempBidData.success.visibleCount++;
          }

          if(bidBlock.shippingStatus === "Closed Won"){
            resultClass = "closedWon";
            tempBidData.success.closedWon.visibleCount++;
            tempBidData.success.closedWon.total+= bidBlock.deathBenefit;
            tempBidData.success.visibleCount++;
          }

          if(bidBlock.shippingStatus === "No Bid"){
            resultClass = "closed";
            tempBidData.closed.noBid.visibleCount++;
            tempBidData.closed.noBid.total+= bidBlock.deathBenefit;
            tempBidData.closed.visibleCount++;
          }

          if(bidBlock.shippingStatus === "In Conflict"){
            resultClass = "closed";
            tempBidData.closed.inConflict.visibleCount++;
            tempBidData.closed.inConflict.total+= bidBlock.deathBenefit;
            tempBidData.closed.visibleCount++;
          }

          if(bidBlock.shippingStatus === "Does Not Fit Parameters"){
            resultClass = "closed";
            tempBidData.closed.doesNotFitParameters.visibleCount++;
            tempBidData.closed.doesNotFitParameters.total+= bidBlock.deathBenefit;
            tempBidData.closed.visibleCount++;
          }

          if(bidBlock.shippingStatus === "Lost with bid"){
            resultClass = "closed";
            tempBidData.closed.lostWithBid.visibleCount++;
            tempBidData.closed.lostWithBid.total+= bidBlock.deathBenefit;
            tempBidData.closed.visibleCount++;
          }

          if(visibleCount < listAllBidsVisibleThreshold){

            tempViewAllBidsList.push(
              props?.session?.bids?.data?.list?.listType === "list" ?
              <KanbanRow
                  key={bidBlock.recordID}
                  kanbanRowInfo={bidBlock}
                  kanbanRowType="result"
                  bidBlockClass={resultClass}
                  changeActivityView={changeActivityView}
                  toggleLeadQuickView={toggleLeadQuickView}
                  navigation={props.navigation}
                  type="bid"
                  sessionUser={props?.sessionUser}
                  session={props?.session}
                />
              :
                <KanbanBlock
                  key={bidBlock.recordID}
                  kanbanBlockInfo={bidBlock}
                  kanbanBlockType="result"
                  bidBlockClass={resultClass}
                  changeActivityView={changeActivityView}
                  toggleLeadQuickView={toggleLeadQuickView}
                  navigation={props.navigation}
                  type="bid"
                  sessionUser={props?.sessionUser}
                  session={props?.session}
                />
              );
            visibleCount++;
          }

          tempBidData.total.visibleCount++;
        // }
      }

      tempBidData.total.count++;
      tempBidData.total.total+= bidBlock.deathBenefit;

    }
  }

  setViewAllBidsList(tempViewAllBidsList);
  setBidBlocListkData({...tempBidData});
}

useEffect(() => {
  printBidsListView();
  resetBidYScrolls("list");
}, [listBidToggles, bidsSearchVal, props?.session?.bids?.data?.list?.listType]);

useEffect(() => {
  if(props?.session?.navigation?.data?.currentInstance?.view === "bids"){
    printBidsKanbanBoard();
    resetBidYScrolls("kanban");
  }
}, [bidsSearchVal, bidsColumnHandler, JSON.stringify(props?.session?.storage?.filters?.bidFilters), filterHandler]);

useEffect(() => {
  printBidsKanbanBoard();
}, [bidProps?.bidsList]);

/* Profile */
  const profileProps = {
    leadsCategoryToggle,
    kanbanToggles,
    setKanbanToggles,
    listToggles,
    setListToggles,
    sessionVars : props.sessionVars
  }

  //ENV
  useEffect(() => {
    if(props.sessionInfo !== null && props.sessionInfo.darkMode === "true"){
      setAppView("darkMode");
      document.body.style.backgroundColor = "#212121";
    }else{
      setAppView("default");
      document.body.style.backgroundColor = "white";
    }

    if (props.mobileDevice) {
      document.body.classList.add("mobile");
    } else {
      document.body.classList.remove("mobile");
    }
  
  }, [props.sessionInfo, props.mobileDevice]);

  return(
    <div className={appView}>
      <div className="appHeader moduleBlock">
        <NavBar
          sessionUser={props.sessionUser}
          activityView={props.activityView}
          changeActivityView={changeActivityView}
          relatedPolicyID={props.relatedPolicyID}
          policyNumber={policyNumber}
          leadID={leadID}
          showNotifications={props.showNotifications}
          setShowNotifications={props.setShowNotifications}
          notifications={notifications}
          leadsList={leadsList}
          navigation={props.navigation}
          currentPath={props.currentPath}
          updatePage={props.updatePage}
          mobileDevice={props.mobileDevice}
          showMobileMore={props.showMobileMore}
          setShowMobileMore={props.setShowMobileMore}
          sessionVars={props.sessionvars}
          session={props?.session}
        />
      </div>
      <AppActivity
        activityView={props.activityView}
        singlePolicyHash={singlePolicyHash}
        setSinglePolicyHash={setSinglePolicyHash}
        changeActivityView={changeActivityView}
        relatedPolicyID={props.relatedPolicyID}
        policyNumber={policyNumber}
        setPolicyNumber={setPolicyNumber}
        setLeadID={setLeadID}
        sessionUser={props.sessionUser}
        setSessionUser={props.setSessionUser}
        policies={props.policies}
        policyList={props.policyList}
        setPolicyList={props.setPolicyList}
        profile={props.profile}
        updateFiltersNew={props.updateFiltersNew}
        filters={props.filters}
        homepageTimeline={homepageTimeline}
        graphData={graphData}
        updateTimeline={updateTimeline}
        currentPath={currentPath}
        chartData={props.chartData}
        updateChartData={props.updateChartData}
        showNotifications={props.showNotifications}
        setShowNotifications={props.setShowNotifications}
        notifications={notifications}
        loadNotifications={loadNotifications}
        statusHandler={statusHandler}
        visibleTimeline={visibleTimeline}
        statusMaxCount={statusMaxCount}
        visibleMaxStatusCount={visibleMaxStatusCount}
        setVisibleMaxStatusCount={setVisibleMaxStatusCount}
        loadHomePageTimelineData={loadHomePageTimelineData}
        loadHomepageTimeline={loadHomepageTimeline}
        loadAccountPolicies={loadAccountPolicies}
        filterHandler={filterHandler}
        setFilterHandler={setFilterHandler}
        deathBenefitsRange={deathBenefitsRange}
        setDeathBenefitsRange={setDeathBenefitsRange}
        updateDeathBenefitsRange={updateDeathBenefitsRange}
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        searchValHandler={searchValHandler}
        setSearchValHandler={setSearchValHandler}
        emptyDeathBenefit={emptyDeathBenefit}
        setEmptyDeathBenefit={setEmptyDeathBenefit}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        deathBenefitsRangeReset={deathBenefitsRangeReset}
        setDeathBenefitsRangeReset={setDeathBenefitsRangeReset}
        updateDeathBenefitsRangeReset={updateDeathBenefitsRangeReset}
        visiblePolicies={visiblePolicies}
        setMaxVisiblePolicies={setMaxVisiblePolicies}
        maxVisiblePolicies={maxVisiblePolicies}
        updateSearchVal={updateSearchVal}
        updatePage={props.updatePage}
        insuranceCompanyList={insuranceCompanyList}
        markSessionPolicyAsRead={props.markSessionPolicyAsRead}
        updateSessionProfile={props.updateSessionProfile}
        loadSessionPolicies={props.loadSessionPolicies}
        orderPolicies={orderPolicies}
        setSessionInfo={props.setSessionInfo}
        loadPolicyOverview={loadPolicyOverview}
        policyOrderDirection={policyOrderDirection}
        mobileDevice={props.mobileDevice}
        showMobileMore={props.showMobileMore}
        setShowMobileMore={props.setShowMobileMore}
        leadsList={leadsList}
        setLeadsList={setLeadsList}
        leadsSearchVal={leadsSearchVal}
        setLeadsSearchVal={setLeadsSearchVal}
        leadsFilters={leadsFilters}
        setLeadsFilters={setLeadsFilters}
        updateLeadsSearchVal={updateLeadsSearchVal}
        leadsListPriceRange={leadsListPriceRange}
        setLeadsListPriceRange={setLeadsListPriceRange}
        leadProps={leadProps}
        bidProps={bidProps}
        profileProps={profileProps}
        policiesProps={policiesProps}
        navigation={props.navigation}
        sessionVars={props.sessionVars}
        session={props?.session}
      />
      { props.showNotifications &&
          <NotificationBoard
            sessionUser={props.sessionUser}
            changeActivityView={props.changeActivityView}
            notifications={props.notifications}
            showNotifications={props.showNotifications}
            setShowNotifications={props.setShowNotifications}
            updatePage={props.updatePage}
            loadNotifications={props.loadNotifications}
            mobileDevice={props.mobileDevice}
            sessionVars={props.sessionVars}
          />
        }
    </div>
  );
}

function PublicPath(props){

  return(
    <div className={`loginBoard g fR${props?.session?.env?.viewport?.viewType !== "full" ? " alt" : ''}`}>
        <Routes>
          <Route
            exact
            path="/login"
            element={
              <Login
                sessionUser={props.sessionUser}
                setSessionUser={props.setSessionUser}
                updatePage={props.updatePage}
                account={props.account}
                mobileDevice={props.mobileDevice}
                sessionVars={props?.sessionVars}
                session={props?.session}
              />
            }
          />
          <Route
            exact
            path="*"
            element={
              <Rerouter
                session={props?.session}
                sessionUser={props.sessionUser}
              />
            }
          />
        </Routes>
    </div>
  );
}

function AppActivity(props){
  const [importedUploadPolicy, setImportedUploadPolicy] = useState({});

  return (
    <div className="appBoard g cC">
      <div className="appActivity">
        <OverlayHandler
          session={props?.session}
          overlay={props?.session?.env?.overlay}
          setter={props?.session?.env?.setOverlay}
          handleClose={props?.session?.env?.functions?.handleClose}
        />
        <Routes>
          <Route
            exact
            path="*"
            element={
              <Rerouter
                session={props?.session}
                sessionUser={props.sessionUser}
              />
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <HomePage
                sessionUser={props.sessionUser}
                changeActivityView={props.changeActivityView}
                homepageTimeline={props.homepageTimeline}
                graphData={props.graphData}
                updateTimeline={props.updateTimeline}
                policies={props.policies}
                chartData={props.chartData}
                statusHandler={props.statusHandler}
                visibleTimeline={props.visibleTimeline}
                statusMaxCount={props.statusMaxCount}
                visibleMaxStatusCount={props.visibleMaxStatusCount}
                setVisibleMaxStatusCount={props.setVisibleMaxStatusCount}
                loadHomePageTimelineData={props.loadHomePageTimelineData}
                loadHomepageTimeline={props.loadHomepageTimeline}
                mobileDevice={props.mobileDevice}
                updatePage={props.updatePage}
                profileProps={props.profileProps}
                filterHandler={props.filterHandler}
                setFilterHandler={props.setFilterHandler}
                sessionVars={props.sessionVars}
                session={props?.session}
              />
            }
          />
          <Route
            exact
            path="/upload"
            element={
              <Upload
                sessionUser={props.sessionUser}
                insuranceCompanyList={props.insuranceCompanyList}
                loadSessionPolicies={props.loadSessionPolicies}
                policies={props.policies}
                //loadPolicyOverview={props.loadPolicyOverview}
                loadNotifications={props.loadNotifications}
                mobileDevice={props.mobileDevice}
                updatePage={props.updatePage}
                sessionVars={props.sessionVars}
                importedUploadPolicy={importedUploadPolicy}
                setImportedUploadPolicy={setImportedUploadPolicy}
                leadsList={props.leadsList}
                setLeadsList={props.setLeadsList}
                session={props?.session}
              />
            }
          />
          {/* <Route
            exact
            path="/dashboard"
            element={
              <HomePage
                sessionUser={props.sessionUser}
                changeActivityView={props.changeActivityView}
                homepageTimeline={props.homepageTimeline}
                graphData={props.graphData}
                updateTimeline={props.updateTimeline}
                policies={props.policies}
                chartData={props.chartData}
                statusHandler={props.statusHandler}
                visibleTimeline={props.visibleTimeline}
                statusMaxCount={props.statusMaxCount}
                visibleMaxStatusCount={props.visibleMaxStatusCount}
                setVisibleMaxStatusCount={props.setVisibleMaxStatusCount}
                loadHomePageTimelineData={props.loadHomePageTimelineData}
                loadHomepageTimeline={props.loadHomepageTimeline}
                mobileDevice={props.mobileDevice}
                updatePage={props.updatePage}
                profileProps={props.profileProps}
                filterHandler={props.filterHandler}
                setFilterHandler={props.setFilterHandler}
                sessionVars={props.sessionVars}
                session={props?.session}
              />
            }
          /> */}
          <Route
            exact
            path="/policies/*"
            element={
              <Policies
                accountID={props.sessionUser.accountID}
                activityView={props.activityView}
                changeActivityView={props.changeActivityView}
                policies={props.policies}
                policyList={props.policyList}
                setPolicyList={props.setPolicyList}
                pID={props.pID}
                updateFiltersNew={props.updateFiltersNew}
                filters={props.filters}
                sessionUser={props.sessionUser}
                chartData={props.chartData}
                updateChartData={props.updateChartData}
                policyNumber={props.policyNumber}
                setPolicyNumber={props.setPolicyNumber}
                loadAccountPolicies={props.loadAccountPolicies}
                filterHandler={props.filterHandler}
                setFilterHandler={props.setFilterHandler}
                deathBenefitsRange={props.deathBenefitsRange}
                setDeathBenefitsRange={props.setDeathBenefitsRange}
                updateDeathBenefitsRange={props.updateDeathBenefitsRange}
                searchVal={props.searchVal}
                setSearchVal={props.setSearchVal}
                searchValHandler={props.searchValHandler}
                setSearchValHandler={props.setSearchValHandler}
                emptyDeathBenefit={props.emptyDeathBenefit}
                setEmptyDeathBenefit={props.setEmptyDeathBenefit}
                filterStatus={props.filterStatus}
                setFilterStatus={props.setFilterStatus}
                deathBenefitsRangeReset={props.deathBenefitsRangeReset}
                setDeathBenefitsRangeReset={props.setDeathBenefitsRangeReset}
                updateDeathBenefitsRangeReset={props.updateDeathBenefitsRangeReset}
                visiblePolicies={props.visiblePolicies}
                setMaxVisiblePolicies={props.setMaxVisiblePolicies}
                maxVisiblePolicies={props.maxVisiblePolicies}
                updateSearchVal={props.updateSearchVal}
                updatePage={props.updatePage}
                loadNotifications={props.loadNotifications}
                markSessionPolicyAsRead={props.markSessionPolicyAsRead}
                orderPolicies={props.orderPolicies}
                notifications={props.notifications}
                singlePolicyHash={props.singlePolicyHash}
                setSinglePolicyHash={props.setSinglePolicyHash}
                policyOrderDirection={props.policyOrderDirection}
                mobileDevice={props.mobileDevice}
                navigation={props.navigation}
                sessionVars={props.sessionVars}
                policiesProps={props.policiesProps}
                allPolicies={props.allPolicies}
                session={props?.session}
              />
              // <AllPolicies
              //   session={props?.session}
              // />
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Profile 
                userID={props.sessionUser.userID}
                sessionUser={props.sessionUser}
                profile={props.profile}
                updateSessionProfile={props.updateSessionProfile}
                setSessionInfo={props.setSessionInfo}
                mobileDevice={props.mobileDevice}
                updatePage={props.updatePage}
                sessionVars={props.sessionVars}
                filterHandler={props.filterHandler}
                setFilterHandler={props.setFilterHandler}
                profileProps={props.profileProps}
                session={props?.session}
              />
            }
          />
          <Route
            exact
            path="/leads/*"
            element={
              <Leads
                sessionUser={props.sessionUser}
                changeActivityView={props.changeActivityView}
                insuranceCompanyList={props.insuranceCompanyList}
                loadSessionPolicies={props.loadSessionPolicies}
                policies={props.policies}
                //loadPolicyOverview={props.loadPolicyOverview}
                loadNotifications={props.loadNotifications}
                mobileDevice={props.mobileDevice}
                updatePage={props.updatePage}
                leadsList={props.leadsList}
                setLeadsList={props.setLeadsList}
                setImportedUploadPolicy={setImportedUploadPolicy}
                leadsSearchVal={props.leadsSearchVal}
                setLeadsSearchVal={props.setLeadsSearchVal}
                leadsFilters={props.leadsFilters}
                setLeadsFilters={props.setLeadsFilters}
                updateLeadsSearchVal={props.updateLeadsSearchVal}
                leadsListPriceRange={props.leadsListPriceRange}
                setLeadsListPriceRange={props.setLeadsListPriceRange}
                leadProps={props.leadProps}
                navigation={props.navigation}
                sessionVars={props.sessionVars}
                session={props?.session}
              />
            }
          />
          <Route
            exact
            path="/bids/*"
            element={
              <Bids
                sessionUser={props.sessionUser}
                updatePage={props.updatePage}
                mobileDevice={props.mobileDevice}
                changeActivityView={props.changeActivityView}
                bidProps={props.bidProps}
                navigation={props.navigation}
                sessionVars={props.sessionVars}
                loadNotifications={props.loadNotifications}
                session={props?.session}
              />
            }
          />
          <Route
            exact
            path="/reporting/*"
            element={
              <Reporting session={props?.session} />
            }
          />
          <Route
            exact
            path="/tertiary/*"
            element={
              <Tertiary
                session={props?.session}
                branch="bids"
                stem="tertiary"  
              />
            }
          />
        </Routes>

        {/* { props.showNotifications &&
          <NotificationBoard
            sessionUser={props.sessionUser}
            changeActivityView={props.changeActivityView}
            notifications={props.notifications}
            showNotifications={props.showNotifications}
            setShowNotifications={props.setShowNotifications}
            updatePage={props.updatePage}
            loadNotifications={props.loadNotifications}
            mobileDevice={props.mobileDevice}
            fetch={props.fetch}
          />
        } */}
      </div>
    </div>
  )
}

export default App;