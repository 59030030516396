import React, { useEffect, useState } from 'react';

function SessionNavigation(props) {
  // Extract the first part of the path from the URL
  const env = props?.env;
  const user = props?.user?.data;
  const initialPath = env?.functions?.currentPath(window.location);

  const [data, setData] = useState({
    currentInstance: {view : initialPath?.stem, "instance" : initialPath?.recordID ? "single" : "all"},
    history : [{view : initialPath?.stem, "instance" : initialPath?.recordID ? "single" : "all"}],
  });

  const pathHandler = {
    "dashboard": "/dashboard",
    "reporting": "/reporting",
    "origination": "/policies",
    "servicing": "/policies",
    "bids": "/bids",
    "tertiary": "/tertiary",
    "leads": "/leads",
    "upload": "/upload",
  };

  const validPaths = {
    "dashboard" : {
      prompt : "Dashboard",
      path : "home",
    },
    "home" : {
      prompt : "Home",
      path : "home",
    },
    "policies" : {
      prompt : "View All Policies",
      path : "policies",
    },
    "leads" : {
      prompt : "View All Leads",
      path : "leads",
    },
    "bids" : {
      prompt : "View All Bids",
      path : "bids",
    },
    "reporting" : {
      prompt : "Reporting",
      path : "reporting",
    },
    "tertiary" : {
      prompt : "View All Tertiary",
      path : "tertiary",
    },
    "upload" : {
      prompt : "Upload",
      path : "upload",
    },
  }

  function isPathEligible(path) {
    const eligablePages = [
      "home",
      "policies",
      "upload",
      "leads",
      "bids",
      "profile",
      "reporting",
      "dashboard",
      "tertiary",
    ];

    const lowerCasePath = path?.toLowerCase();

    return eligablePages.some(page => page.toLowerCase() === lowerCasePath);
  }

  const homepage = (importedUser) => {
    const prioritizedViews = ["dashboard", "origination", "servicing", "bids", "tertiary", "leads", "reporting", "upload"];
    
    // Determine the pages and accountData to use
    const pages = importedUser?.pages ?? user?.pages ?? {};
    const accountData = importedUser?.accountData ?? user?.accountData ?? {};
    
    // Check if pages is an object before proceeding
    if (typeof pages !== 'object' || pages === null) {
      return "/profile";
    }
  
    // Iterate through the prioritized views
    for (const view of prioritizedViews) {
      // Check if the user has access to the view
      const userHasAccess = view in pages;
  
      // Check if at least one account has access to the view
      const accountHasAccess = Object.values(accountData).some(account => {
        const accountPages = account.pages ?? {};
        return view in accountPages;
      });
    
      // If both the user and at least one account have access to the view, return it
      if (userHasAccess && accountHasAccess) {
        return pathHandler?.[view];
      }
    }
  
    // If no match is found, return the default
    return "/profile";
  };
  
  const verifyPageAccess = (page) => {
    const accounts = user?.accountData ?? {}; // Assuming `user.accounts` contains the account list
    
    // Check if the user has access to the page
    const userHasAccess = Array.isArray(page)
      ? page.some(p => p in user?.pages)
      : page in user?.pages;
  
    if (!userHasAccess) {
      return false;
    }
  
    // Check if at least one account has access to the page
    const accountHasAccess = Object.values(accounts).some(account => {
      const accountPages = account.pages ?? {};
      return Array.isArray(page)
        ? page.some(p => p in accountPages)
        : page in accountPages;
    });
  
    return accountHasAccess;
  };  

  function eligablePath(currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null) {
    let path = currentPath === "tertiary" ? "bids" : currentPath;

    if(path === "profile"){
      return true;
    }

    if (!isPathEligible(path) ||
      !verifyPageAccess(path)
    ){
      return false;
    }

    return true;
  }

  function inlineNavigation(direction){
    const destination = {
      view : "home",
      instance : "all",
      prompt : "Dashboard",
    }

    if(direction === "back"){
      const view = data?.history[data?.history?.length - 1]?.view;
      destination.view = view;
      destination.instance = "all";
      destination.prompt = validPaths[view]?.prompt;
    }
    
    return destination;
  }

  function updateInstance(direction) {
    console.log(direction);
    setData(prevData => {
      // Default destination for cases other than "back"
      let newView = 'home';
      let newInstance = 'all';
      let newPrompt = 'Dashboard';
      let newHistory = prevData?.history;

      if (direction === "back") {
        // Navigate to the previous entry in history
        if (prevData?.history?.length > 1) {
          // Remove the last entry in the history
          newHistory = prevData?.history?.slice(0, -1);
          const lastEntry = newHistory?.[newHistory?.length - 1];

          newView = lastEntry?.view;
          newInstance = lastEntry?.instance;
          newPrompt = validPaths[newView]?.prompt || 'Dashboard';
        }
      } else if (direction?.view && direction?.instance) {
        // Normal navigation to a new instance
        newView = direction.view;
        newInstance = direction.instance;
        newPrompt = validPaths[newView]?.prompt || 'Dashboard';

        // Add to history
        newHistory = [...prevData.history, { view: newView, instance: newInstance }];
      }

      return {
        ...prevData,
        currentInstance: {
            view: newView,
            instance: newInstance,
        },
        history: newHistory,
      };
    });
  }

  const functions = {
    inlineNavigation,
    homepage,
    verifyPageAccess,
    eligablePath,
    isPathEligible,
    updateInstance,
  };

  // useEffect to handle the browser back button
  useEffect(() => {
    const handlePopState = () => {
      // Trigger the 'back' navigation in the state
      updateInstance('back');
    };

    // Add event listener for the popstate event
    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []); // Empty dependency array ensures this effect runs once, on mount

  return {
    data,
    setData,
    functions,
  };
}

export default SessionNavigation;