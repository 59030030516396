import React from 'react';

import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PanToolIcon from '@mui/icons-material/PanTool';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import MessageIcon from '@mui/icons-material/Message';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ForwardIcon from '@mui/icons-material/Forward';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SellIcon from '@mui/icons-material/Sell';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import GavelIcon from '@mui/icons-material/Gavel';
import SearchIcon from '@mui/icons-material/Search';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CampaignIcon from '@mui/icons-material/Campaign';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CancelIcon from '@mui/icons-material/Cancel';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ForumIcon from '@mui/icons-material/Forum';
import StartIcon from '@mui/icons-material/Start';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';
import ReplyTwoToneIcon from '@mui/icons-material/ReplyTwoTone';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone';
import RecommendTwoToneIcon from '@mui/icons-material/RecommendTwoTone';

const statusData = (detectedStatus, noColor, originator) => {
    const icon = (originator === "Customer" && detectedStatus?.customerIcon) 
        ? detectedStatus.customerIcon 
        : detectedStatus?.icon;

    // If `icon` is not defined, return null to avoid errors
    if (!icon) return null;

    const color = noColor
        ? "#FFFFFF"
        : (originator === "Customer" && detectedStatus?.customerColor)
            ? detectedStatus.customerColor
            : detectedStatus?.color;

    return React.cloneElement(icon, {
        sx: { color }
    });
};

const statuses = (status) => {
    let color, icon;

    switch (status) {
        case "Invoicing":
            color = "#673ab7";
            icon = <ReceiptLongIcon />;
            break;
        case "Rescission":
            color = "#3f51b5";
            icon = <HourglassTopIcon />;
            break;
        case "Meds/Illustration Ordered":
            color = "#e53935";
            icon = <MedicalInformationIcon />;
            break;
        case "Underwriting (LE's)":
            color = "#2196f3";
            icon = <NoteAltIcon />;
            break;
        case "Contracts Back":
        case "Contracts Back (Digital)":
        case "Contracts Out":
            color = "#f9a825";
            icon = <LockPersonIcon />;
            break;
        case "Offer Letter Out / CRF":
            color = "#455a64";
            icon = <RequestQuoteIcon />;
            break;
        case "Offer Letter In":
            color = "#607d8b";
            icon = <RequestPageIcon />;
            break;
        case "Bids":
            color = "#6a1b9a";
            icon = <ThumbsUpDownIcon />;
            break;
        case "Pre-Priced":
            color = "#4caf50";
            icon = <PriceChangeIcon />;
            break;
        case "Client Not Interested":
        case "Client not Interested":
            color = "#ef5350";
            icon = <PersonOffIcon />;
            break;
        case "Failed to Contact":
            color = "#ef5350";
            icon = <PhoneDisabledIcon />;
            break;
        case "Unqualified":
            color = "#ef5350";
            icon = <CancelIcon />;
            break;
        case "Sent to 3rd Party":
            color = "#f57c00";
            icon = <EmojiPeopleIcon />;
            break;
        case "Sent to Abacus":
            color = "#104735";
            icon = <RestartAltIcon />;
            break;
        case "Escrow / At Carrier":
        case "Escrow":
            color = "#1976d2";
            icon = <HandshakeIcon />;
            break;
        case "On Hold":
            color = "#ff7043";
            icon = <PanToolIcon />;
            break;
        case "Lead":
            color = "#9ccc65";
            icon = <FollowTheSignsIcon />;
            break;
        case "Shipped":
            color = "#9c27b0";
            icon = <LocalShippingIcon />;
            break;
        case "Shipped - Bid":
            color = "#4caf50";
            icon = <LocalShippingIcon />;
            break;
        case "Pricing / Valuation":
            color = "#9c27b0";
            icon = <PersonSearchIcon />;
            break;
        case "Unqualified Closed":
        case "Declined":
            color = "#ef5350";
            icon = <DoNotDisturbOnIcon />;
            break;
        case "Closed Lost with Bid":
        case "Lost with Bid":
        case "Lost with bid":
            color = "#ef5350";
            icon = <SentimentVeryDissatisfiedIcon />;
            break;
        case "Closed Lost":
            color = "#ef5350";
            icon = <NotInterestedIcon />;
            break;
        case "Closed Lost no bid":
            color = "#ef5350";
            icon = <ThumbDownIcon />;
            break;
        case "Closed Won":
            color = "#e65100";
            icon = <CelebrationIcon />;
            break;
        case "Application Sent":
            color = "#66bb6a";
            icon = <ForwardIcon />;
            break;
        case "Application Sent - Lead":
            color = "#6a1b9a";
            icon = <ForwardIcon />;
            break;
        case "Contact Attempted 1":
        case "Contact Attempted 2":
        case "Contact Attempted 3":
        case "Contact Attempted 4":
        case "Contact Attempted 5":
            color = "#0277bd";
            icon = <PhoneMissedIcon />;
            break;
        case "Application Received":
            color = "#6a1b9a";
            icon = <CallReceivedIcon />;
            break;
        case "Follow Up":
            color = "#6a1b9a";
            icon = <AccessAlarmIcon />;
            break;
        case "Owned":
            color = "#6d4c41";
            icon = <AccountBalanceWalletIcon />;
            break;
        case "Sold":
            color = "#4caf50";
            icon = <SellIcon />;
            break;
        case "Matured":
            color = "#4527a0";
            icon = <RunningWithErrorsIcon />;
            break;
        case "Lapsed":
            color = "#ff9800";
            icon = <TimelapseIcon />;
            break;
        case "Contracts":
            color = "#ff5722";
            icon = <GavelIcon />;
            break;
        case "Does Not Fit Parameters":
            color = "#ef5350";
            icon = <ExtensionOffIcon />;
            break;
        case "Bidding":
            color = "#6a1b9a";
            icon = <CampaignIcon />;
            break;
        case "In Conflict":
            color = "#ef5350";
            icon = <NearbyErrorIcon />;
            break;
        case "No Bid":
            color = "#ef5350";
            icon = <ThumbDownOffAltIcon />;
            break;
        case "Under Review":
        case "Phase II":
            color = "#2196f3";
            icon = <SearchIcon />;
            break;
        case "Requested Info":
            color = "#6a1b9a";
            icon = <PendingActionsIcon />;
            break;
        case "Service Start":
            color = "#4caf50";
            icon = <StartIcon />;
            break;
        case "Message":
            color = "#4fc3f7";
            icon = <ForumIcon />;
            break;
        case "Request":
            color = "#ef5350";
            icon = <AddTaskIcon />;
            break;
        case "default":
            color = "dimgray";
            icon = <NextPlanOutlinedIcon />;
        default:
            color = "dimgray";
            icon = <NextPlanOutlinedIcon />;
    }

    return { color, icon };
};

const types = {
    "Note" : {
        color: "#03a9f4",
        customerColor : "#66bb6a",
        icon : <MessageIcon />,
        type : "note",
    },
    "Follow Up" : {
        color: "#6a1b9a",
        icon : <AccessAlarmIcon />,
        type : "followUp",
    },
    "VOC" : {
        color: "#4fc3f7",
        icon : <VerifiedUserIcon />,
        type : "voc",
    },
    "Payment" : {
        color: "#4caf50",
        icon : <MonetizationOnIcon />,
        type : "payment",
    },
    "Response - Request" : {
        color: "dimgray",
        icon : <TextSnippetTwoToneIcon />,
        altIcon : <ReplyTwoToneIcon/>,
        type : "response",
        responseType : "Request",
    },
    "Request" : {
        color : {
            Open : "#01579b",
            Completed : "#43a047",
            Denied : "#ef5350",
        },
        icon : {
            Open : <PendingActionsTwoToneIcon />,
            Completed : <CheckCircleTwoToneIcon/>,
            Denied : <ThumbDownAltTwoToneIcon/>,
        },
        altIcon : <PsychologyAltTwoToneIcon/>,
        type : "request",
    },
    "Bid" : {
        color : {
            Open : "#6a1b9a",
            Completed : "#43a047",
            Closed : "#43a047",
            Denied : "#ef5350",
        },
        icon : {
            Open : <ThumbsUpDownTwoToneIcon />,
            Completed : <RecommendTwoToneIcon/>,
            Closed : <RecommendTwoToneIcon/>,
            Denied : <ThumbDownAltTwoToneIcon/>
        },
        altIcon : <ErrorTwoToneIcon/>,
        type : "bid",
    },
}

function statusDetails({ status, type, noColor, originator, timelineType }) {
    return statusData(type !== "Status" ? types?.[type] : statuses(status ?? "default"), noColor, originator);
}

export const getStatusData = (status, type) => {
    return type !== "Status" ? types?.[type] : statuses(status ?? "default");
};

export default statusDetails;