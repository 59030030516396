import React from 'react';
import { useState, useEffect, useRef } from 'react';

import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import GppGoodTwoToneIcon from '@mui/icons-material/GppGoodTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import InboxIcon from '@mui/icons-material/Inbox';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import GavelIcon from '@mui/icons-material/Gavel';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import MedicalInformationTwoToneIcon from '@mui/icons-material/MedicalInformationTwoTone';
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import AssistantTwoToneIcon from '@mui/icons-material/AssistantTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';

import StringInput from '../Components/StringInput.js';

function DetailsTab(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const shareLink = props?.shareLink;
    const currentCase = props?.currentCase?.data;
    const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;

    const formHandler = () => {
        const policyPrimaryInfo = (includeDateOfDeath = false) => {
            const primaryRow = [["primaryBirthDate", true], ["age", true]];
        
            if (includeDateOfDeath) {
                primaryRow.push(["primaryDateOfDeath", true]);
            }
        
            return {
                header: { value: "Primary Information", icon: <PersonOutlineTwoToneIcon /> },
                inputs: createInputGroups([
                    [["primaryFirstName", true], ["primaryLastName", true], ["primaryGender", true]],
                    primaryRow
                ], currentCase)
            };
        };
    
        const bidPrimaryInfo = () => ({
            header: { value: "Primary Information", icon: <PersonOutlineTwoToneIcon /> },
            inputs: createInputGroups([
                [["primaryFullName", true], ["primaryGender", true]],
                [["primaryBirthDate", true], ["age", true]]
            ], currentCase)
        });
    
        const policySecondaryInfo = (includeDateOfDeath = false) => {
            const secondaryRow = [["secondaryBirthDate", true], ["secondaryAge", true]];
        
            if (includeDateOfDeath) {
                secondaryRow.push(["secondaryDateOfDeath", true]);
            }

            return {
                header: { value: "Secondary Information", icon: <PeopleAltTwoToneIcon />, conditional: true },
                inputs: createInputGroups([
                    [["secondaryFullName", true], ["secondaryGender", true]],
                    secondaryRow // Use the modified row here
                ], currentCase)
            };
        };

        const bidSecondaryInfo = () => ({
            header: { value: "Secondary Information", icon: <PeopleAltTwoToneIcon />, conditional: true },
            inputs: createInputGroups([
                [["secondaryFullName", true], ["secondaryGender", true]],
                [["secondaryBirthDate", true], ["secondaryAge", true]]
            ], currentCase)
        });
    
        const policyInfo = () => ({
            header: {
                value: "Policy Information",
                icon: <FeedTwoToneIcon />,
                subHeader : {
                    value : `${currentCase?.policyNumber ? ` - ${currentCase?.policyNumber}` : ''}`,
                    link: {
                        href: currentCase?.qbLink,
                        prompt: "View on Quickbase",
                        employeeOnly: true
                    }
                }
            },
            inputs: createInputGroups([
                [["insuranceCompany", true], ["issueDate", true]],
                [["policyStatus", true], ["deathBenefit", true]],
                [["ownerSitusState", true], ["ownerType", true]]
            ], currentCase)
        });

        const bidInfo = () => ({
            header: {
                value : `Policy Information`,
                icon : <FeedTwoToneIcon />,
                subHeader : {
                    value : `${currentCase?.policyNumber ? `${currentCase?.policyNumber}` : ''}`,
                    // dataPoint : currentCase?.shippingStatus,
                    link: {
                        href: currentCase?.qbLink,
                        prompt: "View on Quickbase",
                        employeeOnly: true
                    }
                },
            },
            inputs: createInputGroups([
                [["shippingStatus", true], ["policyType", true], ["faceValue", true]],
                [["insuranceCompany", true], ["issueDate", true]],
                [["clarinetRecordID", true], ["portfolioName", true]]
            ], currentCase)
        });
    
        const createCaseAssistanceInfo = () => ({
            header: {
                value: "Case Assistance",
                icon: <AssistantTwoToneIcon />
            },
            inputs: createInputGroups([
                [["accountManager", true], ["caseProcessor", true], ["caseOwner", true]],
            ], currentCase)
        });
    
        const addInputs = (template, additionalInputs) => ({
            ...template,
            inputs: [
                ...template.inputs,
                ...createInputGroups(additionalInputs, currentCase)
            ]
        });

        const formTemplates = {
            policies : {
                origination :[
                    {
                        header: { value: "Primary Information", icon: <PersonOutlineTwoToneIcon /> },
                        inputs: createInputGroups([
                            [["primaryFirstName", true], ["primaryLastName", true], ["primaryGender", true]],
                            [["primaryBirthDate", true], ["age", true]]
                        ])
                    }
                ]
            },
            bids : {
                bids : [
                    {
                        header : {
                            value: "Primary Information",
                            icon: <PersonOutlineTwoToneIcon />
                        },
                        inputs : createInputGroups([
                            [["primaryFullName", true], ["primaryGender", true], "primaryBirthDate"]]),
                            // [["primaryFullName", true], ["primaryGender", true]],
                            // [["primaryBirthDate", true], ["age", true]]])
                    }, {
                        header : {
                            value: "Secondary Information",
                            icon: <PersonOutlineTwoToneIcon />,
                            conditional: true,
                        },
                        inputs : createInputGroups([
                            [["secondaryFullName", true], ["secondaryGender", true], "secondaryBirthDate"]]),
                            // [["secondaryFullName", true], ["secondaryGender", true]],
                            // [["secondaryBirthDate", true], ["secondaryAge", true]]])
                    }, {
                        header: {
                            value : `Policy Information`,
                            icon : <FeedTwoToneIcon />,
                            subHeader : {
                                value : `${currentCase?.policyNumber ? `${currentCase?.policyNumber}` : ''}`,
                                link: {
                                    href: currentCase?.qbLink,
                                    prompt: "View on Quickbase",
                                    employeeOnly: true
                                }
                            }
                        },
                        inputs: createInputGroups([
                            [["shippingStatus", true], ["policyType", true], ["deathBenefit", true]],
                            [["insuranceCompany", true], ["issueDate", true], ["clarinetRecordID", true]],
                        ])
                    }, {
                        header: {
                            value: "Case Assistance",
                            icon: <AssistantTwoToneIcon />,
                            employeeOnly : true,
                        },
                        inputs: createInputGroups([
                            [["accountManager", true], ["caseProcessor", true], ["caseOwner", true]],
                        ])
                    }
                ],
                tertiary : [
                    {
                        header : {
                            value: "Primary Information",
                            icon: <PersonOutlineTwoToneIcon />
                        },
                        inputs : createInputGroups([
                            [["primaryFullName", true], ["primaryGender", true]],
                            [["primaryBirthDate", true], ["age", true]]])
                    }, {
                        header : {
                            value: "Secondary Information",
                            icon: <PersonOutlineTwoToneIcon />,
                            conditional: true,
                        },
                        inputs : createInputGroups([
                            [["secondaryFullName", true], ["secondaryGender", true]],
                            [["secondaryBirthDate", true], ["secondaryAge", true]]])
                    }, {
                        header: {
                            value : `Policy Information`,
                            icon : <FeedTwoToneIcon />,
                            subHeader : {
                                value : `${currentCase?.policyNumber ? `${currentCase?.policyNumber}` : ''}`,
                                link: {
                                    href: currentCase?.qbLink,
                                    prompt: "View on Quickbase",
                                    employeeOnly: true
                                }
                            }
                        },
                        inputs: createInputGroups([
                            [["shippingStatus", true], ["policyType", true], ["faceValue", true]],
                            [["insuranceCompany", true], ["issueDate", true]],
                            [["clarinetRecordID", true], ["portfolioName", true]],
                            [["securityInterText", true], ["policyOwnedBy", true]]
                        ])
                    }
                ],
            }
        }

        // const formTemplates = {
        //     policies: {
        //         origination: [
        //             addInputs(policyPrimaryInfo(), [
        //                 [["primaryPhoneNumber", true], ["primaryEmail", true]]
        //             ]),
        //             addInputs(policySecondaryInfo(), [
        //                 [["secondaryDateOfDeath", true]]
        //             ]),
        //             policyInfo(),
        //             createCaseAssistanceInfo(),
        //         ],
        //         servicing: [
        //             policyPrimaryInfo(true),
        //             addInputs(policySecondaryInfo(true), [
        //                 [["secondaryDateOfDeath", true]]
        //             ]),
        //             policyInfo(),
        //         ]
        //     },
        //     bids: {
        //         bids: [
        //             bidPrimaryInfo(),
        //             bidSecondaryInfo(),
        //             bidInfo()
        //         ],
        //         secondary: [
        //             bidPrimaryInfo(),
        //             bidSecondaryInfo(),
        //             bidInfo()
        //         ],
        //         tertiary: [
        //             bidPrimaryInfo(),
        //             bidSecondaryInfo(),
        //             addInputs(bidInfo(), [
        //                 [["securityInterText", true], ["policyOwnedBy", true]]
        //             ]),
                    
        //         ]
        //     }
        // };
    
        return formTemplates?.[props?.branch]?.[props?.stem] ?? [];
        // return formTemplates?.["policies"]?.["servicing"] ?? [];
    };

    const createInputGroups = (inputs) => {
        return inputs.map(group => {
            if (!Array.isArray(group)) {
                return {};
            }
    
            return group.reduce((groupObject, item) => {
                if (Array.isArray(item) && item.length === 2) {
                    const [key, readOnly] = item;
                    return {
                        ...groupObject,
                        [key]: {
                            value: currentCase?.[key] ?? '',
                            readOnly
                        }
                    };
                } else if (typeof item === 'string') {
                    return {
                        ...groupObject,
                        [item]: {
                            value: currentCase?.[item] ?? '',
                            readOnly: true
                        }
                    };
                } else {
                    return groupObject;
                }
            }, {});
        });
    };

    const getShippingStatusStyle = (value) => {
        switch (value) {
            case 'Shipped':
            case 'Initial Assessment':
                return {
                    color : "#1b5e20",
                    backgroundColor : "#e8f5e9",
                    border : "solid 2px #1b5e20",
                    icon : <InboxIcon/>
                };
            case 'Under Review':
            case 'Phase II':
            case 'Requested Info':
            case 'Request LEs / Datatape':
                return {
                    color : "#01579b",
                    backgroundColor : "#e3f2fd",
                    border : "solid 2px #01579b",
                    // icon : <FlagCircleIcon/>
                };
            case 'Bidding':
                return {
                    color : "#6a1b9a",
                    backgroundColor : "#f3e5f5",
                    border : "solid 2px #6a1b9a",
                    icon : <CampaignIcon/>
                };
            case 'Closed Won':
                return {
                    color : "#004d40",
                    backgroundColor : "#e0f2f1",
                    border : "solid 2px #004d40",
                    icon : <CelebrationIcon/>
                };
            case 'Closing':
                return {
                    color : "#ff9800",
                    backgroundColor : "#fff3e0",
                    border : "solid 2px #ff9800",
                    icon : <GavelIcon/>
                };
            case 'Declined':
            case 'No Bid':
            case 'Does Not Fit Parameters':
            case 'In Conflict':
            case 'Lost with bid':
                return {
                    color : "#ef5350",
                    backgroundColor : "#ffebee",
                    border : "solid 2px #ef5350",
                    icon : <DoDisturbOnIcon/>
                };
            default:
                return undefined; // Return an empty object or a default style if no match is found
        }
    };

    function printDetailsInteraction(){
        // if(props?.branch === "bids" && props?.stem === "bids"){
        //     return (
        //         <div key="summaryStatement" className="policyDetailsCardTitle g cC fC dG">
        //             <div className="bidSummary g cC fC fR f">
        //                 {currentCase &&
        //                     <div
        //                         key={"originatorStatement"}
        //                         className={`summaryStatement g cC${currentCase?.marketPrice !== undefined ? " alt" : ''}`}
        //                     >
        //                         <div className="f g cC data">
        //                             <div className="summaryStatementTitle">
        //                                 {currentCase?.marketPrice !== undefined ?
        //                                     <div className="bold f cC">
        //                                         Market Price
        //                                     </div>
        //                                 :
        //                                 currentCase?.bidDate ?
        //                                         "Your Current " + session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid"
        //                                     :
        //                                         "No Current " + session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid"
        //                                 }
        //                             </div>
        //                             <div className="summaryStatementBidValue cC s e">
        //                                 {currentCase?.marketPrice !== undefined ?
        //                                     session?.env?.functions?.convertIntToCurrency(currentCase?.marketPrice, props.mobileDevice)
        //                                 :
        //                                     <ThumbsUpDownIcon/>
        //                                 }
        //                             </div>
        //                         </div>
        //                         {currentCase?.marketPrice !== undefined &&
        //                             <div className="vDivider">
        //                             </div>
        //                         }
        //                         <div className="f g cC data">
        //                             <div className="summaryStatementTitle">
        //                                 {`${currentCase?.marketPrice !== undefined ? "Your " : ''}Date of ${session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid"}`}
        //                             </div>
        //                             <div className="summaryStatementBidValue cC s e">
        //                                 {currentCase?.bidDate ? session?.env?.functions?.reformatDate(currentCase?.bidDate) : "-"}
        //                             </div>
        //                         </div>
        //                         <div className="f g cC data">
        //                             <div className="summaryStatementTitle">
        //                                 {`${currentCase?.marketPrice !== undefined ? "Your " : ''}Amount`}
        //                             </div>
        //                             <div className="summaryStatementBidValue cC s e">
        //                                 {currentCase?.grossBid ? session?.env?.functions?.convertIntToCurrency(currentCase?.grossBid, props.mobileDevice) : "-"}
        //                             </div>
        //                         </div>
        //                     </div>
        //                 }
        //             </div>
        //         </div>
        //     )
        // }

        if(props?.stem === "servicing" || props?.stem === "bids"){
            return (
                <div className={`detailsInteraction ${props?.stem} f g s dG`}>
                    {props?.stem === "bids" &&
                        <>
                            <div className={`marketPrice f g cC bR dP dG bold oH pR${currentCase?.marketPrice ? " active" : " inactive"}`}>
                                <div className="f bC fR">
                                    Market Price
                                </div>
                                <div className="data f tC fR">
                                    {currentCase?.marketPrice ? session?.env?.functions?.convertIntToCurrency(currentCase?.marketPrice) : "Pending"}
                                </div>
                            </div>
                            <div className={`currentOffer f g bR oH pR${currentCase?.grossBid ? " active" : " inactive"}`}>
                                {currentCase?.grossBid ?
                                    <>
                                        <div className="date f fR g dG dP">
                                            <div className="f bC">
                                                Your offer on
                                            </div>
                                            <div className="f tC bold">
                                                {session?.env?.functions?.reformatDate(currentCase?.bidDate) ?? "N/A"}
                                            </div>
                                        </div>
                                        <div className="f cC bold data">
                                            {session?.env?.functions?.convertIntToCurrency(currentCase?.grossBid)}
                                        </div>
                                    </>
                                :
                                    <div className="f cC bold">
                                        No submitted offer
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {props?.stem === "servicing" &&
                        <>
                            <div className="nextPremium g f bR dP dG cC fC">
                                <div className="header g bold cC dG">
                                    <PaidTwoToneIcon/>
                                    <div className="f cL">
                                        Next Premium
                                    </div>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="data g dG f">
                                    <div className="dataPoint g dG">
                                        <div className="prompt">
                                            Date
                                        </div>
                                        <div className="value">
                                            {session?.env?.functions?.reformatDate(currentCase?.data?.premiumsData?.nextPremium?.date) ?? "-"}
                                        </div>
                                    </div>
                                    <div className="dataPoint g dG">
                                        <div className="prompt">
                                            Amount
                                        </div>
                                        <div className="value">
                                            {currentCase?.data?.premiumsData?.nextPremium?.projectedPremium ?? "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="previousVOC g f bR dP dG cC fC">
                                <div className="header g bold cC dG">
                                    <GppGoodTwoToneIcon/>
                                    <div className="f cL">
                                        Last VOC
                                    </div>
                                </div>
                                <div className="divider">
                                </div>
                                <div className="data g dG f">
                                    <div className="dataPoint g dG">
                                        <div className="prompt">
                                            Date
                                        </div>
                                        <div className="value">
                                            {currentCase?.data?.vocData?.completedDate ?? "-"}
                                        </div>
                                    </div>
                                    <div className="dataPoint g dG">
                                        <div className="prompt" title="Cost of Insurance">
                                            COI
                                        </div>
                                        <div className="value">
                                            {currentCase?.data?.vocData?.currentCOI ?? "-"}
                                        </div>
                                    </div>
                                    <div className="dataPoint g dG">
                                        <div className="prompt" title="Account Value">
                                            AV
                                        </div>
                                        <div className="value">
                                            {currentCase?.data?.vocData?.accountValue ?? "-"}
                                        </div>
                                    </div>
                                    <div className="dataPoint g dG">
                                        <div className="prompt" title="Cash Surrender Value">
                                            CSV
                                        </div>
                                        <div className="value">
                                            {currentCase?.data?.vocData?.cashValue ?? "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            )
        }
    }

    function printForm() {
        const formData = formHandler(currentCase);
        if (!formData) return null;

        return (
            <>
                {formData?.map((section, sectionIndex) => {
                    // Check if the section is conditional and has no values to display
                    if (section?.header?.conditional) {
                        const hasValue = section.inputs.some(inputGroup =>
                            Object.entries(inputGroup).some(([key, attr]) => attr?.value)
                        );
                        if (!hasValue) {
                            return null;
                        }
                    }

                    if (section?.header?.employeeOnly && !session?.user?.data?.isAbacusEmployee) {
                        return null;
                    }

                    return (
                        <div className="section g dG dP" key={sectionIndex}>
                            <div className="header f g cL dG fR">
                                <div className="f cC">
                                    {section?.header?.icon}
                                </div>
                                <span className="bold">
                                    {section?.header?.value}
                                </span>
                                {section?.header?.subHeader?.value &&
                                    <span className="bold">
                                        | {section?.header?.subHeader?.value}
                                    </span>
                                }
                                {section?.header?.subHeader?.dataPoint && (
                                    <div
                                        className={`dataPoint bR bold lH g fR f cC${getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.icon ? " dG" : ''}`}
                                        style={{
                                            backgroundColor: getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.backgroundColor,
                                            color: getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.color,
                                            border: getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.border,
                                        }}
                                    >
                                        {getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.icon}
                                        <span className={`f cC gC2${getShippingStatusStyle(section?.header?.subHeader?.dataPoint)?.icon ? " gC2" : ''}`}>
                                            {`${section?.header?.subHeader?.dataPoint}`}
                                        </span>
                                    </div>
                                )}
                                {section?.header?.subHeader && (
                                    section?.header?.subHeader?.link?.href &&
                                        (!section?.header?.subHeader?.link?.employeeOnly || session?.user?.data?.isAbacusEmployee) && (
                                            <div className="f cR gC5">
                                                <span className="link cR dG p">
                                                    <PublicTwoToneIcon/>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={section?.header?.subHeader?.link?.href}
                                                    >
                                                        {section?.header?.subHeader?.link?.prompt}
                                                    </a>
                                                </span>
                                            </div>
                                        )
                                )}
                            </div>
                            {section.inputs.map((inputGroup, groupIndex) => (
                                <div
                                    key={groupIndex}
                                    className="row g cC dG"
                                    style={{ gridTemplateColumns: `repeat(${Object.keys(inputGroup)?.length}, 1fr)` }}
                                >
                                    {Object.entries(inputGroup).map(([key, attr]) => (
                                        <StringInput
                                            session={session}
                                            key={key}
                                            placeholder={attributeData?.[key]?.friendlyTerm}
                                            value={attr?.value || ''}
                                            onClick={() => console.log(attributeData?.[key], key, sectionIndex, groupIndex)}
                                            textData={attributeData?.[key]}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    );
                })}
            </>
        );
    }

    useEffect(() => {

    }, []);

    return (
        <div
            className={`detailsTab f g oA${!currentCase ? " s b inactive" : ''}`}
            key="details"
        >
            {!currentCase ?
                <div className="loading f cC g bR">
                    <CircularProgress color="inherit"/>
                </div>
            :
                <>
                    {printDetailsInteraction()}
                    <div className="form">
                        {printForm()}
                    </div>
                </>
            }
        </div>
    );
}

export default DetailsTab;