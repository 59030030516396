import React from 'react';
import { useState, useEffect, useRef } from 'react';

import ViewBarBtn from '../Components/ViewBarBtn.js';
import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportList from '../Components/Reporting/ReportList.js';
import ReportTopbar from '../Components/Reporting/ReportTopbar.js';

function Reporting(props){
    const [requestingBatch, setRequestingBatch] = useState(undefined);
    // const [typing, setTyping] = useState(false);
    const [reportingView, setReportingView] = useState("list");
    const [editingRowValues, setEditingRowValues] = useState([]);
    const [editCellDropdownHandler, setEditCellDropdownHandler] = useState({ showMenu : undefined });
    const [editMode, setEditMode] = useState(false);


    const session = props?.session;
    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport;
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const userOpportunityTypes = session?.user?.data?.opportunityTypes;
    const editingReportStatus = selectedReport?.editable === "always";

    const initialReportCategory = selectedReport?.stem || (userOpportunityTypes && userOpportunityTypes[0]) || "deceased";
    const reportType = reporting?.functions?.reportType(selectedReport?.stem);

    const [reportCategory, setReportCategory] = useState(initialReportCategory);
    const reportCategoryObj = {reportCategory, setReportCategory}

    const [search, setSearch] = useState({
        searching : props?.viewType !== "mobile" ? true : undefined,
        searchValue : selectedReport?.search ?? ''
    });

    /* Dropdowns */
    const [dropdowns, setDropdowns] = useState({
        selectedFolder : {
            showMenu : false,
            name : "Show All",
            onClick : (value) => {
                updateDropdown("selectedFolder", {"showMenu" : false, "name" : value})
            }
        },
        pagination : {
            showMenu : false,
            rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
            index : selectedReport?.lastPageIndex ?? 1,
        },
        editCellDropdownHandler : {
            showMenu : false,
            name : "Show All",
        },
    });

    const updateDropdown = (key, attributes) => {
        setDropdowns((prev) => {
            const updatedDropdowns = {
                ...prev,
                [key]: {
                    ...prev[key],
                    ...attributes, // Spread the incoming attributes to update multiple fields
                },
            };
            return updatedDropdowns;
        });
    };

    const resetDropdowns = () => {
        setDropdowns((prev) => {
            const updated = Object.keys(prev).reduce((acc, key) => {
                acc[key] = { ...prev[key], showMenu: false };
                return acc;
            }, {});
            return updated;
        });
    };

    // const [pagination, setPagination] = useState({
    //     showMenu : false,
    //     rowMax : session?.storage?.reporting?.rowMax ?? 100,
    //     index : selectedReport?.lastPageIndex ?? 1,
    // });

    // const [selectedFolder, setSelectedFolder] = useState({
    //     name : "Show All",
    //     showMenu : false
    // });

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            // Check if showMenu is already true
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            // If showMenu is not true, return the previous state without changes
            return prev;
        });
    };

    // const resetDropdowns = () => {
    //     if(pagination?.showMenu){
    //         resetShowMenu(setPagination);
    //     }
    //     if(selectedFolder?.showMenu){
    //         resetShowMenu(setSelectedFolder);
    //     }
    //     if(editCellDropdownHandler?.showMenu){
    //         resetShowMenu(setEditCellDropdownHandler);
    //     }
    // };

    const requestObject = {
        session,
        requestingBatch,
        setRequestingBatch,
        pagination : dropdowns?.pagination
        // pagination,
    }

    const handleSearchChange = (event) => {
        const value = event?.target?.value;
        setSearch((prev) => ({
            ...prev,
            searchValue: value,
        }));
        // setTyping(true);
    };

    useEffect(() => {
        if(selectedReport?.stem && reportCategory !== selectedReport?.stem){
            setReportCategory(selectedReport?.stem);
        }
    }, [selectedReport?.stem]);

    useEffect(() => {
        // if (session?.env?.isTyping || !typing) return;
        if (session?.env?.isTyping) return;
        session?.set('reporting', 'selectedReport.search', search?.searchValue);

        // setTyping(false);
    }, [session?.env?.isTyping, search.searchValue]);

    useEffect(() => {
        if(reportCategory === undefined){
            setReportCategory(userOpportunityTypes?.[0])
        }
    }, [userOpportunityTypes]);

    return (
        <div className="reportingPage g bR" onClick={resetDropdowns}>
            <div className="reportingHub g oH">
                {props?.viewType === "mobile" &&
                    <div className="reportingNavBar f g cC">
                        <div className="f cC t">
                            <div className="viewBar cC">
                                <ViewBarBtn
                                    isActive={reportingView === "list"}
                                    onClick={() => setReportingView("list")}
                                    label="All Reports"
                                    alertType={"Status"}
                                    viewType={props?.viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                                <ViewBarBtn
                                    isActive={reportingView === "report"}
                                    onClick={() => setReportingView("report")}
                                    label="Selected Report"
                                    alertType={"Status"}
                                    viewType={props?.viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                            </div>
                        </div>
                    </div>
                }
                <ReportTopbar
                    session={props?.session}
                    reportingView={reportingView}
                    reportCategory={reportCategoryObj?.reportCategory}
                    handleSearchChange={handleSearchChange}
                    search={{data : search, set : setSearch}}
                    selectedReport={selectedReport}
                    default={true}
                />
                <div className="reportBuilder f fR g s oH">
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "list")) &&
                        <ReportList
                            session={session}
                            requestObject={requestObject}
                            reportCategoryObj={reportCategoryObj}
                            resetDropdowns={resetDropdowns}
                            reportingView={{data : reportingView, set : setReportingView }}
                            search={{data : search, set : setSearch}}
                            dropdowns={{data : dropdowns, set : updateDropdown}}
                        />
                    }
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "report")) &&
                        <ReportSheet
                            session={props?.session}
                            requestObject={requestObject}
                            reportType={reportType}
                            // search={{ data : search, set : setSearch}}
                            reportCategoryObj={reportCategoryObj}
                            // pagination={{data : pagination, set : setPagination}}
                            editingRowValues={{data : editingRowValues, set : setEditingRowValues}}
                            // sortedListResults={{data : sortedListResults, set : setSortedListResults}}
                            resetDropdowns={resetDropdowns}
                            // editCellDropdownHandler={{
                            //     data: editCellDropdownHandler,
                            //     set: (newValue) => setEditCellDropdownHandler(prev => ({ ...prev, showMenu: newValue }))
                            // }}
                            dropdowns={{data : dropdowns, set : updateDropdown}}
                            editMode={editMode}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Reporting;