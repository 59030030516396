
import React from 'react';
import { useState, useEffect, useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import AutorenewTwoToneIcon from '@mui/icons-material/AutorenewTwoTone';

import Dropdown from '../Components/Dropdown.js';
import DownloadBtn from '../Components/DownloadBtn.js';

function PremiumSchedule(props){
    const session = props?.session;
    const allPremiumSchedules = props?.premiumScheduleData?.premiumSchedule;
    const selectedPremium = props?.premiumScheduleData?.premiumSchedule?.[props?.premiumScheduleData?.selectedPremiumKey] || {};
    const selectedPremiumSchedule = selectedPremium?.premiumSchedule || {};
    const [downloadStatus, setDownloadStatus] = useState(false);

    const preferredFormat = {
        "schedule" : {
            friendlyTerm : "Schedule",
            format : "schedule",
            onClick : () => {downloadSchedule()}
        },
        "graph" : {
            friendlyTerm : "Graph",
            format : "graph",
            onClick : () => {downloadGraph()}
        }
    }

    function downloadSchedule(){
        session?.env?.functions?.downloadPremiumSchedule(
            session,
            selectedPremiumSchedule,
            session?.[props?.branch]?.data?.[props?.stem]?.downloadFileType
        );
        session?.set(props?.branch, `${props?.stem}.preferredPremiumsFormat`, "schedule");
        session?.env?.functions?.updateLocalStorage("bids", props?.stem, "preferredPremiumsFormat", "schedule");
    }

    function downloadGraph(){
        session?.env?.functions?.downloadLineGraph(
            session,
            {
                dataPointer : "premiumSchedule",
                plotPointAttr : "premium",
                yAxisLabel : "deathBenefit",
                xAxisLabel : "date",
                cycles : 24,
                cyclesFriendlyTerm : "Months",
                graphName : "Premium Schedule",
                graphData : selectedPremiumSchedule?.slice(0, 24),
                reportName : `Option ${props?.premiumScheduleData?.selectedPremiumKey + 1}`,
                reportList: props?.premiumScheduleData?.premiumSchedule?.reduce((acc, item, index) => {
                    acc[index] = {
                        ...item,
                        reportKey: index,
                        path: `${index}.premiumSchedule`,
                        reportName : `Option ${index + 1}`,
                    };
                    return acc;
                }, {}),
                reportNamePrompt : "Option ",
                selectedReportKey : 0,
            },
            `Option ${props?.premiumScheduleData?.selectedPremiumKey + 1}`
        );
        session?.set(props?.branch, `${props?.stem}.preferredPremiumsFormat`, "graph");
        session?.env?.functions?.updateLocalStorage("bids", props?.stem, "preferredPremiumsFormat", "graph");
    }

    return (
        <>
            <div className={`header f bR fC g dP dG${!Object.keys(selectedPremiumSchedule)?.length ? " inactive" : ''}`}>
                <div className="data g fR cC">
                    <div className="f cL lH">
                        <div className="point cL g dG" title={`Pricing Method: ${selectedPremium?.pricingMethod || "N/A"}`}>
                            <TuneTwoToneIcon/>
                            <span className="f gCW">
                                Pricing Method: <span className="bold">{selectedPremium?.pricingMethod ?? "-"}</span>
                            </span>
                        </div>
                    </div>
                    <div className="f cR lH">
                        <div className="point cR g dG" title={`Remaining Weeks: ${selectedPremium?.premiumSchedule?.length}`}>
                            <AutorenewTwoToneIcon/>
                            <span className="f gCW">
                                Remaining Weeks: <span className="bold">{selectedPremium?.premiumSchedule?.length ?? "-"}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={`actionBar${props?.displayType === "shipping" ? " alt" : ""} cC ns fR g dG`}>
                    <div className="action f gC1">
                        <div
                            className={`btnWIcon bold bR f cC ns${
                                Object?.keys(selectedPremiumSchedule)?.length ? " p" : " inactive"}`}
                            onClick={() => selectedPremiumSchedule?.length && session?.env?.setOverlay({
                                name : "lineGraph",
                                props : {
                                    dataPointer : "premiumSchedule",
                                    plotPointAttr : "premium",
                                    yAxisLabel : "deathBenefit",
                                    xAxisLabel : "date",
                                    cycles : 24,
                                    cyclesFriendlyTerm : "Months",
                                    graphName : "Premium Schedule",
                                    reportName : `Option ${props?.premiumScheduleData?.selectedPremiumKey + 1}`,
                                    reportList: props?.premiumScheduleData?.premiumSchedule?.reduce((acc, item, index) => {
                                        acc[index] = {
                                            ...item,
                                            reportKey: index,
                                            path: `${index}.premiumSchedule`,
                                            reportName : `Option ${index + 1}`,
                                        };
                                        return acc;
                                    }, {}),
                                    reportNamePrompt : "Option ",
                                    selectedReportKey : 0,
                                    formatList: preferredFormat,
                                    branch : props?.branch,
                                    stem : props?.stem,
                                }})}
                        >
                            <div className="gCW f">
                                View Graph
                            </div>
                            <BarChartIcon />
                        </div>
                    </div>
                    {props?.premiumScheduleData?.premiumSchedule?.length > 0 && 
                        <div className="action f gC2">
                            <Dropdown
                                setShowMenu={(input) => {
                                    props?.dropdowns?.set("premiums", {"showMenu" : input ?? !props?.dropdowns?.data?.premiums?.showMenu});
                                }}
                                showMenu={props?.dropdowns?.data?.premiums?.showMenu}
                                default={`Option ${props?.premiumScheduleData?.selectedPremiumKey + 1}`}
                                generatedList={props?.premiumScheduleData?.premiumSchedule?.reduce((acc, item, index) => {
                                    acc[`Option ${index + 1}`] = {
                                        ...item,
                                        premiumKey : index,
                                    };
                                    return acc;
                                }, {})}
                                onClick={(value, data) => props?.dropdowns?.data?.premiums?.onClick(value, data)}
                                reset={props?.resetDropdowns}
                            />
                        </div>
                    }
                    <div className="action f gC3">
                        <DownloadBtn
                            session={session}
                            setShowMenu={(input) => {
                                props?.dropdowns?.set("premiumsDownload", {"showMenu" : input ?? !props?.dropdowns?.data?.premiumsDownload?.showMenu});
                            }}
                            showMenu={props?.dropdowns?.data?.premiumsDownload?.showMenu}
                            inactive={!Object?.keys(selectedPremiumSchedule)?.length}
                            onClickDownload={()=>{Object?.keys(selectedPremiumSchedule)?.length &&
                                preferredFormat?.[session?.[props?.branch]?.data?.[props?.stem]?.preferredPremiumsFormat]?.onClick()
                            }}
                            preferredFormat={session?.[props?.branch]?.data?.[props?.stem]?.preferredPremiumsFormat}
                            formatList={Object.values(preferredFormat)}
                            reset={props?.resetDropdowns}
                        />
                    </div>
                </div>
                <div className="columnHeaders g cC dG fR">
                    <div className="column g fC cC dG">
                        <CalendarMonthTwoToneIcon style={{ color: "#607d8b" }} />
                        <div className="cL gCW f">
                            Date
                        </div>
                    </div>
                    <div className="column g fC cC dG">
                        <PaidTwoToneIcon style={{ color: "#4caf50" }} />
                        <div className="cL gCW f">
                            Premium
                        </div>
                    </div>
                    <div className="column g fC cC dG">
                        <PaymentsTwoToneIcon style={{ color: "#ba68c8" }} />
                        <div className="cL gCW f">
                            Death Benefit
                        </div>
                    </div>
                </div>
            </div>
            <div className="premiumSchedule g f dG">
            {props?.loadingStatus ? (
                <div className="loading bR g cC f">
                    <CircularProgress />
                </div>
                ) : Object.keys(selectedPremiumSchedule)?.length ? (
                    <div className="table g">
                        {selectedPremiumSchedule?.map((item, index) => (
                            <div
                                key={`${props?.premiumScheduleData?.selectedPremiumKey}-${index}`}
                                className="row g"
                            >
                                <div className="cell cC bR f gCW dP">
                                    {item?.date}
                                </div>
                                <div className="cell cC bR f gCW dP">
                                    {session?.env?.functions?.convertIntToCurrency(item?.premium)}
                                </div>
                                <div className="cell cC bR f gCW dP">
                                    {session?.env?.functions?.convertIntToCurrency(item?.deathBenefit)}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="f cC">
                        No premium schedule to display
                    </div>
                )}
            </div>
        </>
    );
}

export default PremiumSchedule;